import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M153.7,12.29A12,12,0,0,0,142.29,4H97.69a12,12,0,0,0-11.41,8.29A453.85,453.85,0,0,0,71.92,72.58c-7.44,45.34-4.28,77.86,9.37,96.66A44.11,44.11,0,0,0,108,186.72V228H96a12,12,0,0,0,0,24h48a12,12,0,0,0,0-24H132V186.72a44.11,44.11,0,0,0,26.7-17.48c13.66-18.8,16.81-51.32,9.37-96.66A453.85,453.85,0,0,0,153.7,12.29ZM133.36,28c2.08,7.25,5.14,18.7,7.92,32H98.7c2.78-13.3,5.84-24.75,7.92-32Zm5.91,127.14c-4.4,6-10.52,8.86-19.28,8.86s-14.88-2.82-19.28-8.86C91.88,143,89.71,117.88,94.46,84h51.06C150.27,117.88,148.1,143,139.27,155.14ZM240,56a16,16,0,1,1-16-16A16,16,0,0,1,240,56ZM180,20a16,16,0,1,1,16,16A16,16,0,0,1,180,20Zm36,84a16,16,0,1,1-16-16A16,16,0,0,1,216,104Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M120,176c-44.7,0-43.7-57.87-35.8-104h71.6C163.7,118.13,164.7,176,120,176Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M149.91,13.53A8,8,0,0,0,142.3,8H97.71a8,8,0,0,0-7.61,5.53,451,451,0,0,0-14.21,59.7c-7.26,44.25-4.35,75.76,8.65,93.66A40,40,0,0,0,112,183.42V232H96a8,8,0,1,0,0,16h48a8,8,0,0,0,0-16H128V183.42a39.94,39.94,0,0,0,27.46-16.53c13-17.9,15.92-49.41,8.66-93.66A451,451,0,0,0,149.91,13.53ZM103.59,24h32.83c3.06,10.19,6.77,24.42,9.8,40H93.8C96.83,48.42,100.53,34.19,103.59,24Zm38.93,133.48C137.38,164.56,130,168,120,168s-17.37-3.44-22.51-10.51C85.9,141.54,86.55,110,91,80H149C153.47,110,154.12,141.52,142.52,157.48ZM232,52a12,12,0,1,1-12-12A12,12,0,0,1,232,52ZM184,20a12,12,0,1,1,12,12A12,12,0,0,1,184,20Zm24,80a12,12,0,1,1-12-12A12,12,0,0,1,208,100Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M149.91,13.53A8,8,0,0,0,142.3,8H97.71a8,8,0,0,0-7.61,5.53,451,451,0,0,0-14.21,59.7c-7.26,44.25-4.35,75.76,8.65,93.66A40,40,0,0,0,112,183.42V232H96a8,8,0,1,0,0,16h48a8,8,0,0,0,0-16H128V183.42a39.94,39.94,0,0,0,27.46-16.53c13-17.9,15.92-49.41,8.66-93.66A451,451,0,0,0,149.91,13.53ZM93.8,64c3-15.58,6.73-29.81,9.79-40h32.83c3.06,10.19,6.77,24.42,9.8,40ZM232,52a12,12,0,1,1-12-12A12,12,0,0,1,232,52ZM184,20a12,12,0,1,1,12,12A12,12,0,0,1,184,20Zm24,80a12,12,0,1,1-12-12A12,12,0,0,1,208,100Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M142.26,10H97.65A6,6,0,0,0,92,14.14c-1.47,4.51-35.53,110.73-5.85,151.57,6.75,9.28,16.1,14.62,27.86,15.95V234H96a6,6,0,1,0,0,12h48a6,6,0,1,0,0-12H126V181.66c11.76-1.33,21.11-6.67,27.85-15.95,29.68-40.84-4.37-147.06-5.84-151.57A6,6,0,0,0,142.26,10ZM102.08,22h35.75c2.24,7.48,7,24.29,10.75,44H91.34C95.09,46.29,99.85,29.48,102.08,22Zm42,136.66C138.55,166.29,130.66,170,120,170s-18.6-3.71-24.14-11.34C85.46,144.42,83.21,116.55,89.25,78h61.42C156.71,116.55,154.45,144.42,144.1,158.66ZM230,52a10,10,0,1,1-10-10A10,10,0,0,1,230,52ZM206,20a10,10,0,1,1-10-10A10,10,0,0,1,206,20Zm0,80a10,10,0,1,1-10-10A10,10,0,0,1,206,100Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M184,20a12,12,0,1,1,12,12A12,12,0,0,1,184,20ZM164.12,73.23c7.26,44.25,4.35,75.76-8.66,93.66A39.94,39.94,0,0,1,128,183.42V232h16a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h16V183.42a40,40,0,0,1-27.46-16.53c-13-17.9-15.91-49.41-8.65-93.66A451,451,0,0,1,90.1,13.53,8,8,0,0,1,97.71,8H142.3a8,8,0,0,1,7.61,5.53A451,451,0,0,1,164.12,73.23ZM93.8,64h52.4c-3-15.58-6.72-29.81-9.78-40H103.59C100.53,34.19,96.83,48.42,93.8,64ZM149,80H91c-4.49,30-5.14,61.54,6.45,77.49C102.63,164.56,110,168,120,168s17.38-3.44,22.52-10.51C154.1,141.54,153.46,110,149,80Zm71-40a12,12,0,1,0,12,12A12,12,0,0,0,220,40ZM196,88a12,12,0,1,0,12,12A12,12,0,0,0,196,88Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M142.3,12H97.7a4,4,0,0,0-3.8,2.76c-1.45,4.47-35.2,109.75-6.13,149.78C94.5,173.79,104,178.92,116,179.83V236H96a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8H124V179.83c12-.91,21.5-6,28.22-15.29,29.08-40-4.67-145.31-6.12-149.78A4,4,0,0,0,142.3,12Zm-41.65,8h38.7A455.34,455.34,0,0,1,151,68H89A462,462,0,0,1,100.65,20Zm45.1,139.83C139.8,168,131.38,172,120,172s-19.8-4-25.75-12.16C81,141.63,82.6,106.72,87.6,76h64.81C158.86,116,156.57,144.94,145.75,159.83ZM228,52a8,8,0,1,1-8-8A8,8,0,0,1,228,52ZM204,20a8,8,0,1,1-8-8A8,8,0,0,1,204,20Zm0,80a8,8,0,1,1-8-8A8,8,0,0,1,204,100Z" }))
  ]
]);
export {
  t as default
};
