import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M90.44,108.6l6.24-6.24A83.54,83.54,0,0,0,56.24,92H40a12,12,0,0,1,0-24H56.24A107.28,107.28,0,0,1,132.6,99.64l40.25,40.25c10.74.27,22.11-3.64,35.49-14.73a12,12,0,1,1,15.32,18.47C205.49,158.7,189.06,164,174.15,164c-19.76,0-36.86-9.29-51.88-17.45-25.06-13.61-44.86-24.36-74.61.31a12,12,0,1,1-15.32-18.48C54.07,110.36,73.3,106.32,90.44,108.6ZM140,72a36,36,0,1,1,36,36A36,36,0,0,1,140,72Zm24,0a12,12,0,1,0,12-12A12,12,0,0,0,164,72Zm44.34,109.16c-29.75,24.67-49.55,13.92-74.61.3-26.35-14.3-59.14-32.11-101.39,2.93a12,12,0,1,0,15.32,18.47c29.75-24.66,49.55-13.92,74.61-.3,15,8.15,32.12,17.44,51.88,17.44,14.91,0,31.34-5.29,49.51-20.36a12,12,0,0,0-15.32-18.48Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M132.14,108.12l57.45,57.46C147.85,178.63,119.12,134.49,80,136l38.94-38.95A96,96,0,0,1,132.14,108.12ZM176,48a24,24,0,1,0,24,24A24,24,0,0,0,176,48Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M176,104a32,32,0,1,0-32-32A32,32,0,0,0,176,104Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,176,56Zm46.16,129.24a8,8,0,0,1-1,11.26c-17.36,14.39-32.86,19.5-47,19.5-18.58,0-34.82-8.82-49.93-17-25.35-13.76-47.24-25.65-79.07.74a8,8,0,1,1-10.22-12.31c40.17-33.29,70.32-16.93,96.93-2.49,25.35,13.77,47.24,25.65,79.07-.74A8,8,0,0,1,222.16,185.24ZM34.89,147.42a8,8,0,1,0,10.22,12.31c31.83-26.38,53.72-14.5,79.07-.74,15.11,8.2,31.35,17,49.93,17,14.14,0,29.64-5.11,47-19.5a8,8,0,1,0-10.22-12.31,75.79,75.79,0,0,1-19.28,12.06l-53.84-53.82A103.34,103.34,0,0,0,64.24,72H40a8,8,0,0,0,0,16H64.24a87.66,87.66,0,0,1,41.88,10.56L76.49,128.17C63.82,129.35,50.07,134.84,34.89,147.42Zm91.57-33.67,46.13,46.12c-14-.43-26.88-7.39-40.77-14.93-10.75-5.84-22.09-12-34.42-15.05l22.26-22.26A87.14,87.14,0,0,1,126.46,113.75Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M144,72a32,32,0,1,1,32,32A32,32,0,0,1,144,72Zm66.89,112.19c-31.83,26.39-53.72,14.51-79.07.74-26.61-14.44-56.76-30.81-96.93,2.49a8,8,0,1,0,10.22,12.31c31.83-26.39,53.72-14.5,79.07-.74,15.11,8.2,31.35,17,49.93,17,14.14,0,29.64-5.11,47-19.5a8,8,0,0,0-10.22-12.32Zm-176-36.76a8,8,0,1,0,10.22,12.31c31.83-26.38,53.72-14.5,79.07-.74,15.11,8.2,31.35,17,49.93,17,14.14,0,29.64-5.11,47-19.5a8,8,0,1,0-10.22-12.31,75.79,75.79,0,0,1-19.28,12.06l-53.84-53.82A103.34,103.34,0,0,0,64.24,72H40a8,8,0,0,0,0,16H64.24a87.66,87.66,0,0,1,41.88,10.56L76.49,128.17C63.82,129.36,50.07,134.84,34.89,147.42Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M176,102a30,30,0,1,0-30-30A30,30,0,0,0,176,102Zm0-48a18,18,0,1,1-18,18A18,18,0,0,1,176,54Zm44.62,132.58a6,6,0,0,1-.79,8.45c-16.87,14-32,19-45.75,19-18.19,0-34.13-8.65-48.94-16.7-26-14.11-48.44-26.31-81.31,1A6,6,0,1,1,36.17,189c39.13-32.45,68.65-16.41,94.69-2.27s48.44,26.31,81.31-1A6,6,0,0,1,220.62,186.58ZM36.17,149a6,6,0,1,0,7.66,9.24c32.87-27.26,55.32-15.07,81.31-1C140,165.34,155.89,174,174.08,174c13.79,0,28.88-5,45.75-19a6,6,0,1,0-7.66-9.24c-7.48,6.2-14.43,10.36-21,12.92l-54.81-54.82A101.31,101.31,0,0,0,64.24,74H40a6,6,0,0,0,0,12H64.24a89.42,89.42,0,0,1,45.15,12.13l-32.1,32.1C64.77,131.26,51.18,136.57,36.17,149Zm91.71-36.64,49.54,49.55c-16.3,1.26-30.78-6.6-46.56-15.17-11.63-6.32-24-13-37.51-15.6L119.5,105A93.92,93.92,0,0,1,127.88,112.37Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M176,104a32,32,0,1,0-32-32A32,32,0,0,0,176,104Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,176,56Zm46.16,129.24a8,8,0,0,1-1,11.26c-17.36,14.39-32.86,19.5-47,19.5-18.58,0-34.82-8.82-49.93-17-25.35-13.76-47.24-25.65-79.07.74a8,8,0,1,1-10.22-12.31c40.17-33.29,70.32-16.93,96.93-2.49,25.35,13.77,47.24,25.65,79.07-.74A8,8,0,0,1,222.16,185.24ZM34.89,147.42a8,8,0,1,0,10.22,12.31c31.83-26.38,53.72-14.5,79.07-.74,15.11,8.2,31.35,17,49.93,17,14.14,0,29.64-5.11,47-19.5a8,8,0,1,0-10.22-12.31,75.79,75.79,0,0,1-19.28,12.06l-53.84-53.82A103.34,103.34,0,0,0,64.24,72H40a8,8,0,0,0,0,16H64.24a87.66,87.66,0,0,1,41.88,10.56L76.49,128.17C63.82,129.35,50.07,134.84,34.89,147.42Zm91.57-33.67,46.13,46.12c-14-.43-26.88-7.39-40.77-14.93-10.75-5.84-22.09-12-34.42-15.05l22.26-22.26A87.14,87.14,0,0,1,126.46,113.75Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M176,100a28,28,0,1,0-28-28A28,28,0,0,0,176,100Zm0-48a20,20,0,1,1-20,20A20,20,0,0,1,176,52Zm43.08,135.84a4,4,0,0,1-.53,5.63C203.56,205.9,188.9,212,173.93,212a54.84,54.84,0,0,1-6.12-.34c-15-1.65-28.6-9-41.72-16.14-25.52-13.86-49.62-27-83.54,1.18a4,4,0,0,1-5.1-6.16c17-14.12,33.62-20.07,50.74-18.19,15,1.65,28.6,9,41.72,16.14,25.52,13.86,49.62,26.94,83.54-1.18A4,4,0,0,1,219.08,187.84ZM40,84H64.24a91.4,91.4,0,0,1,48.37,13.73L78.19,132.16c-13.63,1-27,7-40.74,18.38a4,4,0,0,0,5.1,6.16c33.92-28.13,58-15,83.54-1.18,13.12,7.12,26.69,14.49,41.72,16.14a54.84,54.84,0,0,0,6.12.34c15,0,29.63-6.1,44.62-18.53a4,4,0,1,0-5.1-6.16c-8.12,6.73-15.68,11.1-22.83,13.66L135,105.29A99.34,99.34,0,0,0,64.24,76H40a4,4,0,0,0,0,8Zm141.66,79.32c-18.77,3.07-35-5.71-51.75-14.83-12.83-7-26.09-14.15-40.73-16l30.16-30.16a94,94,0,0,1,9.95,8.63Z" }))
  ]
]);
export {
  t as default
};
