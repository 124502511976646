import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M220,124a32,32,0,0,0-8.06,1c-1.29-41.54-29.35-62.32-50-77.58C150.18,38.73,140,31.21,140,24a12,12,0,0,0-24,0c0,7.21-10.18,14.73-22,23.45-20.63,15.26-48.69,36-50,77.58A32,32,0,0,0,4,156v52a12,12,0,0,0,12,12H240a12,12,0,0,0,12-12V156A32,32,0,0,0,220,124ZM44,196H28V156a8,8,0,0,1,16,0ZM108.31,66.74c7-5.14,14-10.36,19.69-16.27,5.68,5.91,12.74,11.13,19.69,16.27C164.8,79.4,182.4,92.44,186.9,116H69.1C73.6,92.44,91.2,79.4,108.31,66.74ZM188,196H164V168a12,12,0,0,0-24,0v28H116V168a12,12,0,0,0-24,0v28H68V140H188Zm40,0H212V156a8,8,0,0,1,16,0Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M208,128H48c0-64,80-72,80-104C128,56,208,64,208,128Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M224,128a23.84,23.84,0,0,0-8,1.38V128c0-41.78-31.07-62.46-53.76-77.56C148.16,41.06,136,33,136,24a8,8,0,0,0-16,0c0,9-12.16,17.06-26.24,26.44C71.07,65.54,40,86.22,40,128v1.38A24,24,0,0,0,8,152v56a8,8,0,0,0,8,8H80a8,8,0,0,0,8-8V176a8,8,0,0,1,16,0v32a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V176a8,8,0,0,1,16,0v32a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V152A24,24,0,0,0,224,128ZM40,200H24V152a8,8,0,0,1,16,0ZM102.63,63.76c9.67-6.44,19-12.68,25.37-20,6.34,7.35,15.7,13.59,25.37,20,20,13.32,42.48,28.29,46.11,56.24h-143C60.15,92.05,82.6,77.08,102.63,63.76ZM200,200H184V176a24,24,0,0,0-48,0v24H120V176a24,24,0,0,0-48,0v24H56V136H200Zm32,0H216V152a8,8,0,0,1,16,0Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M224,128a23.84,23.84,0,0,0-8,1.38V128c0-41.78-31.07-62.46-53.76-77.56C148.16,41.06,136,33,136,24a8,8,0,0,0-16,0c0,9-12.16,17.06-26.24,26.44C71.07,65.54,40,86.22,40,128v1.38A24,24,0,0,0,8,152v56a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V176a16,16,0,0,1,32,0v32a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8V176a16,16,0,0,1,32,0v32a8,8,0,0,0,8,8h56a8,8,0,0,0,8-8V152A24,24,0,0,0,224,128ZM40,200H24V152a8,8,0,0,1,16,0Zm192,0H216V152a8,8,0,0,1,16,0Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M224,130a21.84,21.84,0,0,0-10,2.41V128c0-40.71-29.31-60.22-52.87-75.9C146.57,42.41,134,34,134,24a6,6,0,0,0-12,0c0,10-12.57,18.41-27.13,28.1C71.31,67.78,42,87.29,42,128v4.41A22,22,0,0,0,10,152v56a6,6,0,0,0,6,6H80a6,6,0,0,0,6-6V176a10,10,0,0,1,20,0v32a6,6,0,0,0,6,6h32a6,6,0,0,0,6-6V176a10,10,0,0,1,20,0v32a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V152A22,22,0,0,0,224,130ZM101.52,62.09c10.37-6.9,20.38-13.56,26.48-21.57,6.1,8,16.11,14.67,26.48,21.57C175.41,76,199,91.71,201.73,122H54.27C57,91.71,80.59,76,101.52,62.09ZM22,152a10,10,0,0,1,20,0v50H22Zm138,2a22,22,0,0,0-22,22v26H118V176a22,22,0,0,0-44,0v26H54V134H202v68H182V176A22,22,0,0,0,160,154Zm74,48H214V152a10,10,0,0,1,20,0Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M224,128a23.84,23.84,0,0,0-8,1.38V128c0-41.78-31.07-62.46-53.76-77.56C148.16,41.06,136,33,136,24a8,8,0,0,0-16,0c0,9-12.16,17.06-26.24,26.44C71.07,65.54,40,86.22,40,128v1.38A24,24,0,0,0,8,152v56a8,8,0,0,0,8,8H80a8,8,0,0,0,8-8V176a8,8,0,0,1,16,0v32a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V176a8,8,0,0,1,16,0v32a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V152A24,24,0,0,0,224,128ZM102.63,63.76c9.67-6.44,19-12.68,25.37-20,6.34,7.35,15.7,13.59,25.37,20,20,13.32,42.48,28.29,46.11,56.24h-143C60.15,92.05,82.6,77.08,102.63,63.76ZM24,152a8,8,0,0,1,16,0v48H24Zm136,0a24,24,0,0,0-24,24v24H120V176a24,24,0,0,0-48,0v24H56V136H200v64H184V176A24,24,0,0,0,160,152Zm72,48H216V152a8,8,0,0,1,16,0Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M224,132a19.91,19.91,0,0,0-12,4v-8c0-39.63-28.82-58.82-52-74.23-15-10-28-18.66-28-29.77a4,4,0,0,0-8,0c0,11.11-13,19.76-28,29.77C72.82,69.18,44,88.37,44,128v8a20,20,0,0,0-32,16v56a4,4,0,0,0,4,4H80a4,4,0,0,0,4-4V176a12,12,0,0,1,24,0v32a4,4,0,0,0,4,4h32a4,4,0,0,0,4-4V176a12,12,0,0,1,24,0v32a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V152A20,20,0,0,0,224,132ZM100.41,60.43C112,52.71,122.51,45.71,128,37.14c5.49,8.57,16,15.57,27.59,23.29C177.41,75,202,91.34,203.87,124H52.13C54,91.34,78.59,75,100.41,60.43ZM20,152a12,12,0,0,1,24,0v52H20Zm140,4a20,20,0,0,0-20,20v28H116V176a20,20,0,0,0-40,0v28H52V132H204v72H180V176A20,20,0,0,0,160,156Zm76,48H212V152a12,12,0,0,1,24,0Z" }))
  ]
]);
export {
  t as default
};
