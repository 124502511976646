import c from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M213.25,169.21C228.34,162.67,236,146.11,236,120s-7.66-42.68-22.75-49.22c-10.66-4.61-23.59-3.6-39.17,3.14,6.73-15.58,7.75-28.51,3.14-39.17C170.67,19.66,154.12,12,128,12S85.33,19.66,78.78,34.75c-4.61,10.66-3.59,23.59,3.14,39.17-15.58-6.74-28.51-7.75-39.17-3.14C27.66,77.32,20,93.88,20,120s7.66,42.67,22.75,49.21A35.35,35.35,0,0,0,57,172.05q11.15,0,24.94-6c-6.73,15.59-7.75,28.52-3.14,39.17C85.33,220.34,101.88,228,128,228s42.67-7.66,49.22-22.76a34.2,34.2,0,0,0,1.53-4.33c7.57,13.18,14,27.41,17.61,42A12,12,0,0,0,208,252a12.28,12.28,0,0,0,2.92-.36,12,12,0,0,0,8.73-14.55c-6-24.11-18.23-46.91-31.51-66.33A50.24,50.24,0,0,0,199,172.05,35.35,35.35,0,0,0,213.25,169.21ZM100.81,44.29C103.09,39,113,36,128,36s24.91,3,27.19,8.29c1.12,2.57,3.44,13-13.53,38.81-4.63,7-9.59,13.51-13.66,18.52-4.07-5-9-11.48-13.66-18.52C97.37,57.26,99.69,46.86,100.81,44.29ZM52.29,147.19C47,144.91,44,135,44,120s3-24.91,8.29-27.19A11.53,11.53,0,0,1,56.91,92C62.47,92,73,94.45,91.1,106.34A228.36,228.36,0,0,1,109.62,120c-5,4.07-11.48,9-18.52,13.66C65.26,150.63,54.86,148.31,52.29,147.19Zm102.9,48.52C152.91,201,143,204,128,204s-24.91-3-27.19-8.29c-1.12-2.58-3.44-13,13.53-38.81,4.63-7,9.59-13.51,13.66-18.52,4.07,5,9,11.48,13.66,18.52C158.63,182.74,156.31,193.13,155.19,195.71Zm9.71-62.05c-7-4.63-13.51-9.59-18.52-13.66a228.36,228.36,0,0,1,18.52-13.66c25.84-17,36.24-14.65,38.81-13.53C209,95.09,212,105,212,120s-3,24.91-8.29,27.19C201.14,148.31,190.74,150.63,164.9,133.66Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement(
      "path",
      {
        d: "M128,120s-90-96,0-96S128,120,128,120Zm-96,0c0,90,96,0,96,0S32,30,32,120Zm96,96c90,0,0-96,0-96S38,216,128,216Zm0-96s96,90,96,0S128,120,128,120Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ c.createElement("path", { d: "M211.66,165.54C225.16,159.7,232,144.37,232,120s-6.84-39.7-20.34-45.55c-11.65-5-27.24-2.23-46.46,8.35,10.58-19.22,13.39-34.81,8.35-46.46C167.7,22.84,152.37,16,128,16S88.3,22.84,82.45,36.34C77.41,48,80.22,63.58,90.8,82.8,71.58,72.22,56,69.4,44.34,74.45,30.84,80.3,24,95.63,24,120s6.84,39.7,20.34,45.54A31,31,0,0,0,56.8,168c9.6,0,21-3.62,34-10.79C80.22,176.41,77.41,192,82.45,203.65,88.3,217.15,103.63,224,128,224s39.7-6.85,45.55-20.35a32.24,32.24,0,0,0,2.34-15c10.45,16.23,19.64,34.48,24.35,53.33A8,8,0,0,0,208,248a8.13,8.13,0,0,0,1.95-.24,8,8,0,0,0,5.82-9.7c-6.94-27.76-22.27-53.8-37.86-74.79Q189.68,168,199.2,168A31,31,0,0,0,211.66,165.54Zm-6.37-76.4C214.14,93,216,108,216,120s-1.86,27-10.7,30.86c-8.36,3.63-23.52-1.31-42.68-13.91a243.4,243.4,0,0,1-22.54-17C158.49,104.37,190.4,82.68,205.29,89.14ZM97.14,42.7C101,33.86,116,32,128,32s27,1.86,30.86,10.7c3.63,8.36-1.31,23.52-13.91,42.68a243.4,243.4,0,0,1-17,22.54C112.37,89.51,90.69,57.59,97.14,42.7ZM50.71,150.86C41.86,147,40,132,40,120s1.86-27,10.7-30.86A15.64,15.64,0,0,1,57,88c8.75,0,21.34,5.17,36.4,15.07a243.4,243.4,0,0,1,22.54,17C97.51,135.62,65.59,157.32,50.71,150.86Zm108.15,46.43C155,206.14,140,208,128,208s-27-1.86-30.86-10.7c-3.63-8.36,1.31-23.52,13.91-42.68a243.4,243.4,0,0,1,17-22.54C143.63,150.49,165.31,182.41,158.86,197.29Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M228,120c0,22.63-6,36.72-17.93,41.87a27.3,27.3,0,0,1-11,2.13,41.75,41.75,0,0,1-8.4-.93,4.05,4.05,0,0,1-2.52-1.64,368.49,368.49,0,0,0-47.75-55.26,8,8,0,0,0-11,11.62c14.84,13.91,64.13,63.49,78.32,120.27a8,8,0,0,1-5.82,9.7A8.13,8.13,0,0,1,200,248a8,8,0,0,1-7.75-6.06c-4.12-16.47-11.65-32.48-20.46-47.09a25.85,25.85,0,0,1-1.9,7.21C164.72,214,150.63,220,128,220s-36.72-6-41.88-17.94c-5.45-12.58-.39-30.82,15-54.21.68-1,1.36-2,2-3l-3,2C82.84,158.27,68.35,164,56.89,164a27.3,27.3,0,0,1-11-2.13C34,156.72,28,142.63,28,120s6-36.72,17.93-41.88c12.59-5.45,30.83-.39,54.22,15l3,2q-1-1.5-2-3c-15.41-23.39-20.47-41.63-15-54.22C91.28,26,105.37,20,128,20s36.72,6,41.88,17.93c5.45,12.59.39,30.83-15,54.22q-1,1.53-2,3l3-2c23.39-15.41,41.63-20.47,54.22-15C222,83.28,228,97.37,228,120Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M210.86,163.71C223.56,158.21,230,143.5,230,120s-6.44-38.21-19.14-43.71c-14.5-6.29-34,1.5-51,11.8,10.3-16.93,18.08-36.45,11.8-50.95C166.21,24.44,151.5,18,128,18S89.79,24.44,84.29,37.14c-6.28,14.5,1.5,34,11.8,51C79.17,77.79,59.64,70,45.14,76.29,32.44,81.79,26,96.5,26,120s6.44,38.21,19.14,43.71A29.08,29.08,0,0,0,56.78,166c12.42,0,26.6-6.33,39.32-14.08-10.31,16.93-18.1,36.46-11.81,51C89.79,215.56,104.5,222,128,222s38.21-6.44,43.71-19.14c3-6.82,2.79-14.76.72-23,12.62,18.21,24.22,39.49,29.75,61.6A6,6,0,0,0,208,246a6.14,6.14,0,0,0,1.46-.18,6,6,0,0,0,4.36-7.28c-7.56-30.24-25.2-58.44-42.18-80.2,9.32,4.51,18.88,7.63,27.58,7.63A29.11,29.11,0,0,0,210.86,163.71ZM95.3,41.91C98.73,34,109.73,30,128,30s29.27,4,32.7,11.91c3.9,9-1.11,24.85-14.11,44.61A247.27,247.27,0,0,1,128,111a247.27,247.27,0,0,1-18.59-24.45C96.41,66.76,91.4,50.91,95.3,41.91ZM49.91,152.7C42,149.27,38,138.27,38,120s4-29.27,11.91-32.7a17.46,17.46,0,0,1,7-1.29c9.26,0,22.27,5.28,37.65,15.4A246.07,246.07,0,0,1,119,120a246.07,246.07,0,0,1-24.45,18.59C74.76,151.59,58.91,156.6,49.91,152.7ZM160.7,198.09C157.27,206,146.27,210,128,210s-29.27-4-32.7-11.91c-3.9-9,1.11-24.85,14.11-44.61A246.07,246.07,0,0,1,128,129a246.07,246.07,0,0,1,18.59,24.45C159.59,173.24,164.6,189.08,160.7,198.09Zm.78-59.5A246.07,246.07,0,0,1,137,120a246.07,246.07,0,0,1,24.45-18.59c19.76-13,35.61-18,44.61-14.11C214,90.73,218,101.73,218,120s-4,29.27-11.91,32.7C197.09,156.6,181.24,151.59,161.48,138.59Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M211.66,165.54C225.16,159.7,232,144.37,232,120s-6.84-39.7-20.34-45.55c-11.65-5-27.24-2.23-46.46,8.35,10.58-19.22,13.39-34.81,8.35-46.46C167.7,22.84,152.37,16,128,16S88.3,22.84,82.45,36.34c-5,11.65-2.23,27.24,8.35,46.45C71.58,72.22,56,69.4,44.34,74.45,30.84,80.3,24,95.63,24,120s6.84,39.7,20.34,45.54A31,31,0,0,0,56.8,168c9.6,0,21-3.62,34-10.79C80.22,176.41,77.41,192,82.45,203.65,88.3,217.15,103.63,224,128,224s39.7-6.85,45.55-20.35a32.24,32.24,0,0,0,2.34-15c10.45,16.23,19.64,34.48,24.35,53.33A8,8,0,0,0,208,248a8.13,8.13,0,0,0,1.95-.24,8,8,0,0,0,5.82-9.7c-6.94-27.76-22.27-53.8-37.86-74.79Q189.68,168,199.2,168A31,31,0,0,0,211.66,165.54Zm-6.37-76.4C214.14,93,216,108,216,120s-1.86,27-10.7,30.86c-8.36,3.63-23.52-1.31-42.68-13.91a243.4,243.4,0,0,1-22.54-17C158.49,104.37,190.4,82.68,205.29,89.14ZM97.14,42.7C101,33.86,116,32,128,32s27,1.86,30.86,10.7c3.63,8.36-1.31,23.52-13.91,42.68a243.4,243.4,0,0,1-17,22.54C112.37,89.51,90.69,57.59,97.14,42.7ZM50.71,150.86C41.86,147,40,132,40,120s1.86-27,10.7-30.86A15.64,15.64,0,0,1,57,88c8.75,0,21.34,5.17,36.4,15.07a243.4,243.4,0,0,1,22.54,17C97.51,135.62,65.59,157.32,50.71,150.86Zm108.15,46.43C155,206.14,140,208,128,208s-27-1.86-30.86-10.7c-3.63-8.36,1.31-23.52,13.91-42.68a243.4,243.4,0,0,1,17-22.54C143.63,150.49,165.31,182.41,158.86,197.29Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M210.07,161.88C222,156.72,228,142.63,228,120s-6-36.72-17.93-41.88c-12.59-5.45-30.83-.39-54.22,15l-3,2q1-1.5,2-3c15.41-23.39,20.47-41.63,15-54.22C164.72,26,150.63,20,128,20S91.28,26,86.12,37.93c-5.45,12.59-.39,30.83,15,54.22q1,1.53,2,3l-3-2c-23.39-15.41-41.63-20.47-54.22-15C34,83.28,28,97.37,28,120s6,36.72,17.93,41.88a27.47,27.47,0,0,0,11,2.12c11.46,0,26-5.73,43.26-17.14l3-2q-1,1.5-2,3c-15.41,23.39-20.47,41.63-15,54.21C91.28,214,105.37,220,128,220s36.72-6,41.88-17.94c3.81-8.8,2.47-20.39-3.95-34.56C181.58,188,197.34,213.86,204.12,241a4,4,0,0,0,3.88,3,4.09,4.09,0,0,0,1-.12,4,4,0,0,0,2.91-4.85c-8.41-33.65-29.45-64.78-48-87.2,13.82,8.09,25.62,12.17,35.26,12.17A27.47,27.47,0,0,0,210.07,161.88ZM93.47,41.11C97.24,32.41,108.86,28,128,28s30.76,4.41,34.53,13.11c8.21,18.94-19.86,56.41-34.53,73C113.33,97.52,85.26,60.05,93.47,41.11ZM49.11,154.53C40.41,150.76,36,139.14,36,120s4.41-30.76,13.11-34.53A19.41,19.41,0,0,1,56.91,84c20.08,0,50.66,23.15,65.15,36C105.52,134.67,68.05,162.74,49.11,154.53Zm113.42,44.36C158.76,207.59,147.14,212,128,212s-30.76-4.41-34.53-13.11C85.26,180,113.33,142.48,128,125.94,142.67,142.48,170.74,180,162.53,198.89Zm-28.42-78.74L134,120c16.54-14.68,54-42.75,72.94-34.53C215.59,89.24,220,100.86,220,120s-4.41,30.76-13.11,34.53C188,162.71,150.73,134.86,134.11,120.15Z" }))
  ]
]);
export {
  e as default
};
