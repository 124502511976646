import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M227.93,152.31l-45.82-11.17L72.49,31.51a12,12,0,0,0-17.34.39C32.48,56.68,20,88,20,120v72a20,20,0,0,0,20,20H72a20,20,0,0,0,20-20V150.29l1.07.71a108.44,108.44,0,0,1,39.11,48.6A19.9,19.9,0,0,0,150.72,212H236a20,20,0,0,0,20-20v-4.73A35.68,35.68,0,0,0,227.93,152.31ZM68,188H44V132.67a106.69,106.69,0,0,1,24,5.51Zm164,0H153.35a132.44,132.44,0,0,0-46.67-56.77,131.08,131.08,0,0,0-62-22.63A106.69,106.69,0,0,1,64.87,57.84L167.51,160.49a12.06,12.06,0,0,0,5.65,3.17l49.2,12,.25.06a11.84,11.84,0,0,1,9.39,11.6Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M248,187.31V192a8,8,0,0,1-8,8H150.72a8,8,0,0,1-7.42-4.92C125.51,151.28,82.38,120,32,120c0-31,12.59-58.78,32-80L176,152l53.21,12A23.92,23.92,0,0,1,248,187.31Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M231,156.19,180,144.71,69.66,34.34a8,8,0,0,0-11.56.26C36.11,58.64,24,89,24,120v72a16,16,0,0,0,16,16H72a16,16,0,0,0,16-16V143.06c2.49,1.45,4.94,3,7.34,4.64a112.45,112.45,0,0,1,40.55,50.39A15.9,15.9,0,0,0,150.72,208H240a16,16,0,0,0,16-16v-4.73A31.72,31.72,0,0,0,231,156.19ZM72,192H40V128.29a110.88,110.88,0,0,1,32,7.12Zm168,0H150.68a128.36,128.36,0,0,0-46.27-57.46,126.9,126.9,0,0,0-64.12-22.26A110.67,110.67,0,0,1,64.46,51.78L170.34,157.66a8,8,0,0,0,3.9,2.14l53.24,12A15.81,15.81,0,0,1,240,187.31Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M32,144a95.28,95.28,0,0,1,37.53,7.67,4,4,0,0,1,2.47,3.7V192a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V148a4,4,0,0,1,4-4Zm199,12.19L180,144.71,69.66,34.34a8,8,0,0,0-11.56.26C36.11,58.64,24,89,24,120a8,8,0,0,0,8,8,111.2,111.2,0,0,1,63.34,19.7,112.45,112.45,0,0,1,40.55,50.39A15.9,15.9,0,0,0,150.72,208H240a16,16,0,0,0,16-16v-4.73A31.72,31.72,0,0,0,231,156.19Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M230.53,158.14,179,146.53,68.24,35.76a6,6,0,0,0-8.67.19C37.92,59.62,26,89.47,26,120v72a14,14,0,0,0,14,14H72a14,14,0,0,0,14-14V139.65a115,115,0,0,1,51.74,57.69,13.92,13.92,0,0,0,13,8.66H240a14,14,0,0,0,14-14v-4.73A29.73,29.73,0,0,0,230.53,158.14ZM74,192a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V126.18A112.75,112.75,0,0,1,74,134Zm168,0a2,2,0,0,1-2,2H150.72a2,2,0,0,1-1.86-1.18,126.53,126.53,0,0,0-45.58-56.65,125.13,125.13,0,0,0-65.12-22C39.47,90.66,48.6,67.83,64.31,48.79L171.76,156.24a6,6,0,0,0,2.92,1.61l53.23,12A17.81,17.81,0,0,1,242,187.31Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M231,156.19,180,144.7,69.66,34.34a8,8,0,0,0-11.56.26C36.11,58.64,24,89,24,120v72a16,16,0,0,0,16,16H72a16,16,0,0,0,16-16V143.06c2.49,1.45,4.94,3,7.34,4.64a112.45,112.45,0,0,1,40.55,50.39A15.9,15.9,0,0,0,150.72,208H240a16,16,0,0,0,16-16v-4.73A31.72,31.72,0,0,0,231,156.19ZM72,192H40V128.29a110.88,110.88,0,0,1,32,7.12Zm168,0H150.68a128.36,128.36,0,0,0-46.27-57.46,126.9,126.9,0,0,0-64.12-22.26A110.67,110.67,0,0,1,64.46,51.78L170.34,157.66a8,8,0,0,0,3.9,2.14l53.24,12A15.81,15.81,0,0,1,240,187.31Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M230.09,160.09,178,148.35,66.83,37.17A4,4,0,0,0,63.91,36a4,4,0,0,0-2.86,1.3C39.74,60.6,28,90,28,120v72a12,12,0,0,0,12,12H72a12,12,0,0,0,12-12V136.36a116.73,116.73,0,0,1,55.59,60.23A11.93,11.93,0,0,0,150.72,204H240a12,12,0,0,0,12-12v-4.73A27.74,27.74,0,0,0,230.09,160.09ZM76,192a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V124.08a114.9,114.9,0,0,1,40,8.62Zm168,0a4,4,0,0,1-4,4H150.72a4,4,0,0,1-3.71-2.42,124.36,124.36,0,0,0-44.87-55.76,123.1,123.1,0,0,0-66.07-21.75c.93-25.33,10.8-50,28.11-70.23l109,109a4,4,0,0,0,2,1.07l53.22,12A19.79,19.79,0,0,1,244,187.31Z" }))
  ]
]);
export {
  t as default
};
