import c from "react";
const a = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M244,149.31c0,16.84-3.37,31.33-9.75,41.91C226.06,204.81,213.33,212,197.44,212c-30.33,0-50.72-38.05-72.3-78.33C109.46,104.41,90,68,74,68c-7.71,0-17.16,9.37-24.66,24.45A133.82,133.82,0,0,0,36,149.31c0,12.3,2.24,22.78,6.3,29.52,3.77,6.26,8.94,9.17,16.26,9.17,13.75,0,30.63-29,36.18-38.49a12,12,0,0,1,20.74,12.09c-8.91,15.28-16.53,26-24,33.78C80.75,206.56,70,212,58.56,212c-15.89,0-28.62-7.19-36.81-20.78C15.37,180.64,12,166.15,12,149.31,12,105.34,35.58,44,74,44c30.33,0,50.72,38.05,72.3,78.33C162,151.59,181.47,188,197.44,188c7.32,0,12.49-2.91,16.26-9.17,4.06-6.74,6.3-17.22,6.3-29.52a133.82,133.82,0,0,0-13.33-56.86C199.17,77.37,189.72,68,182,68c-5.72,0-12.61,5-20.49,14.83a12,12,0,0,1-18.74-15C155.62,51.8,168.45,44,182,44c17.65,0,34,13.41,46.15,37.76A159,159,0,0,1,244,149.31Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement(
      "path",
      {
        d: "M128.49,114.6l-18.71,32.75C93.88,175.86,77.52,200,58.56,200-3.16,200,27.7,56,74,56,94.25,56,111.56,83.58,128.49,114.6ZM182,56c-12.62,0-24.1,10.7-35,26.27L128.49,114.6c21.76,39.86,42.91,85.4,68.95,85.4C259.16,200,228.3,56,182,56Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ c.createElement("path", { d: "M240,149.31c0,16.11-3.17,29.89-9.17,39.84-7.43,12.33-19,18.85-33.39,18.85-27.94,0-47.78-37-68.78-76.22C111.64,100,92.35,64,74,64c-9.38,0-19.94,10-28.25,26.67A138.18,138.18,0,0,0,32,149.31c0,13.2,2.38,24.12,6.88,31.58S49.82,192,58.56,192c15.12,0,30.85-24.54,44.23-48.55a8,8,0,0,1,14,7.8C101.46,178.71,83.07,208,58.56,208c-14.41,0-26-6.52-33.39-18.85-6-10-9.17-23.73-9.17-39.84A154.81,154.81,0,0,1,31.42,83.54C42.82,60.62,57.94,48,74,48c27.94,0,47.77,37,68.78,76.22C159.79,156,179.08,192,197.44,192c8.74,0,15.18-3.63,19.68-11.11S224,162.51,224,149.31a138.18,138.18,0,0,0-13.74-58.64C202,74,191.39,64,182,64c-8.36,0-17.68,7.48-28.51,22.88a8,8,0,1,1-13.08-9.21c9-12.74,23-29.67,41.59-29.67,16.05,0,31.17,12.62,42.57,35.54A154.81,154.81,0,0,1,240,149.31Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM201.23,170.26c-5.63,9-14.33,13.74-25.16,13.74-20.73,0-34.81-25.31-49.72-52.11C115.47,112.33,101.93,88,90.61,88,78.18,88,64,116.88,64,142.21c0,8.26,1.5,15,4.34,19.56C71,166,74.68,168,79.93,168c6.08,0,13.52-7,22.74-21.51a8,8,0,0,1,13.49,8.59C99.84,180.71,88.22,184,79.93,184c-10.83,0-19.53-4.75-25.16-13.74-4.43-7.08-6.77-16.78-6.77-28A103.13,103.13,0,0,1,59,97.06C67.34,80.9,78.57,72,90.61,72c20.73,0,34.81,25.31,49.72,52.11C151.21,143.66,164.75,168,176.07,168c5.25,0,8.93-2,11.59-6.23,2.84-4.53,4.34-11.3,4.34-19.56C192,116.88,177.82,88,165.39,88c-3.4,0-7.35,2.31-11.74,6.88a8,8,0,1,1-11.54-11.09C149.74,75.86,157.35,72,165.39,72c12,0,23.27,8.9,31.62,25.06a103.13,103.13,0,0,1,11,45.15C208,153.48,205.66,163.18,201.23,170.26Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M238,149.31c0,15.75-3.07,29.17-8.88,38.81-7.05,11.7-18,17.88-31.68,17.88-26.74,0-46.3-36.51-67-75.17C113.14,98.57,93.55,62,74,62c-23.28,0-44,48.83-44,87.31,0,13.57,2.48,24.84,7.16,32.62,4.9,8.12,11.9,12.07,21.4,12.07,16.16,0,32.31-25.06,46-49.57A6,6,0,0,1,115,150.27C100,177.23,82,206,58.56,206c-13.67,0-24.63-6.18-31.68-17.88C21.07,178.48,18,165.06,18,149.31,18,108.82,39.81,50,74,50c26.74,0,46.3,36.51,67,75.17C158.29,157.43,177.88,194,197.44,194c9.5,0,16.5-3.95,21.4-12.07,4.68-7.78,7.16-19.05,7.16-32.62C226,110.83,205.29,62,182,62c-9,0-18.91,7.76-30.14,23.73a6,6,0,0,1-9.82-6.91C150.75,66.45,164.37,50,182,50,216.19,50,238,108.82,238,149.31Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M240,149.31c0,16.11-3.17,29.89-9.17,39.84-7.43,12.33-19,18.85-33.39,18.85-27.94,0-47.78-37-68.78-76.22C111.64,100,92.35,64,74,64c-9.38,0-19.94,10-28.25,26.67A138.18,138.18,0,0,0,32,149.31c0,13.2,2.38,24.12,6.88,31.58S49.82,192,58.56,192c15.12,0,30.85-24.54,44.23-48.55a8,8,0,0,1,14,7.8C101.46,178.71,83.07,208,58.56,208c-14.41,0-26-6.52-33.39-18.85-6-10-9.17-23.73-9.17-39.84A154.81,154.81,0,0,1,31.42,83.54C42.82,60.62,57.94,48,74,48c27.94,0,47.77,37,68.78,76.22C159.79,156,179.08,192,197.44,192c8.74,0,15.18-3.63,19.68-11.11S224,162.51,224,149.31a138.18,138.18,0,0,0-13.74-58.64C202,74,191.39,64,182,64c-8.36,0-17.68,7.48-28.51,22.88a8,8,0,1,1-13.08-9.21c9-12.74,23-29.67,41.59-29.67,16.05,0,31.17,12.62,42.57,35.54A154.81,154.81,0,0,1,240,149.31Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M236,149.31c0,15.38-3,28.45-8.6,37.78-6.67,11.06-17,16.91-30,16.91-25.55,0-44.83-36-65.25-74.11C113.78,95.53,94.74,60,74,60,62.9,60,51.3,70.53,42.16,88.89A142.23,142.23,0,0,0,28,149.31c0,13.93,2.58,25.56,7.45,33.65,5.22,8.65,13,13,23.11,13,17.15,0,33.07-24.32,47.73-50.6a4,4,0,1,1,7,3.9C98.52,175.76,80.9,204,58.56,204c-12.93,0-23.29-5.85-30-16.91C23,177.76,20,164.69,20,149.31a150.73,150.73,0,0,1,15-64C45.69,63.83,59.54,52,74,52c25.54,0,44.83,36,65.25,74.11,18.41,34.36,37.44,69.89,58.2,69.89,10.12,0,17.89-4.39,23.11-13,4.87-8.09,7.45-19.72,7.45-33.65a142.23,142.23,0,0,0-14.16-60.42C204.7,70.53,193.1,60,182,60c-9.75,0-20.15,8-31.78,24.57a4,4,0,0,1-6.54-4.6c13.42-19.08,25.6-28,38.32-28,14.45,0,28.3,11.83,39,33.32A150.73,150.73,0,0,1,236,149.31Z" }))
  ]
]);
export {
  a as default
};
