import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,152c0,20.19-19.33,36-44,36-21.26,0-39.46-12.35-43.27-29.38a12,12,0,0,1,23.42-5.24C109.3,158.49,117.21,164,128,164c9.44,0,20-5.13,20-12,0-6.37-6.29-9.32-24-14.42-16.14-4.65-38.23-11-38.23-33.58,0-20.52,18.15-36,42.22-36,17.24,0,32.06,8.14,38.69,21.24a12,12,0,1,1-21.41,10.84C142.8,95.17,136,92,128,92c-10.22,0-18.22,5.27-18.22,12,0,1.09,0,2.21,3.28,4.17,4.18,2.48,11,4.45,17.6,6.35,8.75,2.52,17.8,5.13,25.38,9.86C169.23,132.61,172,143.94,172,152Zm64,24a60.07,60.07,0,0,1-60,60A59.36,59.36,0,0,1,144,226.73,101.21,101.21,0,0,1,128,228,100.11,100.11,0,0,1,28,128a101.21,101.21,0,0,1,1.27-16A59.36,59.36,0,0,1,20,80,60.07,60.07,0,0,1,80,20a59.36,59.36,0,0,1,32.05,9.27A101.21,101.21,0,0,1,128,28,100.11,100.11,0,0,1,228,128,101.21,101.21,0,0,1,226.73,144,59.36,59.36,0,0,1,236,176Zm-24,0a35.63,35.63,0,0,0-7.49-22,12,12,0,0,1-2.23-9.87,76.07,76.07,0,0,0-90.43-90.43A12,12,0,0,1,102,51.49,35.63,35.63,0,0,0,80,44,36,36,0,0,0,44,80a35.63,35.63,0,0,0,7.49,22,12,12,0,0,1,2.23,9.87,76.07,76.07,0,0,0,90.43,90.43,12,12,0,0,1,9.87,2.23A35.63,35.63,0,0,0,176,212,36,36,0,0,0,212,176Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M224,176a48,48,0,0,1-77.31,38A88.07,88.07,0,0,1,42,109.31,48,48,0,0,1,109.31,42,88.07,88.07,0,0,1,214,146.69,47.77,47.77,0,0,1,224,176Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M168,152c0,17.64-17.94,32-40,32s-40-14.36-40-32a8,8,0,0,1,16,0c0,8.67,11,16,24,16s24-7.33,24-16c0-9.48-8.61-13-26.88-18.26C109.37,129.2,89.78,123.55,89.78,104c0-18.24,16.43-32,38.22-32,15.72,0,29.18,7.3,35.12,19a8,8,0,1,1-14.27,7.22C145.64,91.94,137.65,88,128,88c-12.67,0-22.22,6.88-22.22,16,0,7,9,10.1,23.77,14.36C145.78,123,168,129.45,168,152Zm64,24a56.06,56.06,0,0,1-56,56,55.38,55.38,0,0,1-31.17-9.47A96.09,96.09,0,0,1,33.47,111.17,55.38,55.38,0,0,1,24,80,56.06,56.06,0,0,1,80,24a55.38,55.38,0,0,1,31.17,9.47A96.09,96.09,0,0,1,222.53,144.83,55.38,55.38,0,0,1,232,176Zm-16,0a39.6,39.6,0,0,0-8.32-24.42,8,8,0,0,1-1.49-6.58A80.07,80.07,0,0,0,111,49.81a8,8,0,0,1-6.58-1.49A39.6,39.6,0,0,0,80,40,40,40,0,0,0,40,80a39.6,39.6,0,0,0,8.32,24.42A8,8,0,0,1,49.81,111,80.07,80.07,0,0,0,145,206.19a8,8,0,0,1,6.58,1.49A39.6,39.6,0,0,0,176,216,40,40,0,0,0,216,176Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M222.53,144.83A96.09,96.09,0,0,0,111.17,33.47,55.38,55.38,0,0,0,80,24,56.06,56.06,0,0,0,24,80a55.38,55.38,0,0,0,9.47,31.17A96.09,96.09,0,0,0,144.83,222.53,55.38,55.38,0,0,0,176,232a56.06,56.06,0,0,0,56-56A55.38,55.38,0,0,0,222.53,144.83ZM128,184c-22.06,0-40-14.36-40-32a8,8,0,0,1,16,0c0,8.67,11,16,24,16s24-7.33,24-16c0-9.48-8.61-13-26.88-18.26C109.37,129.2,89.78,123.55,89.78,104c0-18.24,16.43-32,38.22-32,15.72,0,29.18,7.3,35.12,19a8,8,0,1,1-14.27,7.22C145.64,91.94,137.65,88,128,88c-12.67,0-22.22,6.88-22.22,16,0,7,9,10.1,23.77,14.36C145.78,123,168,129.45,168,152,168,169.64,150.06,184,128,184Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M166,152c0,16.54-17.05,30-38,30s-38-13.46-38-30a6,6,0,0,1,12,0c0,9.76,11.91,18,26,18s26-8.24,26-18c0-11.21-10.7-15.1-28.33-20.18-15.89-4.58-33.89-9.77-33.89-27.82,0-17.1,15.57-30,36.22-30,15,0,27.74,6.88,33.34,18a6,6,0,0,1-10.71,5.42C147.08,90.36,138.41,86,128,86c-13.81,0-24.22,7.74-24.22,18,0,8.41,9.52,11.76,25.21,16.29C145.48,125,166,131,166,152Zm64,24a54.06,54.06,0,0,1-54,54,53.43,53.43,0,0,1-30.72-9.59A94.06,94.06,0,0,1,35.59,110.72,53.43,53.43,0,0,1,26,80,54.06,54.06,0,0,1,80,26a53.43,53.43,0,0,1,30.72,9.59A94.06,94.06,0,0,1,220.41,145.28,53.43,53.43,0,0,1,230,176Zm-12,0a41.57,41.57,0,0,0-8.74-25.65,6,6,0,0,1-1.11-4.93,82.09,82.09,0,0,0-97.57-97.57,6,6,0,0,1-4.93-1.11A41.57,41.57,0,0,0,80,38,42,42,0,0,0,38,80a41.57,41.57,0,0,0,8.74,25.65,6,6,0,0,1,1.11,4.93,82.09,82.09,0,0,0,97.57,97.57,5.95,5.95,0,0,1,4.93,1.11A41.57,41.57,0,0,0,176,218,42,42,0,0,0,218,176Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M168,152c0,17.64-17.94,32-40,32s-40-14.36-40-32a8,8,0,0,1,16,0c0,8.67,11,16,24,16s24-7.33,24-16c0-9.48-8.61-13-26.88-18.26C109.37,129.2,89.78,123.55,89.78,104c0-18.24,16.43-32,38.22-32,15.72,0,29.18,7.3,35.12,19a8,8,0,1,1-14.27,7.22C145.64,91.94,137.65,88,128,88c-12.67,0-22.22,6.88-22.22,16,0,7,9,10.1,23.77,14.36C145.78,123,168,129.45,168,152Zm64,24a56.06,56.06,0,0,1-56,56,55.38,55.38,0,0,1-31.17-9.47A96.09,96.09,0,0,1,33.47,111.17,55.38,55.38,0,0,1,24,80,56.06,56.06,0,0,1,80,24a55.38,55.38,0,0,1,31.17,9.47A96.09,96.09,0,0,1,222.53,144.83,55.38,55.38,0,0,1,232,176Zm-16,0a39.6,39.6,0,0,0-8.32-24.42,8,8,0,0,1-1.49-6.58A80.07,80.07,0,0,0,111,49.81a8,8,0,0,1-6.58-1.49A39.6,39.6,0,0,0,80,40,40,40,0,0,0,40,80a39.6,39.6,0,0,0,8.32,24.42A8,8,0,0,1,49.81,111,80.07,80.07,0,0,0,145,206.19a8,8,0,0,1,6.58,1.49A39.6,39.6,0,0,0,176,216,40,40,0,0,0,216,176Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M164,152c0,15.44-16.15,28-36,28s-36-12.56-36-28a4,4,0,0,1,8,0c0,11,12.56,20,28,20s28-9,28-20c0-12.58-11.79-16.92-29.78-22.11C111,125.51,93.78,120.54,93.78,104c0-16,14.71-28,34.22-28,14.2,0,26.29,6.46,31.55,16.85a4,4,0,1,1-7.13,3.61C148.53,88.78,139.17,84,128,84c-15,0-26.22,8.6-26.22,20,0,9.81,10.07,13.43,26.66,18.21S164,132.46,164,152Zm64,24a52.06,52.06,0,0,1-52,52,51.43,51.43,0,0,1-30.26-9.71,92.08,92.08,0,0,1-108-108A51.43,51.43,0,0,1,28,80,52.06,52.06,0,0,1,80,28a51.43,51.43,0,0,1,30.26,9.71,92.08,92.08,0,0,1,108,108A51.43,51.43,0,0,1,228,176Zm-8,0a43.63,43.63,0,0,0-9.15-26.87,4,4,0,0,1-.75-3.29A84.07,84.07,0,0,0,110.16,45.9a4,4,0,0,1-3.29-.75,44,44,0,0,0-61.72,61.72,4,4,0,0,1,.75,3.29,84.07,84.07,0,0,0,99.94,99.94,4,4,0,0,1,3.29.75A44,44,0,0,0,220,176Z" }))
  ]
]);
export {
  t as default
};
