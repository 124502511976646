import a from "react";
const c = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M235.79,48c-4.27-5.48-12.4-12-26.88-12-17.86,0-40.5,11.7-60.57,31.3-3,2.89-5.74,5.85-8.34,8.84V56a12,12,0,0,0-24,0V76.14c-2.6-3-5.38-6-8.34-8.84C87.59,47.7,65,36,47.09,36c-14.48,0-22.61,6.54-26.88,12C7,65,12,93.91,19.28,122.66c5.75,22.64,17.8,33,28.88,37.69A48.12,48.12,0,0,0,92,228a47.87,47.87,0,0,0,36-16.28A48,48,0,0,0,212,180a48.51,48.51,0,0,0-4.14-19.65c11.08-4.67,23.13-15,28.88-37.69C244,93.91,249,65,235.79,48ZM92,204a24,24,0,0,1-24-24,24.36,24.36,0,0,1,21.31-24.07,12,12,0,0,0-2.64-23.86A47.63,47.63,0,0,0,65.17,140c-8.19-.29-18-4.92-22.63-23.24-7.41-29.18-8.55-47.35-3.39-54C39.74,62,41.3,60,47.09,60,58.3,60,75.91,69.83,90.9,84.47c15.25,14.9,25.1,31.86,25.1,43.2V180A24,24,0,0,1,92,204Zm121.45-87.25C208.81,135.07,199,139.7,190.82,140a47.54,47.54,0,0,0-21.51-7.92,12,12,0,1,0-2.64,23.86A24.36,24.36,0,0,1,188,180a24,24,0,1,1-48,0V127.67c0-11.34,9.85-28.3,25.1-43.2C180.09,69.83,197.7,60,208.91,60c5.79,0,7.35,2,7.94,2.76C222,69.4,220.87,87.57,213.46,116.75Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M225.12,119.71c-7.86,30.94-29.31,32.71-37.36,32.23h-1A36,36,0,1,1,128,180a36,36,0,1,1-58.72-28.06h-1c-8,.48-29.5-1.29-37.36-32.23C22.79,87.84,15.78,48,47.07,48S128,95.8,128,127.67C128,95.8,177.64,48,208.93,48S233.21,87.84,225.12,119.71Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M232.7,50.48C229,45.7,221.84,40,209,40c-16.85,0-38.46,11.28-57.81,30.16A140.07,140.07,0,0,0,136,87.53V56a8,8,0,0,0-16,0V87.53a140.07,140.07,0,0,0-15.15-17.37C85.49,51.28,63.88,40,47,40,34.16,40,27,45.7,23.3,50.48c-6.82,8.77-12.18,24.08-.21,71.2,6.05,23.83,19.51,33,30.63,36.42A44,44,0,0,0,128,205.27a44,44,0,0,0,74.28-47.17c11.12-3.4,24.57-12.59,30.63-36.42C239.63,95.24,244.85,66.1,232.7,50.48ZM92,208A28.12,28.12,0,0,1,88.86,152a8,8,0,1,0-1.76-15.9A43.64,43.64,0,0,0,66.36,144c-8.43.09-22-3.57-27.76-26.26C35.72,106.39,27,71.86,35.94,60.3,37.37,58.46,40.09,56,47,56c27.27,0,73,44.88,73,71.67V180A28,28,0,0,1,92,208ZM217.4,117.74c-5.77,22.69-19.33,26.34-27.77,26.26a43.6,43.6,0,0,0-20.74-7.95,8,8,0,1,0-1.76,15.9A28.11,28.11,0,1,1,136,180V127.67C136,100.88,181.69,56,209,56c6.95,0,9.66,2.46,11.1,4.3C229.05,71.86,220.28,106.39,217.4,117.74Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,100.17a108.42,108.42,0,0,0-8-12.64V56a8,8,0,0,1,16,0V87.53A108.42,108.42,0,0,0,128,100.17ZM232.7,50.48C229,45.7,221.84,40,209,40c-16.85,0-38.46,11.28-57.81,30.16A140.07,140.07,0,0,0,136,87.53V180a8,8,0,0,1-16,0V87.53a140.07,140.07,0,0,0-15.15-17.37C85.49,51.28,63.88,40,47,40,34.16,40,27,45.7,23.3,50.48c-6.82,8.77-12.18,24.08-.21,71.2,6.05,23.83,19.51,33,30.63,36.42A44,44,0,0,0,128,205.27a44,44,0,0,0,74.28-47.17c11.12-3.4,24.57-12.59,30.63-36.42C239.63,95.24,244.85,66.1,232.7,50.48Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M231.1,51.71C226.09,45.27,218.64,42,209,42c-16.33,0-37.41,11.06-56.4,29.59A132,132,0,0,0,134,93.94V56a6,6,0,0,0-12,0V93.94a132,132,0,0,0-18.54-22.35C84.49,53.06,63.4,42,47.07,42c-9.69,0-17.14,3.27-22.15,9.71-5.53,7.11-7.71,17.69-6.66,32.34.91,12.73,4.12,26.53,6.81,37.13,6.28,24.74,20.77,33,31.78,35.68A42,42,0,1,0,128,201.62a42,42,0,1,0,71.16-44.76c11-2.63,25.5-10.94,31.78-35.68C237.55,95.21,242.72,66.65,231.1,51.71ZM92,210a30.12,30.12,0,0,1-3.34-60A6,6,0,0,0,87.35,138a41.71,41.71,0,0,0-20.28,8c-9,.31-24.12-3.16-30.37-27.76-3.25-12.81-11.89-46.83-2.31-59.15C37.05,55.66,41.2,54,47.07,54c12.88,0,31.72,10.28,48,26.18C111.69,96.39,122,114.59,122,127.67V180A30,30,0,0,1,92,210Zm127.31-91.77C213.07,142.83,198,146.29,189,146a41.62,41.62,0,0,0-20.28-8A6,6,0,1,0,167.36,150,30.11,30.11,0,1,1,134,180V127.67c0-13.08,10.32-31.28,26.93-47.49C177.23,64.28,196.07,54,209,54c5.87,0,10,1.66,12.68,5.08C231.21,71.4,222.57,105.42,219.32,118.23Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M232.7,50.48C229,45.7,221.84,40,209,40c-16.85,0-38.46,11.28-57.81,30.16A140.07,140.07,0,0,0,136,87.53V56a8,8,0,0,0-16,0V87.53a140.07,140.07,0,0,0-15.15-17.37C85.49,51.28,63.88,40,47,40,34.16,40,27,45.7,23.3,50.48c-6.82,8.77-12.18,24.08-.21,71.2,6.05,23.83,19.51,33,30.63,36.42A44,44,0,0,0,128,205.27a44,44,0,0,0,74.28-47.17c11.12-3.4,24.57-12.59,30.63-36.42C239.63,95.24,244.85,66.1,232.7,50.48ZM92,208A28.12,28.12,0,0,1,88.86,152a8,8,0,1,0-1.76-15.9A43.64,43.64,0,0,0,66.36,144c-8.43.09-22-3.57-27.76-26.26C35.72,106.39,27,71.86,35.94,60.3,37.37,58.46,40.09,56,47,56c27.27,0,73,44.88,73,71.67V180A28,28,0,0,1,92,208ZM217.4,117.74c-5.77,22.69-19.33,26.34-27.77,26.26a43.6,43.6,0,0,0-20.74-7.95,8,8,0,1,0-1.76,15.9A28.11,28.11,0,1,1,136,180V127.67C136,100.88,181.69,56,209,56c6.95,0,9.66,2.46,11.1,4.3C229.05,71.86,220.28,106.39,217.4,117.74Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M229.5,52.94C224.89,47,218,44,208.93,44c-25.86,0-61.28,28.73-76.93,57.28V56a4,4,0,0,0-8,0v45.28C108.35,72.73,72.93,44,47.07,44c-9,0-16,3-20.57,8.94-11.1,14.26-6,42.25.5,67.75,3.67,14.42,10.55,24.64,20.48,30.38a36.79,36.79,0,0,0,12.75,4.45A40,40,0,1,0,128,197.43a40,40,0,1,0,67.77-41.9,36.82,36.82,0,0,0,12.75-4.46c9.93-5.74,16.81-16,20.48-30.38C235.47,95.19,240.6,67.2,229.5,52.94ZM92,212a32.12,32.12,0,0,1-3.56-64,4,4,0,0,0-.88-8A39.64,39.64,0,0,0,67.78,148c-22.8,1.24-30.68-20-33-29.24-5.65-22.24-11-49.26-1.95-60.87C35.87,53.91,40.54,52,47.07,52,76.55,52,124,98.68,124,127.67V180A32,32,0,0,1,92,212Zm129.24-93.28c-2.34,9.22-10.22,30.47-33,29.24A39.68,39.68,0,0,0,168.44,140a4,4,0,0,0-.88,8A32.1,32.1,0,1,1,132,180V127.67c0-29,47.45-75.67,76.93-75.67,6.53,0,11.2,1.91,14.26,5.85C232.22,69.46,226.89,96.48,221.24,118.72Z" }))
  ]
]);
export {
  c as default
};
