import a from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M108,128a20,20,0,1,1,20-20A20,20,0,0,1,108,128Zm60-20a20,20,0,1,0,20,20A20,20,0,0,0,168,108Zm-40,40a20,20,0,1,0,20,20A20,20,0,0,0,128,148Zm124-20a12,12,0,0,1-12,12H227.28a99.62,99.62,0,0,1-20.62,49.69l9.83,9.82a12,12,0,0,1-17,17l-9.82-9.83A99.62,99.62,0,0,1,140,227.28V240a12,12,0,0,1-24,0V227.28a99.62,99.62,0,0,1-49.69-20.62l-9.82,9.83a12,12,0,0,1-17-17l9.83-9.82A99.62,99.62,0,0,1,28.72,140H16a12,12,0,0,1,0-24H28.72A99.62,99.62,0,0,1,49.34,66.31l-9.83-9.82a12,12,0,0,1,17-17l9.82,9.83A99.62,99.62,0,0,1,116,28.72V16a12,12,0,0,1,24,0V28.72a99.62,99.62,0,0,1,49.69,20.62l9.82-9.83a12,12,0,0,1,17,17l-9.83,9.82A99.62,99.62,0,0,1,227.28,116H240A12,12,0,0,1,252,128Zm-48,0a76,76,0,1,0-76,76A76.08,76.08,0,0,0,204,128Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M128,40a88,88,0,1,0,88,88A88,88,0,0,0,128,40Zm-20,88a20,20,0,1,1,20-20A20,20,0,0,1,108,128Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M136,108a28,28,0,1,0-28,28A28,28,0,0,0,136,108Zm-28,12a12,12,0,1,1,12-12A12,12,0,0,1,108,120Zm68-8a16,16,0,1,1-16,16A16,16,0,0,1,176,112Zm-32,64a16,16,0,1,1-16-16A16,16,0,0,1,144,176Zm96-56H223.66a95.52,95.52,0,0,0-22.39-53.95l12.39-12.39a8,8,0,0,0-11.32-11.32L190,54.73A95.52,95.52,0,0,0,136,32.34V16a8,8,0,0,0-16,0V32.34A95.52,95.52,0,0,0,66.05,54.73L53.66,42.34A8,8,0,0,0,42.34,53.66L54.73,66.05a95.52,95.52,0,0,0-22.39,54H16a8,8,0,0,0,0,16H32.34A95.52,95.52,0,0,0,54.73,190L42.34,202.34a8,8,0,0,0,11.32,11.32l12.39-12.39a95.52,95.52,0,0,0,54,22.39V240a8,8,0,0,0,16,0V223.66A95.52,95.52,0,0,0,190,201.27l12.39,12.39a8,8,0,0,0,11.32-11.32L201.27,190A95.52,95.52,0,0,0,223.66,136H240a8,8,0,0,0,0-16ZM128,208a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,208Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,120H223.66a95.52,95.52,0,0,0-22.39-53.95l12.39-12.39a8,8,0,0,0-11.32-11.32L190,54.73A95.52,95.52,0,0,0,136,32.34V16a8,8,0,0,0-16,0V32.34A95.52,95.52,0,0,0,66.05,54.73L53.66,42.34A8,8,0,0,0,42.34,53.66L54.73,66.05a95.52,95.52,0,0,0-22.39,54H16a8,8,0,0,0,0,16H32.34A95.52,95.52,0,0,0,54.73,190L42.34,202.34a8,8,0,0,0,11.32,11.32l12.39-12.39a95.52,95.52,0,0,0,54,22.39V240a8,8,0,0,0,16,0V223.66A95.52,95.52,0,0,0,190,201.27l12.39,12.39a8,8,0,0,0,11.32-11.32L201.27,190A95.52,95.52,0,0,0,223.66,136H240a8,8,0,0,0,0-16ZM80,108a28,28,0,1,1,28,28A28,28,0,0,1,80,108Zm48,84a16,16,0,1,1,16-16A16,16,0,0,1,128,192Zm48-48a16,16,0,1,1,16-16A16,16,0,0,1,176,144Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M134,108a26,26,0,1,0-26,26A26,26,0,0,0,134,108Zm-26,14a14,14,0,1,1,14-14A14,14,0,0,1,108,122Zm82,6a14,14,0,1,1-14-14A14,14,0,0,1,190,128Zm-48,48a14,14,0,1,1-14-14A14,14,0,0,1,142,176Zm98-54H221.8a93.57,93.57,0,0,0-23.26-56.06l13.7-13.7a6,6,0,0,0-8.48-8.48l-13.7,13.7A93.57,93.57,0,0,0,134,34.2V16a6,6,0,0,0-12,0V34.2A93.57,93.57,0,0,0,65.94,57.46l-13.7-13.7a6,6,0,0,0-8.48,8.48l13.7,13.7A93.57,93.57,0,0,0,34.2,122H16a6,6,0,0,0,0,12H34.2a93.57,93.57,0,0,0,23.26,56.06l-13.7,13.7a6,6,0,1,0,8.48,8.48l13.7-13.7A93.57,93.57,0,0,0,122,221.8V240a6,6,0,0,0,12,0V221.8a93.57,93.57,0,0,0,56.06-23.26l13.7,13.7a6,6,0,0,0,8.48-8.48l-13.7-13.7A93.57,93.57,0,0,0,221.8,134H240a6,6,0,0,0,0-12ZM128,210a82,82,0,1,1,82-82A82.1,82.1,0,0,1,128,210Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M136,108a28,28,0,1,0-28,28A28,28,0,0,0,136,108Zm-28,12a12,12,0,1,1,12-12A12,12,0,0,1,108,120Zm68-8a16,16,0,1,1-16,16A16,16,0,0,1,176,112Zm-32,64a16,16,0,1,1-16-16A16,16,0,0,1,144,176Zm96-56H223.66a95.52,95.52,0,0,0-22.39-53.95l12.39-12.39a8,8,0,0,0-11.32-11.32L190,54.73A95.52,95.52,0,0,0,136,32.34V16a8,8,0,0,0-16,0V32.34A95.52,95.52,0,0,0,66.05,54.73L53.66,42.34A8,8,0,0,0,42.34,53.66L54.73,66.05a95.52,95.52,0,0,0-22.39,54H16a8,8,0,0,0,0,16H32.34A95.52,95.52,0,0,0,54.73,190L42.34,202.34a8,8,0,0,0,11.32,11.32l12.39-12.39a95.52,95.52,0,0,0,54,22.39V240a8,8,0,0,0,16,0V223.66A95.52,95.52,0,0,0,190,201.27l12.39,12.39a8,8,0,0,0,11.32-11.32L201.27,190A95.52,95.52,0,0,0,223.66,136H240a8,8,0,0,0,0-16ZM128,208a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,208Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M132,108a24,24,0,1,0-24,24A24,24,0,0,0,132,108Zm-24,16a16,16,0,1,1,16-16A16,16,0,0,1,108,124Zm80,4a12,12,0,1,1-12-12A12,12,0,0,1,188,128Zm-48,48a12,12,0,1,1-12-12A12,12,0,0,1,140,176Zm100-52H219.91a91.64,91.64,0,0,0-24.12-58.13l15-15a4,4,0,1,0-5.66-5.66l-15,15A91.64,91.64,0,0,0,132,36.09V16a4,4,0,0,0-8,0V36.09A91.64,91.64,0,0,0,65.87,60.21l-15-15a4,4,0,0,0-5.66,5.66l15,15A91.64,91.64,0,0,0,36.09,124H16a4,4,0,0,0,0,8H36.09a91.64,91.64,0,0,0,24.12,58.13l-15,15a4,4,0,0,0,5.66,5.66l15-15A91.64,91.64,0,0,0,124,219.91V240a4,4,0,0,0,8,0V219.91a91.64,91.64,0,0,0,58.13-24.12l15,15a4,4,0,0,0,5.66-5.66l-15-15A91.64,91.64,0,0,0,219.91,132H240a4,4,0,0,0,0-8ZM128,212a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Z" }))
  ]
]);
export {
  l as default
};
