import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M208.49,47.49a12,12,0,0,1,0,17l-52,52a12,12,0,0,1-17-17l52-52A12,12,0,0,1,208.49,47.49ZM252,58.41A54.09,54.09,0,0,1,236.06,96.9l-53.23,53.23A19.86,19.86,0,0,1,168.69,156H156v12.83a20.13,20.13,0,0,1-4.06,12.08,11.45,11.45,0,0,1-1.08,1.23l-8,8a20,20,0,0,1-28.28,0L98.7,174.26,24.49,248.48a12,12,0,1,1-17-17l74.22-74.22L65.86,141.41h0a20,20,0,0,1,0-28.29l8-8A11.56,11.56,0,0,1,75.1,104,20.08,20.08,0,0,1,87.17,100H100V87.3a19.89,19.89,0,0,1,5.86-14.15l53.23-53.23A54.43,54.43,0,0,1,252,58.41Zm-24,0a30.43,30.43,0,0,0-51.94-21.52L124,89v15a20,20,0,0,1-20,20H88.94l-3.28,3.28,21.52,21.53h0l21.53,21.52,3.28-3.28V152a20,20,0,0,1,20-20h15l52.06-52.07A30.24,30.24,0,0,0,228,58.41Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M227.57,88.43l-53.23,53.23a8,8,0,0,1-5.65,2.34H152a8,8,0,0,0-8,8v16.83a8,8,0,0,1-1.62,4.83l-8,8a8,8,0,0,1-11.32,0L74.34,132.94a8,8,0,0,1,0-11.32l8-8A8,8,0,0,1,87.17,112H104a8,8,0,0,0,8-8V87.31a8,8,0,0,1,2.34-5.65l53.23-53.23a42.42,42.42,0,0,1,60,0h0A42.42,42.42,0,0,1,227.57,88.43Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M205.66,50.32a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32-11.31l56-56A8,8,0,0,1,205.66,50.32ZM248,58.41a50.13,50.13,0,0,1-14.77,35.66L180,147.3A15.86,15.86,0,0,1,168.69,152H152v16.83a16,16,0,0,1-3.25,9.66,8.08,8.08,0,0,1-.72.83l-8,8a16,16,0,0,1-22.62,0L98.7,168.6l-77,77.06a8,8,0,0,1-11.32-11.32l77.05-77.05-18.7-18.71a16,16,0,0,1,0-22.63l8-8a8,8,0,0,1,.82-.72A16.14,16.14,0,0,1,87.17,104H104V87.3A15.92,15.92,0,0,1,108.68,76l53.24-53.23A50.43,50.43,0,0,1,248,58.41Zm-16,0a34.43,34.43,0,0,0-58.77-24.35L120,87.3V104a16,16,0,0,1-16,16H87.28L80,127.27,128.72,176l7.28-7.28V152a16,16,0,0,1,16-16h16.69l53.23-53.24A34.21,34.21,0,0,0,232,58.41Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M233.23,22.75a50.46,50.46,0,0,0-71.31,0L108.68,76A15.92,15.92,0,0,0,104,87.3V104H87.17a16.14,16.14,0,0,0-9.66,3.24,8,8,0,0,0-.82.72l-8,8a16,16,0,0,0,0,22.63l18.7,18.71-77,77.05a8,8,0,0,0,11.32,11.32l77-77.06,18.71,18.71a16,16,0,0,0,22.62,0l8-8a8.08,8.08,0,0,0,.72-.83,16,16,0,0,0,3.25-9.66V152h16.69A15.86,15.86,0,0,0,180,147.3l53.23-53.23a50.43,50.43,0,0,0,0-71.32ZM205.66,61.64l-56,56a8,8,0,0,1-11.32-11.31l56-56a8,8,0,0,1,11.32,11.32Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M204.24,51.74a6,6,0,0,1,0,8.48l-56,56a6,6,0,0,1-8.48-8.49l56-56A6,6,0,0,1,204.24,51.74ZM246,58.41a48.12,48.12,0,0,1-14.18,34.24l-53.24,53.24a13.9,13.9,0,0,1-9.89,4.1H152a2,2,0,0,0-2,2v16.83a14.07,14.07,0,0,1-2.84,8.45,6.48,6.48,0,0,1-.54.62l-8,8a14,14,0,0,1-19.8,0L98.7,165.77,20.24,244.24a6,6,0,0,1-8.48-8.48l78.46-78.47L70.1,137.17h0a14,14,0,0,1,0-19.8l8-8a4.53,4.53,0,0,1,.62-.54A14,14,0,0,1,87.17,106H104a2,2,0,0,0,2-2V87.3a13.92,13.92,0,0,1,4.1-9.9l53.23-53.24A48.43,48.43,0,0,1,246,58.41Zm-12,0a36.43,36.43,0,0,0-62.18-25.76L118.58,85.88A2,2,0,0,0,118,87.3V104a14,14,0,0,1-14,14H87.17a2.1,2.1,0,0,0-1,.25l-7.61,7.61a2,2,0,0,0,0,2.83h0l48.73,48.73a2,2,0,0,0,2.82,0l7.62-7.61a2.1,2.1,0,0,0,.25-1V152a14,14,0,0,1,14-14h16.69a2,2,0,0,0,1.41-.59l53.23-53.23A36.19,36.19,0,0,0,234,58.41Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M205.66,50.32a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32-11.31l56-56A8,8,0,0,1,205.66,50.32ZM248,58.41a50.13,50.13,0,0,1-14.77,35.66L180,147.3A15.86,15.86,0,0,1,168.69,152H152v16.83a16,16,0,0,1-3.25,9.66,8.08,8.08,0,0,1-.72.83l-8,8a16,16,0,0,1-22.62,0L98.7,168.6l-77,77.06a8,8,0,0,1-11.32-11.32l77.05-77.05-18.7-18.71a16,16,0,0,1,0-22.63l8-8a8,8,0,0,1,.82-.72A16.14,16.14,0,0,1,87.17,104H104V87.3A15.92,15.92,0,0,1,108.68,76l53.24-53.23A50.43,50.43,0,0,1,248,58.41Zm-16,0a34.43,34.43,0,0,0-58.77-24.35L120,87.3V104a16,16,0,0,1-16,16H87.28L80,127.27,128.72,176l7.28-7.28V152a16,16,0,0,1,16-16h16.69l53.23-53.24A34.21,34.21,0,0,0,232,58.41Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M202.83,53.15a4,4,0,0,1,0,5.66l-56,56a4,4,0,0,1-5.66-5.66l56-56A4,4,0,0,1,202.83,53.15ZM244,58.41a46.13,46.13,0,0,1-13.6,32.83l-53.23,53.24a11.93,11.93,0,0,1-8.48,3.51H152a4,4,0,0,0-4,4v16.83a12.08,12.08,0,0,1-2.44,7.25c-.11.14-.23.28-.35.41l-8,8a12,12,0,0,1-17,0L98.7,163,18.83,242.83a4,4,0,0,1-5.66-5.66l79.88-79.88L71.51,135.75a12,12,0,0,1,0-17l8-8a3.62,3.62,0,0,1,.42-.36A12,12,0,0,1,87.17,108H104a4,4,0,0,0,4-4V87.3a12,12,0,0,1,3.51-8.49l53.24-53.23A46.43,46.43,0,0,1,244,58.41Zm-8,0a38.43,38.43,0,0,0-65.6-27.18L117.17,84.47A4,4,0,0,0,116,87.3V104a12,12,0,0,1-12,12H87.17a4,4,0,0,0-2.23.68l-7.77,7.77a4,4,0,0,0,0,5.66l48.72,48.72a4,4,0,0,0,5.66,0l7.77-7.77a4,4,0,0,0,.68-2.23V152a12,12,0,0,1,12-12h16.69a4,4,0,0,0,2.82-1.17l53.24-53.24A38.21,38.21,0,0,0,236,58.41Z" }))
  ]
]);
export {
  e as default
};
