import a from "react";
const A = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,92a31.86,31.86,0,0,0-9.16,1.34l-27-33.68A32,32,0,1,0,116,44c0,.11,0,.22,0,.33L74.75,61.52a32,32,0,1,0-33.55,52.6l1.62,40.73a32,32,0,1,0,38.49,48.7l42.89,12A32,32,0,1,0,182,193.43l24.16-38a31.68,31.68,0,0,0,5.8.54,32,32,0,0,0,0-64ZM71.64,109.24l34.93,20.22L72,156.33a31.68,31.68,0,0,0-5.24-2.45l-1.62-40.73A32.32,32.32,0,0,0,71.64,109.24Zm81.16-33.6a33.2,33.2,0,0,0,4.36-1l27,33.68a32.81,32.81,0,0,0-2.31,5.08l-38.43,1.83Zm-66,99.62L122,147.89l13.26,39.79a32.6,32.6,0,0,0-4.55,4.77l-42.89-12A33,33,0,0,0,86.78,175.26ZM158,180.07l-13.62-40.84L183,137.4a31.7,31.7,0,0,0,3,5.17l-24.16,38A31.64,31.64,0,0,0,158,180.07ZM148,36a8,8,0,1,1-8,8A8,8,0,0,1,148,36ZM125.25,66.48a33.46,33.46,0,0,0,4.21,3.58l-9.4,39.48L83.68,88.48A31.6,31.6,0,0,0,84,84c0-.11,0-.22,0-.33ZM52,76a8,8,0,1,1-8,8A8,8,0,0,1,52,76Zm4,116a8,8,0,1,1,8-8A8,8,0,0,1,56,192Zm100,28a8,8,0,1,1,8-8A8,8,0,0,1,156,220Zm56-88a8,8,0,1,1,8-8A8,8,0,0,1,212,132Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M148,64a20,20,0,1,1,20-20A20,20,0,0,1,148,64Zm64,40a20,20,0,1,0,20,20A20,20,0,0,0,212,104Zm-56,88a20,20,0,1,0,20,20A20,20,0,0,0,156,192ZM56,164a20,20,0,1,0,20,20A20,20,0,0,0,56,164Zm-4-60A20,20,0,1,0,32,84,20,20,0,0,0,52,104Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M212,96a27.84,27.84,0,0,0-10.51,2L171,59.94A28,28,0,1,0,120,44a28.65,28.65,0,0,0,.15,2.94L73.68,66.3a28,28,0,1,0-28.6,44.83l1.85,46.38a28,28,0,1,0,32.74,41.42L128,212.47a28,28,0,1,0,49.13-18.79l27.21-42.75A28,28,0,1,0,212,96Zm-56,88-.89,0-16.18-48.53,46.65-2.22a27.94,27.94,0,0,0,5.28,9l-27.21,42.75A28,28,0,0,0,156,184ZM62.92,156.87l-1.85-46.38a28,28,0,0,0,10.12-6.13L113.72,129,72.26,161.22A28,28,0,0,0,62.92,156.87ZM149.57,72a27.8,27.8,0,0,0,8.94-2L189,108.06a27.86,27.86,0,0,0-4.18,9.22l-46.57,2.22ZM82.09,173.85,124,141.26l15.94,47.83a28.2,28.2,0,0,0-7.6,8L84,183.53A28,28,0,0,0,82.09,173.85ZM148,32a12,12,0,1,1-12,12A12,12,0,0,1,148,32ZM126.32,61.7A28.44,28.44,0,0,0,134,68.24l-11.3,47.45L79.23,90.52A28,28,0,0,0,80,84a28.65,28.65,0,0,0-.15-2.94ZM40,84A12,12,0,1,1,52,96,12,12,0,0,1,40,84ZM56,196a12,12,0,1,1,12-12A12,12,0,0,1,56,196Zm100,28a12,12,0,1,1,12-12A12,12,0,0,1,156,224Zm56-88a12,12,0,1,1,12-12A12,12,0,0,1,212,136Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,96a27.84,27.84,0,0,0-10.51,2L171,59.94A28,28,0,1,0,120,44a28.65,28.65,0,0,0,.15,2.94L73.68,66.3a28,28,0,1,0-28.6,44.83l1.85,46.38a28,28,0,1,0,32.74,41.42L128,212.47a28,28,0,1,0,49.13-18.79l27.21-42.75A28,28,0,1,0,212,96ZM71.19,104.36,113.72,129,72.26,161.22a28,28,0,0,0-9.34-4.35l-1.85-46.38A28,28,0,0,0,71.19,104.36ZM149.57,72a27.8,27.8,0,0,0,8.94-2L189,108.06a27.86,27.86,0,0,0-4.18,9.22l-46.57,2.22ZM82.09,173.85,124,141.26l15.94,47.83a28.2,28.2,0,0,0-7.6,8L84,183.53A28,28,0,0,0,82.09,173.85ZM156,184l-.89,0-16.18-48.53,46.65-2.22a27.94,27.94,0,0,0,5.28,9l-27.21,42.75A28,28,0,0,0,156,184ZM126.32,61.7A28.44,28.44,0,0,0,134,68.24l-11.3,47.45L79.23,90.52A28,28,0,0,0,80,84a28.65,28.65,0,0,0-.15-2.94Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,98a25.85,25.85,0,0,0-11.11,2.5L168.48,60A26,26,0,1,0,122,44a26.23,26.23,0,0,0,.34,4.19L73,68.74a26,26,0,1,0-26,40.78L49,159A26,26,0,1,0,78.74,196.6L130,211c0,.34,0,.69,0,1a26,26,0,1,0,44.63-18.11l28.85-45.33A26,26,0,1,0,212,98Zm-56,88c-.78,0-1.55,0-2.31.11L136.2,133.62,187,131.2a26.08,26.08,0,0,0,6.35,10.91l-28.85,45.33A25.87,25.87,0,0,0,156,186ZM72.27,163.74A26,26,0,0,0,61,158.48L59,109a26,26,0,0,0,11.86-7.17l46.42,26.87ZM148,70a25.85,25.85,0,0,0,11.11-2.5L191.52,108a25.92,25.92,0,0,0-5.07,11.2l-50.76,2.42L148,70Zm0-40a14,14,0,1,1-14,14A14,14,0,0,1,148,30ZM127,59.26a26.19,26.19,0,0,0,9.34,7.95L124,118.77,76.9,91.48a26,26,0,0,0,.76-11.67ZM38,84A14,14,0,1,1,52,98,14,14,0,0,1,38,84ZM56,198a14,14,0,1,1,14-14A14,14,0,0,1,56,198Zm26-13c0-.34,0-.69,0-1a25.94,25.94,0,0,0-2.35-10.79L125,137.94l17.32,52a26.23,26.23,0,0,0-9.05,9.49Zm74,41a14,14,0,1,1,14-14A14,14,0,0,1,156,226Zm56-88a14,14,0,1,1,14-14A14,14,0,0,1,212,138Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,96a27.84,27.84,0,0,0-10.51,2L171,59.94A28,28,0,1,0,120,44a28.65,28.65,0,0,0,.15,2.94L73.68,66.3a28,28,0,1,0-28.6,44.83l1.85,46.38a28,28,0,1,0,32.74,41.42L128,212.47a28,28,0,1,0,49.13-18.79l27.21-42.75A28,28,0,1,0,212,96Zm-56,88-.89,0-16.18-48.53,46.65-2.22a27.94,27.94,0,0,0,5.28,9l-27.21,42.75A28,28,0,0,0,156,184ZM62.92,156.87l-1.85-46.38a28,28,0,0,0,10.12-6.13L113.72,129,72.26,161.22A28,28,0,0,0,62.92,156.87ZM149.57,72a27.8,27.8,0,0,0,8.94-2L189,108.06a27.86,27.86,0,0,0-4.18,9.22l-46.57,2.22ZM82.09,173.85,124,141.26l15.94,47.83a28.2,28.2,0,0,0-7.6,8L84,183.53A28,28,0,0,0,82.09,173.85ZM148,32a12,12,0,1,1-12,12A12,12,0,0,1,148,32ZM126.32,61.7A28.44,28.44,0,0,0,134,68.24l-11.3,47.45L79.23,90.52A28,28,0,0,0,80,84a28.65,28.65,0,0,0-.15-2.94ZM40,84A12,12,0,1,1,52,96,12,12,0,0,1,40,84ZM56,196a12,12,0,1,1,12-12A12,12,0,0,1,56,196Zm100,28a12,12,0,1,1,12-12A12,12,0,0,1,156,224Zm56-88a12,12,0,1,1,12-12A12,12,0,0,1,212,136Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,100a23.78,23.78,0,0,0-11.65,3L165.9,60a24,24,0,1,0-41.28-10.56L72.29,71.21A24,24,0,1,0,49,107.8l2.11,52.71A24,24,0,1,0,77.7,194.23l54.43,15.24c-.08.83-.13,1.68-.13,2.53a24,24,0,1,0,40.07-17.81l30.6-48.08A23.78,23.78,0,0,0,212,148a24,24,0,0,0,0-48Zm-23.82,21.13-55.06,2.62,13.29-55.81q.79.06,1.59.06a23.78,23.78,0,0,0,11.65-3L194.1,108A23.91,23.91,0,0,0,188.18,121.13Zm-116,45.19a23.9,23.9,0,0,0-13.16-6.12l-2.11-52.71a24.05,24.05,0,0,0,13.52-8.18l50.4,29.18ZM148,28a16,16,0,1,1-16,16A16,16,0,0,1,148,28ZM127.71,56.79a24,24,0,0,0,10.92,9.3l-13.28,55.76L74.48,92.39A23.83,23.83,0,0,0,76,84a24.12,24.12,0,0,0-.62-5.41ZM36,84a16,16,0,1,1,16,16A16,16,0,0,1,36,84ZM56,200a16,16,0,1,1,16-16A16,16,0,0,1,56,200Zm23.87-13.47c.08-.83.13-1.68.13-2.53a23.86,23.86,0,0,0-2.87-11.37l48.86-38,18.73,56.19a24.17,24.17,0,0,0-10.42,11ZM156,228a16,16,0,1,1,16-16A16,16,0,0,1,156,228Zm9.33-38.11A23.78,23.78,0,0,0,156,188a24.94,24.94,0,0,0-3.69.28l-18.85-56.54,55.1-2.62a23.92,23.92,0,0,0,7.37,12.69ZM212,140a16,16,0,1,1,16-16A16,16,0,0,1,212,140Z" }))
  ]
]);
export {
  A as default
};
