import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M243.93,195.71a20,20,0,0,0-8-14.41L203,155.17l12.1-12.1a43.66,43.66,0,0,0,12.11-39.4,103.52,103.52,0,0,0-36.81-60.23A106.19,106.19,0,0,0,122.52,20,104.29,104.29,0,0,0,20,122.52a106.17,106.17,0,0,0,23.43,67.85,103.52,103.52,0,0,0,60.23,36.81,43.64,43.64,0,0,0,39.39-12.11L155.17,203l26.13,32.95a20,20,0,0,0,14.41,8c.55,0,1.1.07,1.66.07a20,20,0,0,0,14.13-5.86l26.64-26.64A19.94,19.94,0,0,0,243.93,195.71ZM44,122.86C44.6,80,80,44.6,122.86,44a81.92,81.92,0,0,1,65.2,31L75,188.06a81.92,81.92,0,0,1-31-65.2ZM197.71,218l-32.1-40.49A12,12,0,0,0,156.9,173l-.69,0a12,12,0,0,0-8.49,3.51L126.09,198.1h0a19.72,19.72,0,0,1-17.75,5.54,76.28,76.28,0,0,1-11.68-3.33L200.31,96.66a76.28,76.28,0,0,1,3.33,11.68,19.72,19.72,0,0,1-5.54,17.75l-21.63,21.63a12,12,0,0,0,1,17.89L218,197.71Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M206.6,81.4,81.4,206.6A93.92,93.92,0,0,1,32,122.69c.69-49.55,41.13-90,90.68-90.68A93.92,93.92,0,0,1,206.6,81.4Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M240,196a16,16,0,0,0-6.65-11.7l-38-27.15,17-16.95a39.67,39.67,0,0,0,11-35.79,99.52,99.52,0,0,0-35.4-57.89A101.93,101.93,0,0,0,122.58,24,100.29,100.29,0,0,0,24,122.58a102.12,102.12,0,0,0,22.55,65.28,99.52,99.52,0,0,0,57.89,35.4,39.68,39.68,0,0,0,35.79-11l16.95-17,27.15,38A16,16,0,0,0,196,240c.44,0,.88.05,1.32.05a16,16,0,0,0,11.31-4.69l26.64-26.64A16,16,0,0,0,240,196ZM59,177.83a86.09,86.09,0,0,1-19-55A84.27,84.27,0,0,1,122.8,40a86.28,86.28,0,0,1,55,19A85.08,85.08,0,0,1,196.58,80.1L80.1,196.58A85.08,85.08,0,0,1,59,177.83ZM197.35,224l-32.63-45.69a8,8,0,0,0-5.85-3.32q-.33,0-.66,0a8,8,0,0,0-5.66,2.34l-23.63,23.63a23.68,23.68,0,0,1-21.36,6.63,80.3,80.3,0,0,1-12.3-3.5l108.8-108.8a80.63,80.63,0,0,1,3.5,12.3,23.67,23.67,0,0,1-6.63,21.36L177.3,152.55a8,8,0,0,0,1,12.17L224,197.35Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,196a16,16,0,0,0-6.65-11.7l-38-27.15,17-16.95a39.67,39.67,0,0,0,11-35.79,99.52,99.52,0,0,0-35.4-57.89A101.93,101.93,0,0,0,122.58,24,100.29,100.29,0,0,0,24,122.58a102.12,102.12,0,0,0,22.55,65.28,99.52,99.52,0,0,0,57.89,35.4,39.68,39.68,0,0,0,35.79-11l16.95-17,27.15,38A16,16,0,0,0,196,240c.44,0,.88.05,1.32.05a16,16,0,0,0,11.31-4.69l26.64-26.64A16,16,0,0,0,240,196Zm-42.6,28-32.63-45.69a8,8,0,0,0-5.85-3.32q-.33,0-.66,0a8,8,0,0,0-5.66,2.34l-23.63,23.63a23.68,23.68,0,0,1-21.36,6.63,80.3,80.3,0,0,1-12.3-3.5l108.8-108.8a80.63,80.63,0,0,1,3.5,12.3,23.67,23.67,0,0,1-6.63,21.36L177.3,152.55a8,8,0,0,0,1,12.17L224,197.36Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M238,196.2A14,14,0,0,0,232.14,186l-39.93-28.52,18.62-18.62a37.65,37.65,0,0,0,10.46-34A97.43,97.43,0,0,0,186.6,48.12a99.82,99.82,0,0,0-64-22.11A98.29,98.29,0,0,0,26,122.61a100.09,100.09,0,0,0,22.11,64,97.4,97.4,0,0,0,56.72,34.69,37.65,37.65,0,0,0,34-10.46l18.62-18.62L186,232.14A14,14,0,0,0,196.2,238c.39,0,.78.05,1.16.05a14,14,0,0,0,9.89-4.1l26.65-26.64A14,14,0,0,0,238,196.2ZM57.47,179.08A88.12,88.12,0,0,1,38,122.77,86.29,86.29,0,0,1,122.77,38a88.21,88.21,0,0,1,56.31,19.46,87.3,87.3,0,0,1,20,22.93L80.4,199.12A87.14,87.14,0,0,1,57.47,179.08Zm167.94,19.69-26.64,26.64a2,2,0,0,1-3-.25l-32.64-45.69A6,6,0,0,0,158.7,177l-.49,0a6,6,0,0,0-4.24,1.76l-23.63,23.62h0a25.69,25.69,0,0,1-23.17,7.17,82,82,0,0,1-15.49-4.72L204.8,91.68a82.19,82.19,0,0,1,4.72,15.49,25.67,25.67,0,0,1-7.18,23.17L178.72,154a6,6,0,0,0,.75,9.12l45.69,32.64a2,2,0,0,1,.25,3Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,196a16,16,0,0,0-6.65-11.7l-38-27.15,17-16.95a39.67,39.67,0,0,0,11-35.79,99.52,99.52,0,0,0-35.4-57.89A101.93,101.93,0,0,0,122.58,24,100.29,100.29,0,0,0,24,122.58a102.12,102.12,0,0,0,22.55,65.28,99.52,99.52,0,0,0,57.89,35.4,39.68,39.68,0,0,0,35.79-11l16.95-17,27.15,38A16,16,0,0,0,196,240c.44,0,.88.05,1.32.05a16,16,0,0,0,11.31-4.69l26.64-26.64A16,16,0,0,0,240,196ZM59,177.83a86.09,86.09,0,0,1-19-55A84.27,84.27,0,0,1,122.8,40a86.28,86.28,0,0,1,55,19A85.08,85.08,0,0,1,196.58,80.1L80.1,196.58A85.08,85.08,0,0,1,59,177.83ZM197.35,224l-32.63-45.69a8,8,0,0,0-5.85-3.32q-.33,0-.66,0a8,8,0,0,0-5.66,2.34l-23.63,23.63a23.68,23.68,0,0,1-21.36,6.63,80.3,80.3,0,0,1-12.3-3.5l108.8-108.8a80.63,80.63,0,0,1,3.5,12.3,23.67,23.67,0,0,1-6.63,21.36L177.3,152.55a8,8,0,0,0,1,12.17L224,197.35Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M236,196.36a12,12,0,0,0-5-8.77L189.13,157.7l20.29-20.29a35.68,35.68,0,0,0,9.91-32.18,95.43,95.43,0,0,0-34-55.55A98.33,98.33,0,0,0,122.63,28,96.3,96.3,0,0,0,28,122.63a98.09,98.09,0,0,0,21.67,62.72,95.43,95.43,0,0,0,55.55,34,35.66,35.66,0,0,0,32.18-9.92l20.29-20.28L187.59,231a12,12,0,0,0,8.78,5c.33,0,.66,0,1,0a12,12,0,0,0,8.48-3.52l26.64-26.64A12,12,0,0,0,236,196.36Zm-180-16A90.11,90.11,0,0,1,36,122.74,88.27,88.27,0,0,1,122.75,36a90.13,90.13,0,0,1,57.59,19.9,89.13,89.13,0,0,1,21.28,24.81L80.72,201.62A89,89,0,0,1,55.91,180.33Zm170.92,19.85-26.65,26.65A4,4,0,0,1,197,228a3.92,3.92,0,0,1-2.93-1.67l-32.64-45.69a4,4,0,0,0-2.92-1.66h-.33a4,4,0,0,0-2.83,1.17l-23.63,23.63h0a27.66,27.66,0,0,1-25,7.72,84.36,84.36,0,0,1-18.6-6L205.47,88.18a84.36,84.36,0,0,1,6,18.6,27.69,27.69,0,0,1-7.72,25l-23.63,23.63a4,4,0,0,0,.5,6.08l45.69,32.64a4,4,0,0,1,.51,6.08Z" }))
  ]
]);
export {
  e as default
};
