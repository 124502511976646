import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M56.88,31.93A12,12,0,1,0,39.12,48.07L59.68,70.69C44,95.22,36,119.84,36,144a92,92,0,0,0,153,68.9l10.16,11.17a12,12,0,0,0,17.76-16.14ZM128,212a68.07,68.07,0,0,1-68-68c0-17.74,5.54-36.12,16.49-54.82l96.32,106A67.84,67.84,0,0,1,128,212ZM92.61,47.44a12,12,0,0,1,.12-17,243.72,243.72,0,0,1,28.39-24.3,12,12,0,0,1,13.76,0C138.36,8.6,220,66.64,220,144a92.8,92.8,0,0,1-1,13.34,12,12,0,0,1-11.86,10.28,12.74,12.74,0,0,1-1.74-.13,12,12,0,0,1-10.15-13.6A69.36,69.36,0,0,0,196,144c0-52.93-49.49-97.91-68-112.88-4.64,3.73-11.2,9.33-18.41,16.44A12,12,0,0,1,92.61,47.44Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M208,144a80,80,0,0,1-160,0c0-72,80-128,80-128S208,72,208,144Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M53.92,34.62A8,8,0,1,0,42.08,45.38L64.72,70.29C48.32,95,40,119.78,40,144a88,88,0,0,0,149.21,63.22l12.87,14.16a8,8,0,1,0,11.84-10.76ZM128,216a72.08,72.08,0,0,1-72-72c0-19.93,6.68-40.57,19.86-61.46L178.43,195.36A71.84,71.84,0,0,1,128,216ZM90,50.51a8,8,0,0,1-.27-11.31A247.8,247.8,0,0,1,123.41,9.45a8,8,0,0,1,9.18,0C136,11.83,216,68.7,216,144a88.08,88.08,0,0,1-3.15,23.4,8,8,0,0,1-7.71,5.88A7.79,7.79,0,0,1,203,173a8,8,0,0,1-5.59-9.83A72.55,72.55,0,0,0,200,144c0-57.24-55.48-105-72-118a252.23,252.23,0,0,0-26.66,24.23A8,8,0,0,1,90,50.51Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M213.92,210.62a8,8,0,1,1-11.84,10.76l-12.9-14.19A87.71,87.71,0,0,1,128,232c-48,0-87.49-38.93-88-86.88-.27-24.34,8.22-49.84,24.73-74.81L42.3,45.63a8.23,8.23,0,0,1,.14-11.38,8,8,0,0,1,11.48.37Zm-10.07-34.86a4,4,0,0,0,6.7-1.27A87.66,87.66,0,0,0,216,144c0-31.4-14.51-64.68-42-96.25a254.19,254.19,0,0,0-41.45-38.3,8,8,0,0,0-9.18,0A251.26,251.26,0,0,0,87.17,42a4,4,0,0,0,0,5.41Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M52.44,36A6,6,0,0,0,43.56,44l23.7,26.07C50.51,94.93,42,119.77,42,144a86,86,0,0,0,147.28,60.33L203.56,220a6,6,0,0,0,8.88-8.08ZM128,218a74.09,74.09,0,0,1-74-74c0-21,7.27-42.76,21.59-64.73L181.18,195.42A73.81,73.81,0,0,1,128,218ZM91.4,49.07a6,6,0,0,1-.2-8.49,245.6,245.6,0,0,1,33.36-29.49,6,6,0,0,1,6.88,0,251.42,251.42,0,0,1,41.09,38C199.66,80.26,214,113.09,214,144a86.2,86.2,0,0,1-3.08,22.87,6,6,0,0,1-5.78,4.41,6.2,6.2,0,0,1-1.59-.21,6,6,0,0,1-4.2-7.38A74,74,0,0,0,202,144c0-59.63-59-108.94-74-120.52A253.83,253.83,0,0,0,99.88,48.86,6,6,0,0,1,91.4,49.07Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M53.92,34.62A8,8,0,1,0,42.08,45.38L64.72,70.29C48.32,95,40,119.78,40,144a88,88,0,0,0,149.21,63.22l12.87,14.16a8,8,0,1,0,11.84-10.76ZM128,216a72.08,72.08,0,0,1-72-72c0-19.93,6.68-40.57,19.86-61.46L178.43,195.36A71.84,71.84,0,0,1,128,216ZM90,50.51a8,8,0,0,1-.27-11.31A247.8,247.8,0,0,1,123.41,9.45a8,8,0,0,1,9.18,0C136,11.83,216,68.7,216,144a88.08,88.08,0,0,1-3.15,23.4,8,8,0,0,1-7.71,5.88A7.79,7.79,0,0,1,203,173a8,8,0,0,1-5.59-9.83A72.55,72.55,0,0,0,200,144c0-57.24-55.48-105-72-118a252.23,252.23,0,0,0-26.66,24.23A8,8,0,0,1,90,50.51Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M51,37.31A4,4,0,0,0,45,42.69L69.81,69.93C52.7,94.85,44,119.75,44,144a84,84,0,0,0,145.32,57.4L205,218.69a4,4,0,1,0,5.92-5.38ZM128,220a76.08,76.08,0,0,1-76-76c0-22.1,7.85-44.95,23.33-68L183.92,195.46A75.83,75.83,0,0,1,128,220ZM92.78,47.62A4,4,0,0,1,92.65,42a244.78,244.78,0,0,1,33.06-29.24,4,4,0,0,1,4.58,0A250.18,250.18,0,0,1,171,50.37c26.81,30.84,41,63.21,41,93.63a84.32,84.32,0,0,1-3,22.34,4,4,0,0,1-3.86,2.94,3.86,3.86,0,0,1-1.06-.14,4,4,0,0,1-2.8-4.92A75.89,75.89,0,0,0,204,144c0-62.29-63.08-113.25-76-123A251.38,251.38,0,0,0,98.44,47.48,4,4,0,0,1,92.78,47.62Z" }))
  ]
]);
export {
  t as default
};
