import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M144,184a16,16,0,1,1-16-16A16,16,0,0,1,144,184Zm76-72v40a92,92,0,0,1-184,0V76A32,32,0,0,1,76,45V44a32,32,0,0,1,60.79-14A32,32,0,0,1,180,60V81a32,32,0,0,1,40,31Zm-24,0a8,8,0,0,0-16,0,12,12,0,0,1-24,0V60a8,8,0,0,0-16,0v44a12,12,0,0,1-24,0V44a8,8,0,0,0-16,0v68a12,12,0,0,1-24,0V76a8,8,0,0,0-16,0v76a68,68,0,0,0,136,0Zm-68,16a55.8,55.8,0,0,0-49.89,30.54,12,12,0,0,0,21.37,10.92,32,32,0,0,1,57,0A12,12,0,0,0,167.21,176a11.83,11.83,0,0,0,5.45-1.32,12,12,0,0,0,5.23-16.14A55.8,55.8,0,0,0,128,128Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M188,96a20,20,0,0,0-20,20V60a20,20,0,0,0-40,0V44a20,20,0,0,0-40,0V76a20,20,0,0,0-40,0v76a80,80,0,0,0,160,0V116A20,20,0,0,0,188,96ZM128,200c-32,0-48-32-48-32s16-32,48-32,48,32,48,32S160,200,128,200Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M188,88a27.75,27.75,0,0,0-12,2.71V60a28,28,0,0,0-41.36-24.6A28,28,0,0,0,80,44v6.71A27.75,27.75,0,0,0,68,48,28,28,0,0,0,40,76v76a88,88,0,0,0,176,0V116A28,28,0,0,0,188,88Zm12,64a72,72,0,0,1-144,0V76a12,12,0,0,1,24,0v36a8,8,0,0,0,16,0V44a12,12,0,0,1,24,0v60a8,8,0,0,0,16,0V60a12,12,0,0,1,24,0v60a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-60,16a12,12,0,1,1-12-12A12,12,0,0,1,140,168Zm-12-40c-36.52,0-54.41,34.94-55.16,36.42a8,8,0,0,0,0,7.16C73.59,173.06,91.48,208,128,208s54.41-34.94,55.16-36.42a8,8,0,0,0,0-7.16C182.41,162.94,164.52,128,128,128Zm0,64c-20.63,0-33.8-16.52-38.7-24,4.9-7.48,18.07-24,38.7-24s33.81,16.53,38.7,24C161.8,175.48,148.63,192,128,192Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M144,180a16,16,0,1,1-16-16A16,16,0,0,1,144,180Zm72-76v48a88,88,0,0,1-176,0V64a16,16,0,0,1,32,0v56a8,8,0,0,0,16,0V32a16,16,0,0,1,32,0v80a8,8,0,0,0,16,0V48a16,16,0,0,1,32,0v80a8,8,0,0,0,16,0V104a16,16,0,0,1,32,0Zm-36.42,74.21c-.7-1.4-17.5-34.21-51.58-34.21s-50.88,32.81-51.58,34.21a4,4,0,0,0,0,3.58c.7,1.4,17.5,34.21,51.58,34.21s50.88-32.81,51.58-34.21A4,4,0,0,0,179.58,178.21Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M138,168a10,10,0,1,1-10-10A10,10,0,0,1,138,168Zm76-52v36a86,86,0,0,1-172,0V76A26,26,0,0,1,82,54.11V44a26,26,0,0,1,51.41-5.51A26,26,0,0,1,174,60V94.11A26,26,0,0,1,214,116Zm-12,0a14,14,0,0,0-28,0v4a6,6,0,0,1-12,0V60a14,14,0,0,0-28,0v44a6,6,0,0,1-12,0V44a14,14,0,0,0-28,0v68a6,6,0,0,1-12,0V76a14,14,0,0,0-28,0v76a74,74,0,0,0,148,0Zm-20.63,49.32a6,6,0,0,1,0,5.36C180.65,172.12,163.3,206,128,206s-52.65-33.88-53.37-35.32a6,6,0,0,1,0-5.36C75.35,163.88,92.7,130,128,130S180.65,163.88,181.37,165.32ZM169.08,168c-4.46-7.12-18.41-26-41.08-26s-36.65,18.85-41.08,26c4.46,7.13,18.41,26,41.08,26S164.65,175.15,169.08,168Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M188,88a27.75,27.75,0,0,0-12,2.71V60a28,28,0,0,0-41.36-24.6A28,28,0,0,0,80,44v6.71A27.75,27.75,0,0,0,68,48,28,28,0,0,0,40,76v76a88,88,0,0,0,176,0V116A28,28,0,0,0,188,88Zm12,64a72,72,0,0,1-144,0V76a12,12,0,0,1,24,0v36a8,8,0,0,0,16,0V44a12,12,0,0,1,24,0v60a8,8,0,0,0,16,0V60a12,12,0,0,1,24,0v60a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-60,16a12,12,0,1,1-12-12A12,12,0,0,1,140,168Zm-12-40c-36.52,0-54.41,34.94-55.16,36.42a8,8,0,0,0,0,7.16C73.59,173.06,91.48,208,128,208s54.41-34.94,55.16-36.42a8,8,0,0,0,0-7.16C182.41,162.94,164.52,128,128,128Zm0,64c-20.63,0-33.8-16.52-38.7-24,4.9-7.48,18.07-24,38.7-24s33.81,16.53,38.7,24C161.8,175.48,148.63,192,128,192Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M136,168a8,8,0,1,1-8-8A8,8,0,0,1,136,168Zm76-52v36a84,84,0,0,1-168,0V76A24,24,0,0,1,84,58.13V44a24,24,0,0,1,47.93-1.8A24,24,0,0,1,172,60V98.13A24,24,0,0,1,212,116Zm-8,0a16,16,0,0,0-32,0v4a4,4,0,0,1-8,0V60a16,16,0,0,0-32,0v44a4,4,0,0,1-8,0V44a16,16,0,0,0-32,0v68a4,4,0,0,1-8,0V76a16,16,0,0,0-32,0v76a76,76,0,0,0,152,0Zm-24.42,50.21a4,4,0,0,1,0,3.58c-.7,1.4-17.5,34.21-51.58,34.21s-50.88-32.81-51.58-34.21a4,4,0,0,1,0-3.58c.7-1.4,17.5-34.21,51.58-34.21S178.88,164.81,179.58,166.21Zm-8.16,1.8c-3.83-6.43-18.55-28-43.42-28s-39.6,21.55-43.42,28c3.84,6.44,18.55,28,43.42,28S167.6,174.45,171.42,168Z" }))
  ]
]);
export {
  t as default
};
