import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M252,128a12,12,0,0,1-12,12H219.21a91.65,91.65,0,0,1-3.56,16,12,12,0,0,1-11.43,8.35,12.19,12.19,0,0,1-3.65-.57,12,12,0,0,1-7.78-15.09A68.06,68.06,0,0,0,128,60a68.73,68.73,0,0,0-14.48,1.54,12,12,0,1,1-5.08-23.45c2.49-.54,5-1,7.56-1.31V16a12,12,0,0,1,24,0V36.78A92.21,92.21,0,0,1,219.22,116H240A12,12,0,0,1,252,128Zm-35.12,79.93a12,12,0,1,1-17.76,16.14l-18.65-20.51A91.6,91.6,0,0,1,140,219.2V240a12,12,0,0,1-24,0V219.22A92.21,92.21,0,0,1,36.78,140H16a12,12,0,0,1,0-24H36.79a92.36,92.36,0,0,1,21-47.42L39.12,48.07A12,12,0,1,1,56.88,31.93L190.69,179.11h0ZM164.14,185.6l-90-99a68,68,0,0,0,90,99Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M208,128a80,80,0,1,1-80-80A80,80,0,0,1,208,128Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M248,128a8,8,0,0,1-8,8H215.64a87,87,0,0,1-7.33,28,8,8,0,0,1-7.3,4.73,7.9,7.9,0,0,1-3.27-.71,8,8,0,0,1-4-10.57,72.06,72.06,0,0,0-88.81-97.69,8,8,0,1,1-5.13-15.15A87.21,87.21,0,0,1,120,40.37V16a8,8,0,0,1,16,0V40.37A88.13,88.13,0,0,1,215.63,120H240A8,8,0,0,1,248,128Zm-34.08,82.62a8,8,0,1,1-11.84,10.76L181,198.23a87.69,87.69,0,0,1-45,17.4V240a8,8,0,0,1-16,0V215.63A88.13,88.13,0,0,1,40.37,136H16a8,8,0,0,1,0-16H40.37A88.31,88.31,0,0,1,63.14,68.54L42.08,45.38A8,8,0,1,1,53.92,34.62Zm-43.72-24.3L74,80.45A72,72,0,0,0,170.2,186.32Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,136H215.63a87.21,87.21,0,0,1-10.13,33.61,4,4,0,0,1-6.5.81L92.51,53.28a4,4,0,0,1,1.4-6.38A87,87,0,0,1,120,40.37V16a8,8,0,0,1,8.53-8A8.17,8.17,0,0,1,136,16.27v24.1A88.13,88.13,0,0,1,215.63,120h24.1a8.17,8.17,0,0,1,8.25,7.47A8,8,0,0,1,240,136ZM53.92,34.62A8,8,0,1,0,42.08,45.38l21.09,23.2A87.63,87.63,0,0,0,40.37,120H16a8,8,0,0,0,0,16H40.37A88.13,88.13,0,0,0,120,215.63V240a8,8,0,0,0,16,0V215.63a87.51,87.51,0,0,0,45-17.43l21.08,23.18a8,8,0,1,0,11.84-10.76Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M246,128a6,6,0,0,1-6,6H213.79a85.2,85.2,0,0,1-7.3,29.2,6,6,0,0,1-5.48,3.55,5.91,5.91,0,0,1-2.45-.53,6,6,0,0,1-3-7.93,74.05,74.05,0,0,0-91.28-100.4,6,6,0,0,1-3.84-11.37A85.64,85.64,0,0,1,122,42.22V16a6,6,0,0,1,12,0V42.23A86.12,86.12,0,0,1,213.77,122H240A6,6,0,0,1,246,128Zm-33.56,84a6,6,0,0,1-8.88,8.08l-22.3-24.54A85.73,85.73,0,0,1,134,213.77V240a6,6,0,0,1-12,0V213.77A86.12,86.12,0,0,1,42.23,134H16a6,6,0,0,1,0-12H42.22A86.23,86.23,0,0,1,65.86,68.56L43.56,44A6,6,0,0,1,52.44,36ZM173.17,186.6,74,77.48A74,74,0,0,0,173.17,186.6Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M248,128a8,8,0,0,1-8,8H215.64a87,87,0,0,1-7.33,28,8,8,0,0,1-7.3,4.73,7.9,7.9,0,0,1-3.27-.71,8,8,0,0,1-4-10.57,72.06,72.06,0,0,0-88.81-97.69,8,8,0,1,1-5.13-15.15A87.21,87.21,0,0,1,120,40.37V16a8,8,0,0,1,16,0V40.37A88.13,88.13,0,0,1,215.63,120H240A8,8,0,0,1,248,128Zm-34.08,82.62a8,8,0,1,1-11.84,10.76L181,198.23a87.69,87.69,0,0,1-45,17.4V240a8,8,0,0,1-16,0V215.63A88.13,88.13,0,0,1,40.37,136H16a8,8,0,0,1,0-16H40.37A88.31,88.31,0,0,1,63.14,68.54L42.08,45.38A8,8,0,1,1,53.92,34.62Zm-43.72-24.3L74,80.45A72,72,0,0,0,170.2,186.32Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M244,128a4,4,0,0,1-4,4H211.9a83.08,83.08,0,0,1-7.24,30.38,4,4,0,0,1-3.65,2.37,3.94,3.94,0,0,1-1.63-.36,4,4,0,0,1-2-5.28A76.06,76.06,0,0,0,103.62,56a4,4,0,1,1-2.56-7.57A83.56,83.56,0,0,1,124,44.1V16a4,4,0,0,1,8,0V44.1A84.11,84.11,0,0,1,211.9,124H240A4,4,0,0,1,244,128Zm-33,85.31a4,4,0,1,1-5.92,5.38l-23.56-25.91A83.81,83.81,0,0,1,132,211.9V240a4,4,0,0,1-8,0V211.9A84.11,84.11,0,0,1,44.1,132H16a4,4,0,0,1,0-8H44.1A84.24,84.24,0,0,1,68.6,68.61L45,42.69A4,4,0,0,1,51,37.31L184.77,184.5h0Zm-34.87-26.47L74,74.53a76,76,0,0,0,102.1,112.31Z" }))
  ]
]);
export {
  t as default
};
