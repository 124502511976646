import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M180,100a16,16,0,1,1-16-16A16,16,0,0,1,180,100Zm-48,16a16,16,0,1,0,16,16A16,16,0,0,0,132,116Zm88-20A92,92,0,1,1,128,4,92.1,92.1,0,0,1,220,96Zm-24,0a68,68,0,1,0-68,68A68.07,68.07,0,0,0,196,96ZM164,197.86a108.61,108.61,0,0,1-72,0,12,12,0,0,0-8,22.63,131.83,131.83,0,0,0,32,7V244a12,12,0,0,0,24,0V227.46a131.83,131.83,0,0,0,32-7,12,12,0,0,0-8-22.63Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M208,96a80,80,0,1,1-80-80A80,80,0,0,1,208,96Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M176,100a12,12,0,1,1-12-12A12,12,0,0,1,176,100Zm-44,20a12,12,0,1,0,12,12A12,12,0,0,0,132,120Zm84-24A88,88,0,1,1,128,8,88.1,88.1,0,0,1,216,96Zm-16,0a72,72,0,1,0-72,72A72.08,72.08,0,0,0,200,96Zm-34.86,96.53C152,197.56,139.85,200,128,200s-24-2.44-37.14-7.47a8,8,0,1,0-5.72,14.94A125.91,125.91,0,0,0,120,215.68V248a8,8,0,0,0,16,0V215.68a125.91,125.91,0,0,0,34.86-8.21,8,8,0,1,0-5.72-14.94Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M175.47,197.14a8,8,0,0,1-4.61,10.33A125.91,125.91,0,0,1,136,215.68V248a8,8,0,0,1-16,0V215.68a125.91,125.91,0,0,1-34.86-8.21,8,8,0,1,1,5.72-14.94C104,197.56,116.15,200,128,200s24-2.44,37.14-7.47A8,8,0,0,1,175.47,197.14ZM216,96A88,88,0,1,1,128,8,88.1,88.1,0,0,1,216,96Zm-72,36a12,12,0,1,0-12,12A12,12,0,0,0,144,132Zm32-32a12,12,0,1,0-12,12A12,12,0,0,0,176,100Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M174,100a10,10,0,1,1-10-10A10,10,0,0,1,174,100Zm-42,22a10,10,0,1,0,10,10A10,10,0,0,0,132,122Zm82-26a86,86,0,1,1-86-86A86.1,86.1,0,0,1,214,96Zm-12,0a74,74,0,1,0-74,74A74.09,74.09,0,0,0,202,96Zm-36.14,98.4c-13.38,5.11-25.77,7.6-37.86,7.6s-24.48-2.49-37.86-7.6a6,6,0,1,0-4.28,11.2A122.32,122.32,0,0,0,122,213.81V248a6,6,0,0,0,12,0V213.81a122.32,122.32,0,0,0,36.14-8.21,6,6,0,0,0-4.28-11.2Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M176,100a12,12,0,1,1-12-12A12,12,0,0,1,176,100Zm-44,20a12,12,0,1,0,12,12A12,12,0,0,0,132,120Zm84-24A88,88,0,1,1,128,8,88.1,88.1,0,0,1,216,96Zm-16,0a72,72,0,1,0-72,72A72.08,72.08,0,0,0,200,96Zm-34.86,96.53C152,197.56,139.85,200,128,200s-24-2.44-37.14-7.47a8,8,0,1,0-5.72,14.94A125.91,125.91,0,0,0,120,215.68V248a8,8,0,0,0,16,0V215.68a125.91,125.91,0,0,0,34.86-8.21,8,8,0,1,0-5.72-14.94Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,100a8,8,0,1,1-8-8A8,8,0,0,1,172,100Zm-40,24a8,8,0,1,0,8,8A8,8,0,0,0,132,124Zm80-28a84,84,0,1,1-84-84A84.09,84.09,0,0,1,212,96Zm-8,0a76,76,0,1,0-76,76A76.08,76.08,0,0,0,204,96ZM166.57,196.26C153,201.47,140.34,204,128,204s-25-2.53-38.57-7.74a4,4,0,1,0-2.86,7.48A119.31,119.31,0,0,0,124,211.91V248a4,4,0,0,0,8,0V211.91a119.31,119.31,0,0,0,37.43-8.17,4,4,0,0,0-2.86-7.48Z" }))
  ]
]);
export {
  t as default
};
