import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M200,212H89.45l110.18-90.74a12,12,0,1,0-15.26-18.52l-17.78,14.64a83.34,83.34,0,0,0-17-6.55,48,48,0,1,0-43.26,0,83.9,83.9,0,0,0-58,54.49,12,12,0,0,0,22.76,7.62,60.05,60.05,0,0,1,74.54-38.3l-97.26,80.1A12,12,0,0,0,56,236H200a12,12,0,0,0,0-24ZM128,44a24,24,0,1,1-24,24A24,24,0,0,1,128,44Zm76.61,113.51a83.58,83.58,0,0,1,6.88,25.17,12,12,0,0,1-23.86,2.64,59.16,59.16,0,0,0-4.9-17.95,12,12,0,0,1,21.88-9.86Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M92,68a36,36,0,1,1,36,36A36,36,0,0,1,92,68Zm36,52a72,72,0,0,0-72,72v32H200V192A72,72,0,0,0,128,120Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M172,68a44,44,0,1,0-44,44A44.05,44.05,0,0,0,172,68ZM128,96a28,28,0,1,1,28-28A28,28,0,0,1,128,96Zm80,128a8,8,0,0,1-8,8H56a8,8,0,0,1-5.29-14l98.07-86.54a64,64,0,0,0-84,50.33A8,8,0,0,1,49,179.25a80,80,0,0,1,113.16-59.59L186.71,98a8,8,0,0,1,10.58,12L77.16,216H200A8,8,0,0,1,208,224Zm-14.27-77.62A79.6,79.6,0,0,1,208,192a8,8,0,0,1-16,0,63.67,63.67,0,0,0-11.41-36.49,8,8,0,0,1,13.14-9.13Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,112a44,44,0,1,1,44-44A44.05,44.05,0,0,1,128,112Zm72,104H77.16L197.29,110a8.17,8.17,0,0,0,1.1-1.19,8.07,8.07,0,0,0,1.61-5.08A8,8,0,0,0,186.71,98l-24.54,21.65A80,80,0,0,0,49,179.25a8.33,8.33,0,0,0-.1,1.1L48,223.83A8,8,0,0,0,56,232H200a8,8,0,0,0,0-16Zm-11.88-73a8,8,0,0,0-6.25,1.94L119.47,200H200a8,8,0,0,0,8-8,79.6,79.6,0,0,0-14.27-45.62A8,8,0,0,0,188.12,143Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,110A42,42,0,1,0,86,68,42,42,0,0,0,128,110Zm0-72A30,30,0,1,1,98,68,30,30,0,0,1,128,38Zm78,186a6,6,0,0,1-6,6H56a6,6,0,0,1-4-10.5l100.58-88.75a66,66,0,0,0-89.78,50.72,6,6,0,0,1-5.91,5.05,6.2,6.2,0,0,1-1-.07,6,6,0,0,1-5-6.88A78,78,0,0,1,162.49,122L188,99.5a6,6,0,1,1,7.94,9L71.87,218H200A6,6,0,0,1,206,224Zm-13.92-76.48A77.53,77.53,0,0,1,206,192a6,6,0,0,1-12,0,65.62,65.62,0,0,0-11.77-37.63,6,6,0,0,1,9.85-6.85Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,68a44,44,0,1,0-44,44A44.05,44.05,0,0,0,172,68ZM128,96a28,28,0,1,1,28-28A28,28,0,0,1,128,96Zm80,128a8,8,0,0,1-8,8H56a8,8,0,0,1-5.29-14l98.07-86.54a64,64,0,0,0-84,50.33A8,8,0,0,1,49,179.25a80,80,0,0,1,113.16-59.59L186.71,98a8,8,0,0,1,10.58,12L77.16,216H200A8,8,0,0,1,208,224Zm-14.27-77.62A79.6,79.6,0,0,1,208,192a8,8,0,0,1-16,0,63.67,63.67,0,0,0-11.41-36.49,8,8,0,0,1,13.14-9.13Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,108A40,40,0,1,0,88,68,40,40,0,0,0,128,108Zm0-72A32,32,0,1,1,96,68,32,32,0,0,1,128,36Zm76,188a4,4,0,0,1-4,4H56a4,4,0,0,1-2.65-7l103-90.84a68,68,0,0,0-95.44,51,4,4,0,0,1-3.94,3.37,4.48,4.48,0,0,1-.64-.05A4,4,0,0,1,53,179.89,76,76,0,0,1,162.8,124.43L189.35,101a4,4,0,1,1,5.3,6L66.58,220H200A4,4,0,0,1,204,224Zm-13.56-75.33A75.52,75.52,0,0,1,204,192a4,4,0,0,1-8,0,67.55,67.55,0,0,0-12.13-38.77,4,4,0,1,1,6.57-4.56Z" }))
  ]
]);
export {
  t as default
};
