import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M174,72.36A68,68,0,0,0,204,16,12,12,0,0,0,192,4h-8a68,68,0,0,0-56,29.49A68,68,0,0,0,72,4H64a12,12,0,0,0,0,24h8a44.07,44.07,0,0,1,42.6,33A92.05,92.05,0,1,0,174,72.36Zm4.22-44a44.14,44.14,0,0,1-36.46,31.24A44.14,44.14,0,0,1,178.23,28.38ZM128,220a68,68,0,1,1,68-68A68.07,68.07,0,0,1,128,220Zm49.62-52.4a52,52,0,0,1-34,34,12.2,12.2,0,0,1-3.6.55,12,12,0,0,1-3.6-23.45,28,28,0,0,0,18.32-18.32,12,12,0,0,1,22.9,7.2Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M208,152a80,80,0,1,1-80-80A80,80,0,0,1,208,152Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M165.87,72.58A64.06,64.06,0,0,0,200,16a8,8,0,0,0-8-8h-8a64,64,0,0,0-56,33.06A64,64,0,0,0,72,8H64a8,8,0,0,0,0,16h8a48.08,48.08,0,0,1,47.4,40.42,88,88,0,1,0,46.47,8.16ZM183.33,24a48.09,48.09,0,0,1-46.66,40A48.09,48.09,0,0,1,183.33,24ZM128,224a72,72,0,1,1,72-72A72.08,72.08,0,0,1,128,224Zm55.89-62.68a57.5,57.5,0,0,1-46.57,46.57A8.52,8.52,0,0,1,136,208a8,8,0,0,1-1.31-15.89,41.29,41.29,0,0,0,33.43-33.43,8,8,0,0,1,15.78,2.64Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M165.87,72.58A64.06,64.06,0,0,0,200,16a8,8,0,0,0-8-8h-8a64,64,0,0,0-56,33.06A64,64,0,0,0,72,8H64a8,8,0,0,0,0,16h8a48.08,48.08,0,0,1,47.4,40.42,88,88,0,1,0,46.47,8.16ZM183.33,24a48.09,48.09,0,0,1-46.66,40A48.09,48.09,0,0,1,183.33,24Zm.56,137.32a57.5,57.5,0,0,1-46.57,46.57A8.52,8.52,0,0,1,136,208a8,8,0,0,1-1.31-15.89,41.29,41.29,0,0,0,33.43-33.43,8,8,0,0,1,15.78,2.64Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M161.15,72.65A62.08,62.08,0,0,0,198,16a6,6,0,0,0-6-6h-8a62.08,62.08,0,0,0-56,35.42A62.08,62.08,0,0,0,72,10H64a6,6,0,0,0,0,12h8a50.06,50.06,0,0,1,49.66,44.26,85.95,85.95,0,1,0,39.49,6.39ZM184,22h1.64A50.07,50.07,0,0,1,136,66h-1.64A50.07,50.07,0,0,1,184,22ZM128,226a74,74,0,1,1,74-74A74.09,74.09,0,0,1,128,226Zm53.92-65A55.48,55.48,0,0,1,137,205.92a6.74,6.74,0,0,1-1,.08,6,6,0,0,1-1-11.92A43.29,43.29,0,0,0,170.08,159a6,6,0,1,1,11.84,2Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M165.87,72.58A64.06,64.06,0,0,0,200,16a8,8,0,0,0-8-8h-8a64,64,0,0,0-56,33.06A64,64,0,0,0,72,8H64a8,8,0,0,0,0,16h8a48.08,48.08,0,0,1,47.4,40.42,88,88,0,1,0,46.47,8.16ZM183.33,24a48.09,48.09,0,0,1-46.66,40A48.09,48.09,0,0,1,183.33,24ZM128,224a72,72,0,1,1,72-72A72.08,72.08,0,0,1,128,224Zm55.89-62.68a57.5,57.5,0,0,1-46.57,46.57A8.52,8.52,0,0,1,136,208a8,8,0,0,1-1.31-15.89,41.29,41.29,0,0,0,33.43-33.43,8,8,0,0,1,15.78,2.64Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M155.62,72.69A60.09,60.09,0,0,0,196,16a4,4,0,0,0-4-4h-8a60.09,60.09,0,0,0-56,38.5A60.09,60.09,0,0,0,72,12H64a4,4,0,0,0,0,8h8a52.06,52.06,0,0,1,51.84,48.11,84,84,0,1,0,31.78,4.58ZM184,20h3.85A52.07,52.07,0,0,1,136,68h-3.85A52.07,52.07,0,0,1,184,20ZM128,228a76,76,0,1,1,76-76A76.08,76.08,0,0,1,128,228Zm51.94-67.34A53.45,53.45,0,0,1,136.66,204a5,5,0,0,1-.66.05,4,4,0,0,1-.66-7.95,45.33,45.33,0,0,0,36.72-36.71,4,4,0,1,1,7.88,1.32Z" }))
  ]
]);
export {
  t as default
};
