import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,124a36,36,0,1,0,36,36A36,36,0,0,0,96,124Zm0,48a12,12,0,1,1,12-12A12,12,0,0,1,96,172Zm128.49-52.49a12,12,0,0,1,0,17l-48,48a12,12,0,0,1-17-17l48-48A12,12,0,0,1,224.49,119.51Zm-36-20a12,12,0,0,1,0,17l-20,20a12,12,0,0,1-17-17l20-20A12,12,0,0,1,188.49,99.51Zm44-27-16,16a12,12,0,0,1-17-17l16-16a12,12,0,0,1,17,17Zm-113,15,72-72a12,12,0,0,1,17,17l-72,72a12,12,0,1,1-17-17Zm30.23,109.26a12,12,0,0,1,0,17A76,76,0,1,1,42.26,106.26L125,23.51a12,12,0,1,1,17,17L59.23,123.23a52,52,0,0,0,73.54,73.54A12,12,0,0,1,149.74,196.77Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,160a32,32,0,1,1-32-32A32,32,0,0,1,128,160Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M96,120a40,40,0,1,0,40,40A40,40,0,0,0,96,120Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,96,184Zm125.66-61.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32-11.32l48-48A8,8,0,0,1,221.66,122.34Zm-56,11.32a8,8,0,0,1-11.32-11.32l24-24a8,8,0,0,1,11.32,11.32Zm64-64-16,16a8,8,0,0,1-11.32-11.32l16-16a8,8,0,0,1,11.32,11.32ZM122.34,90.34l72-72a8,8,0,1,1,11.32,11.32l-72,72a8,8,0,0,1-11.32-11.32ZM146.91,199.6a8,8,0,0,1,0,11.31A72,72,0,1,1,45.09,109.09l82.74-82.75a8,8,0,1,1,11.32,11.32L56.4,120.4a56,56,0,0,0,79.2,79.2A8,8,0,0,1,146.91,199.6Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M136,160a40,40,0,1,1-40-40A40,40,0,0,1,136,160Zm74.34-37.66-48,48a8,8,0,0,0,11.32,11.32l48-48a8,8,0,0,0-11.32-11.32Zm-20.68-12.68a8,8,0,0,0-11.32-11.32l-24,24a8,8,0,0,0,11.32,11.32Zm40-51.32a8,8,0,0,0-11.32,0l-16,16a8,8,0,0,0,11.32,11.32l16-16A8,8,0,0,0,229.66,58.34ZM122.34,101.66a8,8,0,0,0,11.32,0l72-72a8,8,0,1,0-11.32-11.32l-72,72A8,8,0,0,0,122.34,101.66ZM135.6,199.6a56,56,0,0,1-79.2-79.2l82.75-82.74a8,8,0,1,0-11.32-11.32L45.09,109.09A72,72,0,1,0,146.91,210.91,8,8,0,0,0,135.6,199.6Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,122a38,38,0,1,0,38,38A38,38,0,0,0,96,122Zm0,64a26,26,0,1,1,26-26A26,26,0,0,1,96,186Zm124.24-62.24a6,6,0,0,1,0,8.48l-48,48a6,6,0,0,1-8.48-8.48l48-48A6,6,0,0,1,220.24,123.76Zm-56,8.48a6,6,0,0,1-8.48-8.48l24-24a6,6,0,0,1,8.48,8.48Zm64-64-16,16a6,6,0,0,1-8.48-8.48l16-16a6,6,0,0,1,8.48,8.48ZM123.76,91.76l72-72a6,6,0,0,1,8.48,8.48l-72,72a6,6,0,1,1-8.48-8.48ZM145.5,201a6,6,0,0,1,0,8.49,70,70,0,0,1-99-99l82.75-82.74a6,6,0,1,1,8.48,8.48L55,119a58,58,0,1,0,82,82A6,6,0,0,1,145.5,201Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,120a40,40,0,1,0,40,40A40,40,0,0,0,96,120Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,96,184Zm125.66-61.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32-11.32l48-48A8,8,0,0,1,221.66,122.34ZM160,136a8,8,0,0,1-5.66-13.66l24-24a8,8,0,0,1,11.32,11.32l-24,24A8,8,0,0,1,160,136Zm69.66-66.34-16,16a8,8,0,0,1-11.32-11.32l16-16a8,8,0,0,1,11.32,11.32ZM122.34,90.34l72-72a8,8,0,1,1,11.32,11.32l-72,72a8,8,0,0,1-11.32-11.32ZM146.91,199.6a8,8,0,0,1,0,11.31A72,72,0,1,1,45.09,109.09l82.74-82.75a8,8,0,1,1,11.32,11.32L56.4,120.4a56,56,0,0,0,79.2,79.2A8,8,0,0,1,146.91,199.6Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,124a36,36,0,1,0,36,36A36,36,0,0,0,96,124Zm0,64a28,28,0,1,1,28-28A28,28,0,0,1,96,188Zm122.83-62.83a4,4,0,0,1,0,5.66l-48,48a4,4,0,0,1-5.66-5.66l48-48A4,4,0,0,1,218.83,125.17Zm-32-18.34-24,24a4,4,0,0,1-5.66-5.66l24-24a4,4,0,1,1,5.66,5.66Zm40-40-16,16a4,4,0,0,1-5.66-5.66l16-16a4,4,0,1,1,5.66,5.66ZM125.17,93.17l72-72a4,4,0,1,1,5.66,5.66l-72,72a4,4,0,1,1-5.66-5.66Zm18.91,109.26a4,4,0,0,1,0,5.65,68,68,0,1,1-96.16-96.16l82.74-82.75a4,4,0,1,1,5.66,5.66L53.57,117.57a60,60,0,0,0,84.86,84.86A4,4,0,0,1,144.08,202.43Z" }))
  ]
]);
export {
  e as default
};
