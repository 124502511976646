import l from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M236.9,117.88l-5.06,4.36a12,12,0,0,1-16.87-17,12,12,0,0,1,2-23.92l8.27.67a20.45,20.45,0,0,1,11.66,35.87ZM172.53,141.7a12,12,0,0,0-3.84,11.86l4.08,17.22a12,12,0,1,0,23.35-5.54l-2.4-10.12,6.12-5.28a12,12,0,0,0-15.67-18.17Zm33.07,63.55a12,12,0,0,0-23.68,2.93,12,12,0,0,0-12.2,20.66l6.71,4.13a20.39,20.39,0,0,0,30.49-22.15ZM175,102l-15.64-1.26a12,12,0,0,1-10.13-7.38L140,70.94V182.39l5.53,3.4A12,12,0,0,1,133,206.24l-5-3.05L79.57,233a20.39,20.39,0,0,1-30.49-22.15l13.2-55.7L19.1,117.88A20.45,20.45,0,0,1,30.75,82l56.6-4.57,21.82-52.82a20.36,20.36,0,0,1,37.66,0l21.82,52.82,8.32.67A12,12,0,0,1,175,102Zm-59,80.36V70.94l-9.27,22.45a12,12,0,0,1-10.13,7.38l-55.4,4.47L83.47,141.7a12,12,0,0,1,3.84,11.86L74.42,208Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement(
      "path",
      {
        d: "M128,24V189.09L73.28,222.74a8.4,8.4,0,0,1-12.52-9.17l14.88-62.79-48.7-42A8.46,8.46,0,0,1,31.73,94L95.64,88.8l24.62-59.6A8.26,8.26,0,0,1,128,24Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ l.createElement("path", { d: "M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.4,16.4,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26ZM69.09,215.94c-.15.09-.2.12-.38,0a.37.37,0,0,1-.17-.48l14.88-62.8a8,8,0,0,0-2.56-7.91l-48.7-42a.39.39,0,0,1-.13-.51c.09-.27.18-.27.33-.29l63.92-5.16A8,8,0,0,0,103,91.86l17-41.08V184.63ZM223.84,102.73l-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0L136,184.63V50.78l17,41.08a8,8,0,0,0,6.75,4.92l63.91,5.16c.16,0,.25,0,.34.29S224,102.63,223.84,102.73Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.4,16.4,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65A8,8,0,0,0,128,181.1V32c.24,0,.27.08.35.26L153,91.86a8,8,0,0,0,6.75,4.92l63.91,5.16c.16,0,.25,0,.34.29S224,102.63,223.84,102.73Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M233,113.32l-5.06,4.36a6,6,0,1,1-7.83-9.08l5.06-4.36a2.38,2.38,0,0,0,.72-2.65,2.28,2.28,0,0,0-2.07-1.65l-8.28-.67a6,6,0,1,1,1-12l8.27.67a14.17,14.17,0,0,1,12.52,9.89A14.43,14.43,0,0,1,233,113.32Zm-58.45,38.83,4.08,17.22a6,6,0,0,0,5.83,4.62,5.51,5.51,0,0,0,1.39-.17,6,6,0,0,0,4.46-7.22l-3.24-13.67,8.87-7.65a6,6,0,1,0-7.84-9.09l-11.63,10A6,6,0,0,0,174.53,152.15Zm25.23,54.45a6,6,0,1,0-11.67,2.77l1.32,5.57a2.35,2.35,0,0,1-.95,2.57,2.24,2.24,0,0,1-2.6.1l-6.71-4.13a6,6,0,1,0-6.29,10.22l6.72,4.13a14.39,14.39,0,0,0,21.5-15.65ZM175.52,96l-15.64-1.26a6,6,0,0,1-5.06-3.69L134,40.7v145l8.39,5.15a6,6,0,0,1-6.29,10.23l-8.1-5L76.42,227.83a14.39,14.39,0,0,1-21.5-15.65l14-59.25L23,113.32a14.43,14.43,0,0,1-4.3-15.45A14.17,14.17,0,0,1,31.24,88l60.25-4.87,23.22-56.2a14.37,14.37,0,0,1,26.58,0l23.22,56.2,12,1a6,6,0,0,1-1,12ZM122,185.72V40.7L101.18,91.09a6,6,0,0,1-5.06,3.69L32.2,99.94a2.28,2.28,0,0,0-2.07,1.65,2.38,2.38,0,0,0,.72,2.65l48.7,42a6,6,0,0,1,1.92,5.92L66.59,214.94a2.35,2.35,0,0,0,1,2.57,2.24,2.24,0,0,0,2.6.1Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M234.29,114.85l-5.06,4.37a8,8,0,0,1-10.45-12.12l5.06-4.37c.12-.1.23-.19.13-.5s-.18-.27-.34-.29l-8.27-.67a8,8,0,1,1,1.29-15.94l8.27.66a16.46,16.46,0,0,1,9.37,28.86Zm-61.71,37.79,4.08,17.22a8,8,0,0,0,7.78,6.16,7.86,7.86,0,0,0,1.85-.22,8,8,0,0,0,5.94-9.63l-3-12.49,8-6.86a8,8,0,0,0-10.45-12.12l-11.64,10A8,8,0,0,0,172.58,152.64Zm29.13,53.53a8,8,0,0,0-15.57,3.69l1.32,5.58a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-6.72-4.13a8,8,0,0,0-8.38,13.63l6.72,4.13A16.4,16.4,0,0,0,203,211.75ZM175.36,98.05l-15.64-1.27A8,8,0,0,1,153,91.86L136,50.78V184.63l7.43,4.57a8,8,0,1,1-8.38,13.63L128,198.49,77.47,229.57A16.4,16.4,0,0,1,53,211.75l13.76-58.07-45-38.83A16.46,16.46,0,0,1,31.08,86l59-4.76,22.76-55.08a16.36,16.36,0,0,1,30.27,0l22.75,55.08,10.76.87a8,8,0,1,1-1.29,16ZM120,184.63V50.78L103,91.86a8,8,0,0,1-6.75,4.92l-63.92,5.16c-.15,0-.24,0-.33.29a.39.39,0,0,0,.13.51l48.7,42a8,8,0,0,1,2.56,7.91l-14.88,62.8a.37.37,0,0,0,.17.48c.18.14.23.11.38,0Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M231.66,111.81l-5.06,4.36a4,4,0,1,1-5.22-6.06l5.05-4.36a4.37,4.37,0,0,0,1.32-4.77,4.29,4.29,0,0,0-3.81-3l-8.27-.67a4,4,0,1,1,.64-8l8.28.67a12.45,12.45,0,0,1,7.07,21.84ZM176.47,151.7l4.08,17.21a4,4,0,0,0,3.88,3.08,3.69,3.69,0,0,0,.93-.11,4,4,0,0,0,3-4.81l-3.52-14.85,9.79-8.45a4,4,0,0,0-5.22-6.06l-11.63,10A4,4,0,0,0,176.47,151.7Zm21.34,55.37a4,4,0,0,0-7.79,1.84l1.32,5.58a4.38,4.38,0,0,1-1.71,4.65,4.26,4.26,0,0,1-4.82.18l-6.72-4.13A4,4,0,1,0,173.9,222l6.72,4.13a12.4,12.4,0,0,0,18.51-13.49Zm-22.14-113L160,92.79a4,4,0,0,1-3.38-2.46L132,30.73l0-.09v156.2l9.34,5.74a4,4,0,0,1-4.2,6.82L128,193.77,75.38,226.13a12.4,12.4,0,0,1-18.51-13.49l14.32-60.42L24.34,111.81A12.45,12.45,0,0,1,31.41,90l61.47-5,23.68-57.34a12.37,12.37,0,0,1,22.88,0L163.12,85l13.2,1.06a4,4,0,0,1-.65,8ZM124,186.84V30.64l0,.09L99.34,90.33A4,4,0,0,1,96,92.79L32.06,98a4.29,4.29,0,0,0-3.81,3,4.37,4.37,0,0,0,1.32,4.77l48.68,42a4,4,0,0,1,1.28,3.95L64.66,214.49a4.38,4.38,0,0,0,1.71,4.65,4.28,4.28,0,0,0,4.82.18Z" }))
  ]
]);
export {
  e as default
};
