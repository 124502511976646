import a from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M176,92a32,32,0,1,0-32-32A32,32,0,0,0,176,92Zm0-40a8,8,0,1,1-8,8A8,8,0,0,1,176,52ZM28.48,80.64a12,12,0,0,1,14.88-8.16l67.5,19.68,8.66-8.65a12,12,0,0,1,17,0L165,112h35a12,12,0,0,1,0,24H160a12,12,0,0,1-8.48-3.51l-4.89-4.89-110-32.07A12,12,0,0,1,28.48,80.64ZM238.65,211.57A73.77,73.77,0,0,1,177,221L20.65,175.51a12,12,0,1,1,6.71-23l79.92,23.27,13.81-13.81L84.7,151.53a12,12,0,1,1,6.6-23.07l56,16a12,12,0,0,1,5.19,20L133.56,183.4,183.66,198a49.81,49.81,0,0,0,41.68-6.39,12,12,0,0,1,13.31,20Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M192,60a20,20,0,1,1-20-20A20,20,0,0,1,192,60Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M172,88a28,28,0,1,0-28-28A28,28,0,0,0,172,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,172,48ZM32.32,77.76a8,8,0,0,1,9.92-5.44L112,92.67l10.32-10.33a8,8,0,0,1,11.32,0L163.31,112H200a8,8,0,0,1,0,16H160a8,8,0,0,1-5.66-2.34L148.73,120l-111-32.36A8,8,0,0,1,32.32,77.76ZM236.44,204.24a69.78,69.78,0,0,1-58.37,8.95L21.76,167.67a8,8,0,1,1,4.48-15.36l76,22.13L128.73,148,85.8,135.69a8,8,0,0,1,4.4-15.39l56,16a8,8,0,0,1,3.46,13.35l-29.9,29.9,62.79,18.28a53.78,53.78,0,0,0,45-6.9,8,8,0,1,1,8.88,13.31Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M144,60a28,28,0,1,1,28,28A28,28,0,0,1,144,60ZM37.76,87.68l111,32.36,5.61,5.61A8,8,0,0,0,160,128h40a8,8,0,0,0,0-16H163.31l-4.72-4.72,0,0-24.9-24.9a8,8,0,0,0-11.32,0L112,92.67,42.24,72.32a8,8,0,0,0-4.48,15.36Zm200.9,105.47a8,8,0,0,0-11.1-2.22,53.78,53.78,0,0,1-45,6.9l-62.79-18.28,29.9-29.9a8,8,0,0,0-3.46-13.35l-56-16a8,8,0,0,0-4.4,15.39L128.73,148l-26.5,26.49-76-22.13a8,8,0,1,0-4.48,15.36l156.31,45.52a69.78,69.78,0,0,0,58.37-8.95A8,8,0,0,0,238.66,193.15Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,86a26,26,0,1,0-26-26A26,26,0,0,0,172,86Zm0-40a14,14,0,1,1-14,14A14,14,0,0,1,172,46ZM38.32,85.76l111.47,32.51,6,6A6,6,0,0,0,160,126h40a6,6,0,0,0,0-12H162.49L132.24,83.75a6,6,0,0,0-8.48,0L112.59,94.92,41.68,74.24a6,6,0,1,0-3.36,11.52ZM128,96.48l4.14,4.14-6.41-1.87Zm107.33,106.1a67.79,67.79,0,0,1-56.7,8.69L22.32,165.75a6,6,0,1,1,3.36-11.52l77.13,22.46L132.55,147l-46.2-13.2a6,6,0,0,1,3.3-11.54l56,16a6,6,0,0,1,2.59,10L116,180.52l66,19.23a55.79,55.79,0,0,0,46.68-7.15,6,6,0,1,1,6.66,10Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,88a28,28,0,1,0-28-28A28,28,0,0,0,172,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,172,48ZM32.32,77.76a8,8,0,0,1,9.92-5.44L112,92.67l10.32-10.33a8,8,0,0,1,11.32,0L163.31,112H200a8,8,0,0,1,0,16H160a8,8,0,0,1-5.66-2.34L148.73,120l-111-32.36A8,8,0,0,1,32.32,77.76ZM236.44,204.24a69.78,69.78,0,0,1-58.37,8.95L21.77,167.67a8,8,0,1,1,4.47-15.36l76,22.13L128.73,148,85.8,135.69a8,8,0,0,1,4.4-15.39l56,16a8,8,0,0,1,3.46,13.35l-29.9,29.9,62.79,18.28a53.78,53.78,0,0,0,45-6.9,8,8,0,1,1,8.88,13.31Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,84a24,24,0,1,0-24-24A24,24,0,0,0,172,84Zm0-40a16,16,0,1,1-16,16A16,16,0,0,1,172,44ZM38.88,83.84l112,32.65,6.33,6.33A4,4,0,0,0,160,124h40a4,4,0,0,0,0-8H161.66L130.83,85.17a4,4,0,0,0-5.66,0l-12,12-72.05-21a4,4,0,0,0-2.24,7.68ZM128,93.65l11.07,11.08-17.14-5ZM234.22,200.92a65.8,65.8,0,0,1-55,8.43L22.88,163.83a4,4,0,1,1,2.24-7.68l78.27,22.79,33-33L86.9,131.84a4,4,0,1,1,2.2-7.69l56,16a4,4,0,0,1,1.73,6.67L112.15,181.5l69.28,20.17a57.81,57.81,0,0,0,48.35-7.41,4,4,0,0,1,4.44,6.66Z" }))
  ]
]);
export {
  l as default
};
