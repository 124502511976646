import a from "react";
const A = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M177.17,207.11A12,12,0,1,1,161,224.88,203.53,203.53,0,0,0,24,172a12,12,0,0,1,0-24A227.45,227.45,0,0,1,177.17,207.11Zm-74.74-2A186.47,186.47,0,0,0,24,188a12,12,0,0,0,0,24,162.61,162.61,0,0,1,68.41,14.9,12,12,0,1,0,10-21.81ZM244,160a12,12,0,0,1-12,12,205.19,205.19,0,0,0-31.38,2.4,270,270,0,0,1,32.59,34.09,12,12,0,1,1-18.72,15A243,243,0,0,0,24,132a12,12,0,0,1,0-24,271,271,0,0,1,44,3.6V80a12,12,0,0,1,4.8-9.6l64-48a12,12,0,0,1,14.4,0l64,48A12,12,0,0,1,220,80v28.28c4-.17,8-.28,12-.28a12,12,0,0,1,0,24,242.85,242.85,0,0,0-74.42,11.55q9.28,5.35,18.15,11.46A228.59,228.59,0,0,1,232,148,12,12,0,0,1,244,160Zm-88-52H132v19.3q11.83-4.77,24-8.39Zm-64,8.69c5.39,1.4,10.72,3,16,4.72V96a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12v17q8-1.56,16-2.65V86L144,47,92,86Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M223.85,216H24V120a257,257,0,0,1,56.2,6.21l-.2-.07V80l64-48,64,48v41.11a254.67,254.67,0,0,0-40,7V96H120v42.61l0,0,0,0A256.79,256.79,0,0,1,223.85,216Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M232,160a8,8,0,0,0,0-16,232.2,232.2,0,0,0-65.8,9.47q-9-5.78-18.46-10.78A246.3,246.3,0,0,1,232,128a8,8,0,0,0,0-16c-5.36,0-10.69.18-16,.49V80a8,8,0,0,0-3.2-6.4l-64-48a8,8,0,0,0-9.6,0l-64,48A8,8,0,0,0,72,80v36.37A266.33,266.33,0,0,0,24,112a8,8,0,0,0,0,16,247,247,0,0,1,193.61,93,8,8,0,1,0,12.48-10q-7.59-9.5-15.94-18.14c5.92-.57,11.89-.86,17.85-.86a8,8,0,0,0,0-16,201.53,201.53,0,0,0-32.59,2.65q-7.75-6.92-16-13.16A216.51,216.51,0,0,1,232,160ZM128,133.24V104h32v17.94a262.48,262.48,0,0,0-31.93,11.33ZM88,84l56-42,56,42v29.93q-12.12,1.45-24,4V96a8,8,0,0,0-8-8H120a8,8,0,0,0-8,8v31q-11.79-4.15-24-7.18Zm48.83,136.43a8,8,0,0,1-11.09,2.23A183.15,183.15,0,0,0,24,192a8,8,0,0,1,0-16,199.11,199.11,0,0,1,110.6,33.34A8,8,0,0,1,136.83,220.43Zm49.54-10.14a8,8,0,1,1-11.2,11.42A214.81,214.81,0,0,0,24,160a8,8,0,0,1,0-16A230.69,230.69,0,0,1,186.37,210.29Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M136.83,220.43a8,8,0,0,1-11.09,2.23A183.15,183.15,0,0,0,24,192a8,8,0,0,1,0-16,199.11,199.11,0,0,1,110.6,33.34A8,8,0,0,1,136.83,220.43ZM24,144a8,8,0,0,0,0,16,214.81,214.81,0,0,1,151.17,61.71,8,8,0,1,0,11.2-11.42A230.69,230.69,0,0,0,24,144Zm208,16a216.51,216.51,0,0,0-48.59,5.49q8.24,6.25,16,13.16A201.53,201.53,0,0,1,232,176a8,8,0,0,1,0,16c-6,0-11.93.29-17.85.86q8.32,8.67,15.94,18.14a8,8,0,1,1-12.48,10A247,247,0,0,0,24,128a8,8,0,0,1,0-16,265.43,265.43,0,0,1,48,4.38V80a8,8,0,0,1,3.2-6.4l64-48a8,8,0,0,1,9.6,0l64,48A8,8,0,0,1,216,80v32.5c5.31-.32,10.64-.5,16-.5a8,8,0,0,1,0,16,246.3,246.3,0,0,0-84.26,14.69q9.44,5,18.46,10.78A232.2,232.2,0,0,1,232,144a8,8,0,0,1,0,16ZM128.07,133.27A261.51,261.51,0,0,1,168,119.81V96H120v34C122.71,131,125.4,132.13,128.07,133.27Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M232,158a6,6,0,0,0,0-12,230.11,230.11,0,0,0-66.11,9.65,260.34,260.34,0,0,0-23.07-13.28A248.25,248.25,0,0,1,232,126a6,6,0,0,0,0-12c-6,0-12,.22-18,.62V80a6,6,0,0,0-2.4-4.8l-64-48a6,6,0,0,0-7.2,0l-64,48A6,6,0,0,0,74,80v38.77A264.25,264.25,0,0,0,24,114a6,6,0,0,0,0,12,249,249,0,0,1,195.17,93.75,6,6,0,0,0,4.69,2.25,6,6,0,0,0,4.67-9.75,265,265,0,0,0-18.69-20.94A190.58,190.58,0,0,1,232,190a6,6,0,0,0,0-12,199.1,199.1,0,0,0-33.21,2.79q-9.63-8.65-20-16.25A218.72,218.72,0,0,1,232,158ZM126,134.56V102h36v21.46a259.11,259.11,0,0,0-33.93,12ZM86,83l58-43.5L202,83v32.71a260.51,260.51,0,0,0-28,4.73V96a6,6,0,0,0-6-6H120a6,6,0,0,0-6,6v33.85a258.87,258.87,0,0,0-28-8.46Zm49.17,136.32a6,6,0,0,1-8.32,1.68A185.14,185.14,0,0,0,24,190a6,6,0,0,1,0-12,197.1,197.1,0,0,1,109.49,33A6,6,0,0,1,135.17,219.32Zm49.8-7.61a6,6,0,1,1-8.4,8.57A216.79,216.79,0,0,0,24,158a6,6,0,0,1,0-12A228.74,228.74,0,0,1,185,211.71Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M136.83,220.43a8,8,0,0,1-11.09,2.23A183.15,183.15,0,0,0,24,192a8,8,0,0,1,0-16,199.11,199.11,0,0,1,110.6,33.34A8,8,0,0,1,136.83,220.43ZM24,144a8,8,0,0,0,0,16,214.81,214.81,0,0,1,151.17,61.71,8,8,0,1,0,11.2-11.42A230.69,230.69,0,0,0,24,144Zm208,16a216.51,216.51,0,0,0-48.59,5.49q8.24,6.25,16,13.16A201.53,201.53,0,0,1,232,176a8,8,0,0,1,0,16c-6,0-11.93.29-17.85.86q8.32,8.67,15.94,18.14a8,8,0,1,1-12.48,10A247,247,0,0,0,24,128a8,8,0,0,1,0-16,266.33,266.33,0,0,1,48,4.37V80a8,8,0,0,1,3.2-6.4l64-48a8,8,0,0,1,9.6,0l64,48A8,8,0,0,1,216,80v32.49c5.31-.31,10.64-.49,16-.49a8,8,0,0,1,0,16,246.3,246.3,0,0,0-84.26,14.69q9.44,5,18.46,10.78A232.2,232.2,0,0,1,232,144a8,8,0,0,1,0,16ZM120,88h48a8,8,0,0,1,8,8v21.94q11.88-2.56,24-4V84L144,42,88,84v35.81q12.19,3,24,7.18V96A8,8,0,0,1,120,88Zm8.07,45.27A262.48,262.48,0,0,1,160,121.94V104H128v29.24Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M232,156a4,4,0,0,0,0-8,228.11,228.11,0,0,0-66.42,9.84,256.45,256.45,0,0,0-27.66-15.67A250.1,250.1,0,0,1,232,124a4,4,0,0,0,0-8q-10.06,0-20,.76V80a4,4,0,0,0-1.6-3.2l-64-48a4,4,0,0,0-4.8,0l-64,48A4,4,0,0,0,76,80v41.2A261.74,261.74,0,0,0,24,116a4,4,0,0,0,0,8,251,251,0,0,1,196.73,94.5,4,4,0,0,0,6.24-5,262.61,262.61,0,0,0-21.44-23.64A190.11,190.11,0,0,1,232,188a4,4,0,0,0,0-8,198.15,198.15,0,0,0-33.84,2.92,261.44,261.44,0,0,0-24-19.25A220.46,220.46,0,0,1,232,156Zm-68-31a259.84,259.84,0,0,0-35.93,12.65c-1.36-.58-2.71-1.18-4.07-1.74V100h40ZM84,82l60-45,60,45v35.49a258.55,258.55,0,0,0-32,5.45V96a4,4,0,0,0-4-4H120a4,4,0,0,0-4,4v36.71A258.64,258.64,0,0,0,84,123Zm49.5,136.21a4,4,0,0,1-5.55,1.12A187.08,187.08,0,0,0,24,188a4,4,0,0,1,0-8,195.12,195.12,0,0,1,108.38,32.67A4,4,0,0,1,133.5,218.21Zm50.07-5.07a4,4,0,0,1-5.6,5.72A218.76,218.76,0,0,0,24,156a4,4,0,0,1,0-8A226.71,226.71,0,0,1,183.57,213.14Z" }))
  ]
]);
export {
  A as default
};
