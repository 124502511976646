import e from "react";
const c = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm84,107.86c-.56,1.41-5.7,7.11-22.88,12.33a157.26,157.26,0,0,1-17.57,4.15c.3-5.44.45-10.91.45-16.34,0-27.85-3.95-56.83-12.25-77.75A84.13,84.13,0,0,1,212,127.86ZM127.86,44c1.41.56,7.11,5.7,12.33,22.88,5,16.6,7.81,38.31,7.81,61.12,0,6.56-.24,13-.68,19.32-6.29.44-12.76.68-19.32.68-22.81,0-44.52-2.78-61.12-7.81C49.7,135,44.56,129.27,44,127.86A84.1,84.1,0,0,1,127.86,44ZM50.25,159.75C71.17,168.05,100.15,172,128,172c5.43,0,10.9-.15,16.34-.46a156.93,156.93,0,0,1-4.15,17.58C135,206.3,129.27,211.44,127.86,212A84.13,84.13,0,0,1,50.25,159.75Zm109.5,46c4.19-10.57,7.28-23.2,9.3-36.7,13.5-2,26.13-5.11,36.7-9.3A84.42,84.42,0,0,1,159.75,205.75Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z", opacity: "0.2" }), /* @__PURE__ */ e.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm88,104c0,6-17,16.23-48.77,21.17.51-7,.77-14.08.77-21.17,0-32.24-5.35-64.72-15.74-84.6A88.17,88.17,0,0,1,216,128ZM128,40c8.15,0,24,31.06,24,88,0,8.24-.34,15.92-.93,23.07-7.15.59-14.83.93-23.07.93-56.94,0-88-15.85-88-24A88.1,88.1,0,0,1,128,40ZM43.4,152.26C63.28,162.65,95.76,168,128,168c7.09,0,14.19-.26,21.17-.77C144.23,199,134,216,128,216A88.17,88.17,0,0,1,43.4,152.26ZM152.26,212.6c6.29-12,10.73-28.67,13.26-47.08,18.41-2.53,35-7,47.08-13.26A88.4,88.4,0,0,1,152.26,212.6Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,16c8.15,0,24,31.06,24,88,0,8.24-.34,15.92-.93,23.07-7.15.59-14.83.93-23.07.93-56.94,0-88-15.85-88-24A88.1,88.1,0,0,1,128,40ZM43.4,152.26C63.28,162.65,95.76,168,128,168c7.09,0,14.19-.26,21.17-.77C144.23,199,134,216,128,216A88.17,88.17,0,0,1,43.4,152.26Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm90,102c0,6.59-12.94,13.24-24,16.94a167.93,167.93,0,0,1-28.92,6.53c.62-7.6.94-15.46.94-23.47,0-26.27-3.44-51-9.68-69.78a85.57,85.57,0,0,0-8-17.91A90.16,90.16,0,0,1,218,128ZM128,38c6.59,0,13.24,12.94,16.94,24,5.84,17.53,9.06,41,9.06,66,0,8.83-.4,17.15-1.11,24.89-8,.73-16.35,1.11-24.89,1.11-54.94,0-90-15.4-90-26A90.1,90.1,0,0,1,128,38ZM40.31,148.3a85.57,85.57,0,0,0,17.91,8C77,162.56,101.73,166,128,166c8,0,15.86-.32,23.45-.94C146.64,198.2,136,218,128,218A90.16,90.16,0,0,1,40.31,148.3Zm108,67.39a85.57,85.57,0,0,0,8-17.91,184,184,0,0,0,7.43-34,184,184,0,0,0,34-7.43,85.57,85.57,0,0,0,17.91-8A90.3,90.3,0,0,1,148.3,215.69Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm88,104c0,6-17,16.23-48.77,21.17.51-7,.77-14.08.77-21.17,0-32.24-5.35-64.72-15.74-84.6A88.17,88.17,0,0,1,216,128ZM128,40c8.15,0,24,31.06,24,88,0,8.24-.34,15.92-.93,23.07-7.15.59-14.83.93-23.07.93-56.94,0-88-15.85-88-24A88.1,88.1,0,0,1,128,40ZM43.4,152.26C63.28,162.65,95.76,168,128,168c7.09,0,14.19-.26,21.17-.77C144.23,199,134,216,128,216A88.17,88.17,0,0,1,43.4,152.26ZM152.26,212.6c6.29-12,10.73-28.67,13.26-47.08,18.41-2.53,35-7,47.08-13.26A88.4,88.4,0,0,1,152.26,212.6Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm92,100c0,10.16-22.26,21.21-57.11,25.8C163.61,145.62,164,137,164,128c0-41.16-8.07-75-20.28-90.65A92.14,92.14,0,0,1,220,128ZM128,36c13.24,0,28,37.78,28,92,0,9.45-.46,18.39-1.27,26.73-8.34.81-17.28,1.27-26.73,1.27-54.22,0-92-14.76-92-28A92.1,92.1,0,0,1,128,36ZM37.35,143.72C53,155.93,86.84,164,128,164c9,0,17.62-.39,25.8-1.11C149.21,197.74,138.16,220,128,220A92.14,92.14,0,0,1,37.35,143.72Zm106.37,74.93c8.63-11.06,15.19-31.22,18.3-56.63,25.41-3.11,45.57-9.67,56.63-18.3A92.23,92.23,0,0,1,143.72,218.65Z" }))
  ]
]);
export {
  c as default
};
