var R = Object.defineProperty;
var l = Object.getOwnPropertySymbols;
var n = Object.prototype.hasOwnProperty, c = Object.prototype.propertyIsEnumerable;
var i = (e, r, t) => r in e ? R(e, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[r] = t, m = (e, r) => {
  for (var t in r || (r = {}))
    n.call(r, t) && i(e, t, r[t]);
  if (l)
    for (var t of l(r))
      c.call(r, t) && i(e, t, r[t]);
  return e;
};
var w = (e, r) => {
  var t = {};
  for (var s in e)
    n.call(e, s) && r.indexOf(s) < 0 && (t[s] = e[s]);
  if (e != null && l)
    for (var s of l(e))
      r.indexOf(s) < 0 && c.call(e, s) && (t[s] = e[s]);
  return t;
};
import g, { forwardRef as v } from "react";
const d = v((e, r) => {
  const a = e, {
    alt: t,
    color: s = "currentColor",
    size: o = "1em",
    weight: f = "regular",
    mirrored: h = !1,
    children: S,
    weights: p
  } = a, u = w(a, [
    "alt",
    "color",
    "size",
    "weight",
    "mirrored",
    "children",
    "weights"
  ]);
  return /* @__PURE__ */ g.createElement(
    "svg",
    m({
      ref: r,
      xmlns: "http://www.w3.org/2000/svg",
      width: o,
      height: o,
      fill: s,
      viewBox: "0 0 256 256",
      transform: h ? "scale(-1, 1)" : void 0
    }, u),
    !!t && /* @__PURE__ */ g.createElement("title", null, t),
    S,
    p.get(f)
  );
});
d.displayName = "SSRBase";
const E = d;
export {
  E as default
};
