import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212.89,210.08l-13.78,3.69-8.57-32a44.07,44.07,0,0,0,20-49.17C197.73,85,171.23,43.28,170.11,41.53a12,12,0,0,0-13-5.17l-16.9,4.22c0-5.34-.18-8.63-.2-9.13a12,12,0,0,0-9.08-11.09l-32-8a12,12,0,0,0-13,5.17C84.77,19.28,58.27,61,45.51,108.6a44.07,44.07,0,0,0,19.95,49.17l-8.57,32-13.78-3.69a12,12,0,0,0-6.22,23.18l48,12.86a12,12,0,0,0,6.22-23.18l-11-3,8.57-32a44.21,44.21,0,0,0,34.62-17.65q1,4.53,2.23,9A44.06,44.06,0,0,0,167.36,188l8.57,32-11,3a12,12,0,0,0,6.22,23.18l48-12.86a12,12,0,1,0-6.22-23.18ZM162.59,77l-22.82,5.71c-.21-6.92-.19-12.83-.1-17.24l14.62-3.65C156.5,65.61,159.38,70.79,162.59,77ZM101.72,37.8l14.46,3.61c0,4,0,9.25-.24,15.34,0,.92-.11,2.2-.17,3.82L92.51,54.76C96.08,47.78,99.3,42,101.72,37.8ZM82.83,139.31a20,20,0,0,1-14.14-24.49,281,281,0,0,1,13.4-37.93l31.94,8a304.16,304.16,0,0,1-6.73,40.4A20,20,0,0,1,82.83,139.31Zm65.84,9.86A264.83,264.83,0,0,1,141.31,107l31.82-8a283,283,0,0,1,14.18,39.73,20,20,0,0,1-38.64,10.35Zm36-109.38a12,12,0,0,1,7.59-15.17l12-4a12,12,0,0,1,7.58,22.76l-12,4a12,12,0,0,1-15.17-7.59ZM236,72a12,12,0,0,1-12,12H212a12,12,0,0,1,0-24h12A12,12,0,0,1,236,72ZM28.62,20.21a12,12,0,0,1,15.17-7.59l12,4a12,12,0,1,1-7.58,22.76l-12-4A12,12,0,0,1,28.62,20.21ZM36,76H24a12,12,0,0,1,0-24H36a12,12,0,0,1,0,24Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M75.23,62.81l51.69,12.92a332.07,332.07,0,0,1-8,52.54A32,32,0,1,1,57.1,111.71,307.67,307.67,0,0,1,75.23,62.81Zm123.67,72.9a310.76,310.76,0,0,0-19-50.69L128.49,97.88c1.13,16.36,3.57,35.67,8.59,54.39a32,32,0,1,0,61.82-16.56Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M213.93,213.94l-17.65,4.73-10.42-38.89a40.06,40.06,0,0,0,20.77-46.14c-12.6-47-38.78-88.22-39.89-89.95a8,8,0,0,0-8.68-3.45L136.2,45.71c0-8.25-.18-13.43-.21-14.08a8,8,0,0,0-6.05-7.39l-32-8a8,8,0,0,0-8.68,3.45c-1.11,1.73-27.29,42.93-39.89,90a40.06,40.06,0,0,0,20.77,46.14L59.72,194.67l-17.65-4.73a8,8,0,0,0-4.14,15.46l48,12.86a8.23,8.23,0,0,0,2.07.27,8,8,0,0,0,2.07-15.73l-14.9-4,10.42-38.89c.81.05,1.61.08,2.41.08a40.12,40.12,0,0,0,37-24.88c1.18,6.37,2.6,12.82,4.31,19.22A40.08,40.08,0,0,0,168,184c.8,0,1.6,0,2.41-.08l10.42,38.89-14.9,4A8,8,0,0,0,168,242.53a8.23,8.23,0,0,0,2.07-.27l48-12.86a8,8,0,0,0-4.14-15.46ZM156.22,57.19c2.78,4.7,7.23,12.54,12.2,22.46L136,87.77c-.42-10-.38-18.25-.25-23.79,0-.56.05-1.12.08-1.68Zm-56.44-24,20.37,5.09c.06,4.28,0,10.67-.21,18.47-.06,1.21-.16,3.19-.23,5.84,0,1-.1,2-.16,3L86.69,57.43C92,46.67,96.84,38.16,99.78,33.19Zm11.39,93.09a24,24,0,0,1-46.34-12.5,291.26,291.26,0,0,1,15-41.59l38.58,9.64A314,314,0,0,1,111.17,126.28Zm33.64,23.92A274,274,0,0,1,137,104l38.41-9.6a293.06,293.06,0,0,1,15.75,43.39,24,24,0,1,1-46.36,12.42Zm40-106.62a8,8,0,0,1,3.58-10.74l16-8a8,8,0,1,1,7.16,14.32l-16,8a8,8,0,0,1-10.74-3.58ZM232,72a8,8,0,0,1-8,8H208a8,8,0,0,1,0-16h16A8,8,0,0,1,232,72ZM32.84,20.42a8,8,0,0,1,10.74-3.58l16,8a8,8,0,0,1-7.16,14.32l-16-8A8,8,0,0,1,32.84,20.42ZM40,72H24a8,8,0,0,1,0-16H40a8,8,0,0,1,0,16Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M213.93,213.94l-17.65,4.73-10.42-38.89a40.06,40.06,0,0,0,20.77-46.14c-12.6-47-38.78-88.22-39.89-89.95a8,8,0,0,0-8.68-3.45L136.2,45.71c0-8.25-.18-13.43-.21-14.08a8,8,0,0,0-6.05-7.39l-32-8a8,8,0,0,0-8.68,3.45c-1.11,1.73-27.29,42.93-39.89,90a40.06,40.06,0,0,0,20.77,46.14L59.72,194.67l-17.65-4.73a8,8,0,0,0-4.14,15.46l48,12.86a8.23,8.23,0,0,0,2.07.27,8,8,0,0,0,2.07-15.73l-14.9-4,10.42-38.89c.81.05,1.61.08,2.41.08a40.12,40.12,0,0,0,37-24.88c1.18,6.37,2.6,12.82,4.31,19.22A40.08,40.08,0,0,0,168,184c.8,0,1.6,0,2.41-.08l10.42,38.89-14.9,4A8,8,0,0,0,168,242.53a8.23,8.23,0,0,0,2.07-.27l48-12.86a8,8,0,0,0-4.14-15.46ZM156.22,57.19c2.78,4.7,7.23,12.54,12.2,22.46L136,87.77c-.42-10-.38-18.25-.25-23.79,0-.56.05-1.12.08-1.68Zm-56.44-24,20.37,5.09c.06,4.28,0,10.67-.21,18.47-.06,1.21-.16,3.19-.23,5.84,0,1-.1,2-.16,3L86.69,57.43C92,46.67,96.84,38.16,99.78,33.19Zm85.06,10.39a8,8,0,0,1,3.58-10.74l16-8a8,8,0,1,1,7.16,14.32l-16,8a8,8,0,0,1-10.74-3.58ZM232,72a8,8,0,0,1-8,8H208a8,8,0,0,1,0-16h16A8,8,0,0,1,232,72ZM32.84,20.42a8,8,0,0,1,10.74-3.58l16,8a8,8,0,0,1-7.16,14.32l-16-8A8,8,0,0,1,32.84,20.42ZM40,72H24a8,8,0,0,1,0-16H40a8,8,0,0,1,0,16Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M214.45,215.87l-19.58,5.25L183.5,178.68a38.06,38.06,0,0,0,21.2-44.52c-12.52-46.73-38.55-87.68-39.65-89.4a6,6,0,0,0-6.51-2.58l-24.36,6.09c.11-9.67-.15-15.84-.19-16.55a6,6,0,0,0-4.53-5.54l-32-8A6,6,0,0,0,91,20.76c-1.1,1.72-27.13,42.67-39.65,89.4a38.06,38.06,0,0,0,21.2,44.52L61.13,197.12l-19.58-5.25a6,6,0,1,0-3.1,11.59l48,12.87a6.4,6.4,0,0,0,1.55.2,6,6,0,0,0,1.55-11.8l-16.83-4.51L84.1,157.77A36.43,36.43,0,0,0,88,158a38.08,38.08,0,0,0,36.71-28.17l0-.13c.28-1.15.55-2.29.81-3.44a262.13,262.13,0,0,0,5.73,27.57A38.06,38.06,0,0,0,168,182a36.58,36.58,0,0,0,3.9-.23l11.38,42.45-16.83,4.51a6,6,0,0,0,1.55,11.8,6.4,6.4,0,0,0,1.55-.2l48-12.87a6,6,0,1,0-3.1-11.59Zm-57.27-161C160.06,59.7,165.39,69,171.32,81L134.07,90.3c-.55-11.07-.51-20.31-.37-26.33.05-1.1.1-2.19.14-3.25Zm-58.36-24,23.31,5.83c.07,4.25.11,11.32-.19,20.2-.07,1.18-.16,3.09-.23,5.62-.09,1.82-.19,3.7-.3,5.63l-37.6-9.4C90.08,45.89,95.8,35.93,98.82,30.89Zm14.29,95.89a26,26,0,0,1-50.22-13.52A295.44,295.44,0,0,1,78.64,69.84L120.5,80.31A317.77,317.77,0,0,1,113.11,126.78Zm29.77,23.94a279.09,279.09,0,0,1-8-48.25l41.68-10.42a297.86,297.86,0,0,1,16.56,45.21,26,26,0,1,1-50.23,13.46Zm43.75-108a6,6,0,0,1,2.69-8l16-8a6,6,0,1,1,5.36,10.74l-16,8a6,6,0,0,1-8.05-2.69ZM230,72a6,6,0,0,1-6,6H208a6,6,0,0,1,0-12h16A6,6,0,0,1,230,72ZM34.63,21.32a6,6,0,0,1,8-2.69l16,8a6,6,0,0,1-5.36,10.74l-16-8A6,6,0,0,1,34.63,21.32ZM40,70H24a6,6,0,0,1,0-12H40a6,6,0,0,1,0,12Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M213.93,213.94l-17.65,4.73-10.42-38.89a40.06,40.06,0,0,0,20.77-46.14c-12.6-47-38.78-88.22-39.89-89.95a8,8,0,0,0-8.68-3.45L136.2,45.71c0-8.25-.18-13.43-.21-14.08a8,8,0,0,0-6.05-7.39l-32-8a8,8,0,0,0-8.68,3.45c-1.11,1.73-27.29,42.93-39.89,90a40.06,40.06,0,0,0,20.77,46.14L59.72,194.67l-17.65-4.73a8,8,0,0,0-4.14,15.46l48,12.86a8.23,8.23,0,0,0,2.07.27,8,8,0,0,0,2.07-15.73l-14.9-4,10.42-38.89c.81.05,1.61.08,2.41.08a40.12,40.12,0,0,0,37-24.88c1.18,6.37,2.6,12.82,4.31,19.22A40.08,40.08,0,0,0,168,184c.8,0,1.6,0,2.41-.08l10.42,38.89-14.9,4A8,8,0,0,0,168,242.53a8.23,8.23,0,0,0,2.07-.27l48-12.86a8,8,0,0,0-4.14-15.46ZM156.22,57.19c2.78,4.7,7.23,12.54,12.2,22.46L136,87.77c-.42-10-.38-18.25-.25-23.79,0-.56.05-1.12.08-1.68Zm-56.44-24,20.37,5.09c.06,4.28,0,10.67-.21,18.47-.06,1.21-.16,3.19-.23,5.84,0,1-.1,2-.16,3L86.69,57.43C92,46.67,96.84,38.16,99.78,33.19Zm11.39,93.09a24,24,0,0,1-46.34-12.5,291.26,291.26,0,0,1,15-41.59l38.58,9.64A314,314,0,0,1,111.17,126.28Zm33.64,23.92A274,274,0,0,1,137,104l38.41-9.6a293.06,293.06,0,0,1,15.75,43.39,24,24,0,1,1-46.36,12.42Zm40-106.62a8,8,0,0,1,3.58-10.74l16-8a8,8,0,1,1,7.16,14.32l-16,8a8,8,0,0,1-10.74-3.58ZM232,72a8,8,0,0,1-8,8H208a8,8,0,0,1,0-16h16A8,8,0,0,1,232,72ZM32.84,20.42a8,8,0,0,1,10.74-3.58l16,8a8,8,0,0,1-7.16,14.32l-16-8A8,8,0,0,1,32.84,20.42ZM40,72H24a8,8,0,0,1,0-16H40a8,8,0,0,1,0,16Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M215,217.8l-21.5,5.77-12.35-46.06a36.06,36.06,0,0,0,21.66-42.84c-12.45-46.43-38.31-87.12-39.4-88.83A4,4,0,0,0,159,44.12l-26.94,6.73c.23-11.53-.09-18.84-.09-19a4,4,0,0,0-3-3.7l-32-8a4,4,0,0,0-4.34,1.72c-1.09,1.71-26.95,42.4-39.4,88.83a36.06,36.06,0,0,0,21.66,42.84L62.54,199.57,41,193.8A4,4,0,0,0,39,201.53l48,12.86a3.77,3.77,0,0,0,1,.14,4,4,0,0,0,1-7.86l-18.77-5,12.35-46.07a35.8,35.8,0,0,0,40.18-26.34c1.2-4.92,2.25-9.87,3.17-14.81a275.26,275.26,0,0,0,7.25,38.89A36.06,36.06,0,0,0,168,180a35,35,0,0,0,5.38-.43l12.35,46.07-18.77,5a4,4,0,0,0,1,7.86,3.77,3.77,0,0,0,1-.14l48-12.86A4,4,0,0,0,215,217.8ZM158.13,52.59c2.79,4.6,9.11,15.4,16.07,29.74l-42,10.5c-.81-14.58-.61-26.31-.4-32.28,0-.47,0-.93,0-1.39Zm-60.26-24,26.23,6.56c.09,4,.17,11.77-.17,21.87-.06,1.18-.15,3.09-.22,5.63s-.27,5.26-.45,8L80.94,60.11C88.24,44.88,95,33.38,97.87,28.59ZM80.76,147A28,28,0,0,1,61,112.75,299.88,299.88,0,0,1,77.5,67.5l45.14,11.28a320.22,320.22,0,0,1-7.59,48.46A28,28,0,0,1,80.76,147Zm60.19,4.2a282.84,282.84,0,0,1-8.2-50.3L177.68,89.7A302.43,302.43,0,0,1,195,136.75,28,28,0,0,1,141,151.24ZM188.42,41.79a4,4,0,0,1,1.79-5.37l16-8a4,4,0,1,1,3.58,7.16l-16,8a4,4,0,0,1-5.37-1.79ZM228,72a4,4,0,0,1-4,4H208a4,4,0,0,1,0-8h16A4,4,0,0,1,228,72ZM36.42,22.21a4,4,0,0,1,5.37-1.79l16,8a4,4,0,0,1-3.58,7.16l-16-8A4,4,0,0,1,36.42,22.21ZM40,68H24a4,4,0,0,1,0-8H40a4,4,0,0,1,0,8Z" }))
  ]
]);
export {
  e as default
};
