import a from "react";
const c = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M152,128c0-22.09,7.16-40,16-40s16,17.91,16,40-7.16,40-16,40S152,150.09,152,128Zm92,96a12,12,0,0,1-12,12H92c-18.5,0-35.3-12.22-47.32-34.4C33.92,181.74,28,155.6,28,128s5.92-53.74,16.68-73.6C56.7,32.22,73.5,20,92,20h72c18.5,0,35.3,12.22,47.32,34.4C222.08,74.26,228,100.4,228,128s-5.92,53.74-16.68,73.6a95.07,95.07,0,0,1-6.51,10.4H232A12,12,0,0,1,244,224ZM164,44c-18.92,0-40,34.5-40,84s21.08,84,40,84,40-34.5,40-84S182.92,44,164,44ZM54.33,155.59,73,142.24a12,12,0,0,1,13.94,0l14.66,10.46A186.77,186.77,0,0,1,100,128c0-3.61.11-7.2.31-10.75L80,102.75,52.09,122.68C52,124.44,52,126.21,52,128A160.1,160.1,0,0,0,54.33,155.59ZM56.54,90,73,78.24a12,12,0,0,1,13.94,0l16.91,12.07a134.79,134.79,0,0,1,12.8-35.91A95.07,95.07,0,0,1,123.19,44H92C78.44,44,63.77,61.73,56.54,90ZM92,212h31.19a95.07,95.07,0,0,1-6.51-10.4,115.79,115.79,0,0,1-6-12.91L80,166.75,61.14,180.22C69,200.08,80.88,212,92,212Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M164,224H92c-28.72,0-52-43-52-96S63.28,32,92,32h72c-28.72,0-52,43-52,96S135.28,224,164,224Zm4-136c-8.84,0-16,17.91-16,40s7.16,40,16,40,16-17.91,16-40S176.84,88,168,88Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M149.26,159.26C155.09,173.82,163.5,176,168,176s12.91-2.18,18.74-16.74c3.39-8.48,5.26-19.58,5.26-31.26s-1.87-22.78-5.26-31.26C180.91,82.18,172.5,80,168,80s-12.91,2.18-18.74,16.74C145.87,105.22,144,116.32,144,128S145.87,150.78,149.26,159.26ZM168,96.2c2.62,2.06,8,13,8,31.8s-5.38,29.74-8,31.8c-2.62-2.06-8-13-8-31.8S165.38,98.26,168,96.2ZM232,216H196.41C213.12,197.73,224,165.47,224,128c0-58.32-26.35-104-60-104H92C58.35,24,32,69.68,32,128S58.35,232,92,232H232a8,8,0,0,0,0-16ZM193.74,63.93C202.93,80.91,208,103.67,208,128s-5.07,47.09-14.26,64.07C185.38,207.5,174.82,216,164,216s-21.38-8.5-29.74-23.93C125.07,175.09,120,152.33,120,128s5.07-47.09,14.26-64.07C142.62,48.5,153.18,40,164,40S185.38,48.5,193.74,63.93ZM48,128c0-2.5.07-5,.17-7.44L80,97.83l24.43,17.45c-.28,4.16-.43,8.41-.43,12.72a179.89,179.89,0,0,0,3.07,33.5l-22.42-16a8,8,0,0,0-9.3,0l-23.74,17A161,161,0,0,1,48,128ZM62.26,63.93C70.62,48.5,81.18,40,92,40h39.59c-11.9,13-20.84,33.12-25,57.16L84.65,81.49a8,8,0,0,0-9.3,0L50.49,99.25C52.85,86,56.83,74,62.26,63.93Zm0,128.14a100.08,100.08,0,0,1-5.94-13.32L80,161.83l33.94,24.24c4.6,12,10.6,22.22,17.65,29.93H92C81.18,216,70.62,207.5,62.26,192.07Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M184,128c0,22.09-7.16,40-16,40s-16-17.91-16-40,7.16-40,16-40S184,105.91,184,128Zm56,96a8,8,0,0,1-8,8H92c-33.64,0-60-45.68-60-104S58.36,24,92,24h72c33.64,0,60,45.68,60,104,0,37.47-10.88,69.73-27.59,88H232A8,8,0,0,1,240,224ZM57.87,111.81a7.93,7.93,0,0,0,4.64-1.49L80,97.83l13.28,9.49a8,8,0,0,0,9.3-13L84.65,81.49a8,8,0,0,0-9.3,0L53.21,97.3a8,8,0,0,0,4.66,14.51Zm46.67,47.89L84.65,145.49a8,8,0,0,0-9.3,0L56,159.29a8,8,0,1,0,9.3,13L80,161.83l15.24,10.88a8,8,0,1,0,9.3-13Zm89.2,32.37c9.19-17,14.26-39.74,14.26-64.07s-5.07-47.09-14.26-64.07C185.38,48.5,174.82,40,164,40s-21.38,8.5-29.74,23.93C125.07,80.91,120,103.67,120,128s5.07,47.09,14.26,64.07C142.62,207.5,153.18,216,164,216S185.38,207.5,193.74,192.07Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M168,174c14.44,0,22-23.14,22-46s-7.56-46-22-46-22,23.14-22,46S153.56,174,168,174Zm0-80c2.57,0,10,11.69,10,34s-7.43,34-10,34-10-11.69-10-34S165.43,94,168,94Zm64,124H191.71C209.89,201,222,167.43,222,128c0-57.2-25.48-102-58-102H92C59.48,26,34,70.8,34,128S59.48,230,92,230H232a6,6,0,0,0,0-12Zm-22-90c0,48.79-21.07,90-46,90s-46-41.21-46-90,21.07-90,46-90S210,79.21,210,128ZM46,128c0-2.86.08-5.69.22-8.5L80,95.37l26.5,18.93c-.33,4.47-.5,9-.5,13.7a175.72,175.72,0,0,0,4,38.08l-26.55-19a6,6,0,0,0-7,0l-26.1,18.64A160.24,160.24,0,0,1,46,128ZM92,38h44.29C122.49,50.92,112.2,73.35,108,100.65L83.49,83.12a6,6,0,0,0-7,0L47.79,103.63C53.37,66.24,71.36,38,92,38Zm0,180C76.42,218,62.36,201.92,54,178l26-18.58,35.53,25.39c5.22,13.92,12.34,25.35,20.76,33.24Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M149.26,159.26C155.09,173.82,163.5,176,168,176s12.91-2.18,18.74-16.74c3.39-8.48,5.26-19.58,5.26-31.26s-1.87-22.78-5.26-31.26C180.91,82.18,172.5,80,168,80s-12.91,2.18-18.74,16.74C145.87,105.22,144,116.32,144,128S145.87,150.78,149.26,159.26ZM168,96.2c2.62,2.06,8,13,8,31.8s-5.38,29.74-8,31.8c-2.62-2.06-8-13-8-31.8S165.38,98.26,168,96.2ZM232,216H196.41C213.12,197.73,224,165.47,224,128c0-58.32-26.35-104-60-104H92C58.35,24,32,69.68,32,128S58.35,232,92,232H232a8,8,0,0,0,0-16ZM193.74,63.93C202.93,80.91,208,103.67,208,128s-5.07,47.09-14.26,64.07C185.38,207.5,174.82,216,164,216s-21.38-8.5-29.74-23.93C125.07,175.09,120,152.33,120,128s5.07-47.09,14.26-64.07C142.62,48.5,153.18,40,164,40S185.38,48.5,193.74,63.93ZM48,128c0-2.5.07-5,.17-7.44L80,97.83l24.43,17.45c-.28,4.16-.43,8.41-.43,12.72a179.89,179.89,0,0,0,3.07,33.5l-22.42-16a8,8,0,0,0-9.3,0l-23.74,17A161,161,0,0,1,48,128ZM62.26,63.93C70.62,48.5,81.18,40,92,40h39.59c-11.9,13-20.84,33.12-25,57.16L84.65,81.49a8,8,0,0,0-9.3,0L50.49,99.25C52.85,86,56.83,74,62.26,63.93Zm0,128.14a100.08,100.08,0,0,1-5.94-13.32L80,161.83l33.94,24.24c4.6,12,10.6,22.22,17.65,29.93H92C81.18,216,70.62,207.5,62.26,192.07Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M168,172c13,0,20-22.67,20-44s-7-44-20-44-20,22.67-20,44S155,172,168,172Zm0-80c4.89,0,12,14,12,36s-7.11,36-12,36-12-14-12-36S163.11,92,168,92Zm64,128H186.23c6.72-5.11,12.85-12.59,18.06-22.21C214.42,179.08,220,154.3,220,128s-5.58-51.08-15.71-69.79C193.73,38.73,179.43,28,164,28H92C76.57,28,62.27,38.73,51.71,58.21,41.58,76.92,36,101.7,36,128s5.58,51.08,15.71,69.79C62.27,217.27,76.57,228,92,228H232a4,4,0,0,0,0-8ZM197.25,62C206.76,79.58,212,103,212,128s-5.24,48.42-14.75,66c-9.09,16.78-20.9,26-33.25,26s-24.16-9.24-33.25-26C121.24,176.42,116,153,116,128s5.24-48.42,14.75-66c9.09-16.78,20.9-26,33.25-26S188.16,45.24,197.25,62ZM58.75,62C67.84,45.24,79.65,36,92,36h49.77c-6.72,5.11-12.85,12.59-18.06,22.21-7,12.93-11.83,28.77-14.14,46L82.33,84.75a4,4,0,0,0-4.66,0L45.17,108C47.19,90.66,51.84,74.78,58.75,62ZM44.26,118.44,80,92.92l28.59,20.42c-.39,4.81-.59,9.71-.59,14.66a169.72,169.72,0,0,0,5.32,42.89l-31-22.14a4,4,0,0,0-4.66,0L49.13,169.13A161.44,161.44,0,0,1,44,128Q44,123.19,44.26,118.44ZM92,220c-12.35,0-24.16-9.24-33.25-26a106.61,106.61,0,0,1-7.19-16.75L80,156.92l37.32,26.65a111.32,111.32,0,0,0,6.39,14.22c5.21,9.62,11.34,17.1,18.06,22.21Z" }))
  ]
]);
export {
  c as default
};
