import c from "react";
const s = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M88,149.39a12,12,0,0,0,12-12V136h56v3.29a12,12,0,0,0,24,0V32a12,12,0,0,0-24,0v8H100V32a12,12,0,0,0-24,0V137.39A12,12,0,0,0,88,149.39ZM100,112V100h56v12Zm56-48V76H100V64ZM20,164a12,12,0,0,1,12-12c15.63,0,24,5.55,30.66,10,5.4,3.6,9,6,17.34,6s11.93-2.38,17.34-6c6.69-4.47,15-10,30.65-10s24,5.55,30.66,10c5.41,3.6,9,6,17.35,6s11.94-2.38,17.34-6c6.7-4.47,15-10,30.66-10a12,12,0,0,1,0,24c-8.37,0-11.94,2.38-17.35,6-6.69,4.47-15,10-30.65,10s-24-5.55-30.66-10c-5.41-3.6-9-6-17.35-6s-11.93,2.38-17.34,6c-6.69,4.47-15,10-30.65,10s-24-5.55-30.66-10c-5.4-3.6-9-6-17.34-6A12,12,0,0,1,20,164Zm216,44a12,12,0,0,1-12,12c-8.37,0-11.94,2.38-17.35,6-6.69,4.47-15,10-30.65,10s-24-5.55-30.66-10c-5.41-3.6-9-6-17.35-6s-11.93,2.38-17.34,6c-6.69,4.47-15,10-30.65,10s-24-5.55-30.66-10c-5.4-3.6-9-6-17.34-6a12,12,0,0,1,0-24c15.63,0,24,5.55,30.66,10,5.4,3.6,9,6,17.34,6s11.93-2.38,17.34-6c6.69-4.47,15-10,30.65-10s24,5.55,30.66,10c5.41,3.6,9,6,17.35,6s11.94-2.38,17.34-6c6.7-4.47,15-10,30.66-10A12,12,0,0,1,236,208Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement(
      "path",
      {
        d: "M224,168v40c-24,0-24,16-48,16s-24-16-48-16-24,16-48,16-24-16-48-16V168c24,0,24,16,48,16s24-16,48-16,24,16,48,16S200,168,224,168Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ c.createElement("path", { d: "M88,149.39a8,8,0,0,0,8-8V128h64v15.29a8,8,0,0,0,16,0V32a8,8,0,0,0-16,0V48H96V32a8,8,0,0,0-16,0V141.39A8,8,0,0,0,88,149.39ZM96,112V96h64v16Zm64-48V80H96V64ZM24,168a8,8,0,0,1,8-8c14.42,0,22.19,5.18,28.44,9.34C66,173.06,70.42,176,80,176s14-2.94,19.56-6.66c6.24-4.16,14-9.34,28.43-9.34s22.2,5.18,28.44,9.34c5.58,3.72,10,6.66,19.57,6.66s14-2.94,19.56-6.66c6.25-4.16,14-9.34,28.44-9.34a8,8,0,0,1,0,16c-9.58,0-14,2.94-19.56,6.66-6.25,4.16-14,9.34-28.44,9.34s-22.2-5.18-28.44-9.34C142,178.94,137.57,176,128,176s-14,2.94-19.56,6.66c-6.24,4.16-14,9.34-28.43,9.34s-22.19-5.18-28.44-9.34C46,178.94,41.58,176,32,176A8,8,0,0,1,24,168Zm208,40a8,8,0,0,1-8,8c-9.58,0-14,2.94-19.56,6.66-6.25,4.16-14,9.34-28.44,9.34s-22.2-5.18-28.44-9.34C142,218.94,137.57,216,128,216s-14,2.94-19.56,6.66c-6.24,4.16-14,9.34-28.43,9.34s-22.19-5.18-28.44-9.34C46,218.94,41.58,216,32,216a8,8,0,0,1,0-16c14.42,0,22.19,5.18,28.44,9.34C66,213.06,70.42,216,80,216s14-2.94,19.56-6.66c6.24-4.16,14-9.34,28.43-9.34s22.2,5.18,28.44,9.34c5.58,3.72,10,6.66,19.57,6.66s14-2.94,19.56-6.66c6.25-4.16,14-9.34,28.44-9.34A8,8,0,0,1,232,208Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M24,168a8,8,0,0,1,8-8c14.42,0,22.19,5.18,28.44,9.34C66,173.06,70.42,176,80,176s14-2.94,19.56-6.66c6.24-4.16,14-9.34,28.43-9.34s22.2,5.18,28.44,9.34c5.58,3.72,10,6.66,19.57,6.66s14-2.94,19.56-6.66c6.25-4.16,14-9.34,28.44-9.34a8,8,0,0,1,0,16c-9.58,0-14,2.94-19.56,6.66-6.25,4.16-14,9.34-28.44,9.34s-22.2-5.18-28.44-9.34C142,178.94,137.57,176,128,176s-14,2.94-19.56,6.66c-6.24,4.16-14,9.34-28.43,9.34s-22.19-5.18-28.44-9.34C46,178.94,41.58,176,32,176A8,8,0,0,1,24,168Zm200,32c-14.42,0-22.19,5.18-28.44,9.34C190,213.06,185.58,216,176,216s-14-2.94-19.57-6.66c-6.24-4.16-14-9.34-28.44-9.34s-22.19,5.18-28.43,9.34C94,213.06,89.57,216,80,216s-14-2.94-19.56-6.66C54.19,205.18,46.42,200,32,200a8,8,0,0,0,0,16c9.58,0,14,2.94,19.56,6.66,6.25,4.16,14,9.34,28.44,9.34s22.19-5.18,28.43-9.34c5.58-3.72,10-6.66,19.56-6.66s14,2.94,19.57,6.66c6.24,4.16,14,9.34,28.44,9.34s22.19-5.18,28.44-9.34c5.57-3.72,10-6.66,19.56-6.66a8,8,0,0,0,0-16ZM80,141.39V32a8,8,0,0,1,16,0v8h64V32a8,8,0,0,1,16,0V143.29a8,8,0,0,1-16,0V136H96v5.39a8,8,0,0,1-16,0ZM96,72a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,72Zm0,32a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,104Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M88,147.39a6,6,0,0,0,6-6V126h68v17.29a6,6,0,0,0,12,0V32a6,6,0,0,0-12,0V50H94V32a6,6,0,0,0-12,0V141.39A6,6,0,0,0,88,147.39ZM94,114V94h68v20Zm68-52V82H94V62ZM26,168a6,6,0,0,1,6-6c13.82,0,21,4.79,27.33,9,5.63,3.75,10.48,7,20.67,7s15-3.24,20.67-7c6.33-4.22,13.51-9,27.32-9s21,4.79,27.33,9c5.63,3.75,10.49,7,20.68,7s15-3.24,20.67-7c6.33-4.22,13.51-9,27.33-9a6,6,0,0,1,0,12c-10.18,0-15,3.24-20.67,7-6.34,4.22-13.52,9-27.33,9s-21-4.79-27.33-9c-5.63-3.75-10.49-7-20.68-7s-15,3.24-20.67,7c-6.33,4.22-13.51,9-27.32,9s-21-4.79-27.33-9C47,177.24,42.18,174,32,174A6,6,0,0,1,26,168Zm204,40a6,6,0,0,1-6,6c-10.18,0-15,3.24-20.67,7-6.34,4.22-13.52,9-27.33,9s-21-4.79-27.33-9c-5.63-3.75-10.49-7-20.68-7s-15,3.24-20.67,7c-6.33,4.22-13.51,9-27.32,9s-21-4.79-27.33-9C47,217.24,42.18,214,32,214a6,6,0,0,1,0-12c13.82,0,21,4.79,27.33,9,5.63,3.75,10.48,7,20.67,7s15-3.24,20.67-7c6.33-4.22,13.51-9,27.32-9s21,4.79,27.33,9c5.63,3.75,10.49,7,20.68,7s15-3.24,20.67-7c6.33-4.22,13.51-9,27.33-9A6,6,0,0,1,230,208Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M88,149.39a8,8,0,0,0,8-8V128h64v15.29a8,8,0,0,0,16,0V32a8,8,0,0,0-16,0V48H96V32a8,8,0,0,0-16,0V141.39A8,8,0,0,0,88,149.39ZM96,112V96h64v16Zm64-48V80H96V64ZM24,168a8,8,0,0,1,8-8c14.42,0,22.19,5.18,28.44,9.34C66,173.06,70.42,176,80,176s14-2.94,19.56-6.66c6.24-4.16,14-9.34,28.43-9.34s22.2,5.18,28.44,9.34c5.58,3.72,10,6.66,19.57,6.66s14-2.94,19.56-6.66c6.25-4.16,14-9.34,28.44-9.34a8,8,0,0,1,0,16c-9.58,0-14,2.94-19.56,6.66-6.25,4.16-14,9.34-28.44,9.34s-22.2-5.18-28.44-9.34C142,178.94,137.57,176,128,176s-14,2.94-19.56,6.66c-6.24,4.16-14,9.34-28.43,9.34s-22.19-5.18-28.44-9.34C46,178.94,41.58,176,32,176A8,8,0,0,1,24,168Zm208,40a8,8,0,0,1-8,8c-9.58,0-14,2.94-19.56,6.66-6.25,4.16-14,9.34-28.44,9.34s-22.2-5.18-28.44-9.34C142,218.94,137.57,216,128,216s-14,2.94-19.56,6.66c-6.24,4.16-14,9.34-28.43,9.34s-22.19-5.18-28.44-9.34C46,218.94,41.58,216,32,216a8,8,0,0,1,0-16c14.42,0,22.19,5.18,28.44,9.34C66,213.06,70.42,216,80,216s14-2.94,19.56-6.66c6.24-4.16,14-9.34,28.43-9.34s22.2,5.18,28.44,9.34c5.58,3.72,10,6.66,19.57,6.66s14-2.94,19.56-6.66c6.25-4.16,14-9.34,28.44-9.34A8,8,0,0,1,232,208Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ c.createElement(c.Fragment, null, /* @__PURE__ */ c.createElement("path", { d: "M88,145.39a4,4,0,0,0,4-4V124h72v19.29a4,4,0,0,0,8,0V32a4,4,0,0,0-8,0V52H92V32a4,4,0,0,0-8,0V141.39A4,4,0,0,0,88,145.39ZM92,116V92h72v24Zm72-56V84H92V60ZM28,168a4,4,0,0,1,4-4c13.21,0,20.12,4.61,26.22,8.67,5.9,3.93,11,7.33,21.78,7.33s15.88-3.4,21.78-7.33c6.09-4.06,13-8.67,26.21-8.67s20.13,4.61,26.22,8.67c5.9,3.93,11,7.33,21.79,7.33s15.88-3.4,21.78-7.33c6.1-4.06,13-8.67,26.22-8.67a4,4,0,0,1,0,8c-10.79,0-15.88,3.4-21.78,7.33-6.1,4.06-13,8.67-26.22,8.67s-20.13-4.61-26.22-8.67c-5.9-3.93-11-7.33-21.79-7.33s-15.88,3.4-21.78,7.33c-6.09,4.06-13,8.67-26.21,8.67s-20.12-4.61-26.22-8.67C47.88,175.4,42.79,172,32,172A4,4,0,0,1,28,168Zm200,40a4,4,0,0,1-4,4c-10.79,0-15.88,3.4-21.78,7.33-6.1,4.06-13,8.67-26.22,8.67s-20.13-4.61-26.22-8.67c-5.9-3.93-11-7.33-21.79-7.33s-15.88,3.4-21.78,7.33c-6.09,4.06-13,8.67-26.21,8.67s-20.12-4.61-26.22-8.67C47.88,215.4,42.79,212,32,212a4,4,0,0,1,0-8c13.21,0,20.12,4.61,26.22,8.67,5.9,3.93,11,7.33,21.78,7.33s15.88-3.4,21.78-7.33c6.09-4.06,13-8.67,26.21-8.67s20.13,4.61,26.22,8.67c5.9,3.93,11,7.33,21.79,7.33s15.88-3.4,21.78-7.33c6.1-4.06,13-8.67,26.22-8.67A4,4,0,0,1,228,208Z" }))
  ]
]);
export {
  s as default
};
