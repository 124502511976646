import l from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M245.36,77.28h0L203.74,56.45C201.38,40.24,183,28,160,28c-24.67,0-44,14.06-44,32a25,25,0,0,0,2.69,11.24l-9.4,4.7C101.56,71,91.35,68,80,68c-24.67,0-44,14.06-44,32a25,25,0,0,0,2.69,11.24l-28.06,14A12,12,0,0,0,4,136H4v56a12,12,0,0,0,6.63,10.73l64,32a12,12,0,0,0,10.74,0l160-80A12,12,0,0,0,252,144V88A12,12,0,0,0,245.36,77.28ZM80,154.58,42.83,136l15.81-7.9A58.2,58.2,0,0,0,80,132c24.67,0,44-14.06,44-32a25.39,25.39,0,0,0-.4-4.38l15-7.52A58.2,58.2,0,0,0,160,92c14.72,0,27.53-5,35.47-12.85L213.17,88ZM160,52c12.91,0,20,6,20,8s-7.09,8-20,8-20-6-20-8S147.09,52,160,52ZM80,92c12.91,0,20,6,20,8s-7.09,8-20,8-20-6-20-8S67.09,92,80,92ZM28,155.42l40,20v29.16l-40-20Zm64,49.16V175.42l136-68v29.16Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M240,80v64L80,224V160Z", opacity: "0.2" }), /* @__PURE__ */ l.createElement("path", { d: "M243.58,72.84,197.29,49.7C191.54,39.24,177.21,32,160,32c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53l-19.52,9.76A53.69,53.69,0,0,0,80,72c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53L12.42,120.84A8,8,0,0,0,8,128v64a8,8,0,0,0,4.42,7.16l64,32a8,8,0,0,0,7.16,0l160-80A8,8,0,0,0,248,144V80A8,8,0,0,0,243.58,72.84ZM80,151.06,33.89,128,51,119.45c7.24,5.29,17.48,8.55,29,8.55,22.43,0,40-12.3,40-28a21.77,21.77,0,0,0-4.35-12.88L131,79.45c7.24,5.29,17.48,8.55,29,8.55,18.38,0,33.49-8.26,38.35-19.88L222.11,80ZM160,48c13.74,0,24,6.34,24,12s-10.26,12-24,12-24-6.34-24-12S146.26,48,160,48ZM80,88c13.74,0,24,6.34,24,12s-10.26,12-24,12c-9.67,0-17.61-3.14-21.47-7a8.29,8.29,0,0,0-.84-.93A6.62,6.62,0,0,1,56,100C56,94.34,66.26,88,80,88ZM24,140.94l48,24v46.12l-48-24Zm64,70.12V164.94l144-72v46.12Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M247.94,79.21a8,8,0,0,0-4.36-6.37L197.29,49.7C191.54,39.24,177.21,32,160,32c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53l-19.52,9.76A53.69,53.69,0,0,0,80,72c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53L12.42,120.84A8,8,0,0,0,8,128v64a8,8,0,0,0,4.42,7.16l64,32a8,8,0,0,0,7.16,0l160-80A8,8,0,0,0,248,144V80A4.54,4.54,0,0,0,247.94,79.21ZM80,151.06,33.89,128,51,119.45c7.24,5.29,17.48,8.55,29,8.55,22.43,0,40-12.3,40-28a21.77,21.77,0,0,0-4.35-12.88L131,79.45c7.24,5.29,17.48,8.55,29,8.55,18.38,0,33.49-8.26,38.35-19.88L222.11,80ZM160,48c12.23,0,21.69,5,23.63,10.12,0,.09.07.18.11.28A5.25,5.25,0,0,1,184,60c0,5.66-10.26,12-24,12-9.66,0-17.6-3.14-21.46-7a6.92,6.92,0,0,0-.86-.93A6.66,6.66,0,0,1,136,60C136,54.34,146.26,48,160,48ZM80,88a37,37,0,0,1,17.13,3.87,7.52,7.52,0,0,0,1,.56c3.69,2.21,5.87,5,5.87,7.57,0,5.66-10.26,12-24,12-9.67,0-17.61-3.14-21.47-7a7.5,7.5,0,0,0-.84-.93A6.62,6.62,0,0,1,56,100C56,94.34,66.26,88,80,88ZM24,140.94l48,24v46.12l-48-24Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M242.68,74.63,195.85,51.22C190.69,41.09,176.78,34,160,34c-21.31,0-38,11.42-38,26a19.06,19.06,0,0,0,1.56,7.51l-22,11A51.47,51.47,0,0,0,80,74c-21.31,0-38,11.42-38,26a19.06,19.06,0,0,0,1.56,7.51L13.32,122.63A6,6,0,0,0,10,128v64a6,6,0,0,0,3.32,5.37l64,32a6,6,0,0,0,5.36,0l160-80A6,6,0,0,0,246,144V80A6,6,0,0,0,242.68,74.63ZM80,153.29,29.42,128l21.77-10.88C58.1,122.59,68.36,126,80,126c21.31,0,38-11.42,38-26a20.4,20.4,0,0,0-5.43-13.58l18.62-9.3C138.1,82.59,148.36,86,160,86c18.63,0,33.74-8.73,37.23-20.67L226.58,80ZM160,46c15.32,0,26,7.38,26,14s-10.68,14-26,14-26-7.38-26-14S144.68,46,160,46ZM80,86c15.32,0,26,7.38,26,14s-10.68,14-26,14-26-7.38-26-14S64.68,86,80,86ZM22,137.71l52,26v50.58l-52-26Zm64,76.58V163.71l148-74v50.58Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M243.58,72.84,197.29,49.7C191.54,39.24,177.21,32,160,32c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53l-19.52,9.76A53.69,53.69,0,0,0,80,72c-22.43,0-40,12.3-40,28a20.77,20.77,0,0,0,1.06,6.53L12.42,120.84A8,8,0,0,0,8,128v64a8,8,0,0,0,4.42,7.16l64,32a8,8,0,0,0,7.16,0l160-80A8,8,0,0,0,248,144V80A8,8,0,0,0,243.58,72.84ZM80,151.06,33.89,128,51,119.45c7.24,5.29,17.48,8.55,29,8.55,22.43,0,40-12.3,40-28a21.77,21.77,0,0,0-4.35-12.88L131,79.45c7.24,5.29,17.48,8.55,29,8.55,18.38,0,33.49-8.26,38.35-19.88L222.11,80ZM160,48c13.74,0,24,6.34,24,12s-10.26,12-24,12-24-6.34-24-12S146.26,48,160,48ZM80,88c13.74,0,24,6.34,24,12s-10.26,12-24,12c-9.67,0-17.61-3.14-21.47-7a8.29,8.29,0,0,0-.84-.93A6.62,6.62,0,0,1,56,100C56,94.34,66.26,88,80,88ZM24,140.94l48,24v46.12l-48-24Zm64,70.12V164.94l144-72v46.12Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M241.79,76.42,194.37,52.71C189.84,42.92,176.36,36,160,36c-20.19,0-36,10.54-36,24a17.55,17.55,0,0,0,2.2,8.43L101.65,80.7A49.17,49.17,0,0,0,80,76c-20.19,0-36,10.54-36,24a17.55,17.55,0,0,0,2.2,8.43l-32,16A4,4,0,0,0,12,128v64a4,4,0,0,0,2.21,3.58l64,32a4,4,0,0,0,3.58,0l160-80A4,4,0,0,0,244,144V80A4,4,0,0,0,241.79,76.42ZM160,44c15.18,0,28,7.33,28,16s-12.82,16-28,16-28-7.33-28-16S144.82,44,160,44ZM131.43,74.76C138,80.41,148.23,84,160,84c19,0,34.09-9.32,35.83-21.61L231.06,80,80,155.53,24.94,128l26.49-13.24C58,120.41,68.23,124,80,124c20.19,0,36-10.54,36-24,0-5.34-2.49-10.21-6.75-14.15ZM80,84c15.18,0,28,7.33,28,16s-12.82,16-28,16c-10.49,0-19.85-3.5-24.63-8.52-.09-.12-.19-.21-.28-.31A10.7,10.7,0,0,1,52,100C52,91.33,64.82,84,80,84ZM20,134.47l56,28v55.06l-56-28Zm64,83.06V162.47l152-76v55.06Z" }))
  ]
]);
export {
  e as default
};
