import e from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M92,24C68.45,24,52,43.74,52,72s16.45,48,40,48,40-19.74,40-48S115.55,24,92,24Zm0,72C76.45,96,76,74.46,76,72s.45-24,16-24,16,21.54,16,24S107.55,96,92,96Zm53.27-42.63a12,12,0,0,1,5.36-16.1l24-12A12,12,0,0,1,192,36v72a12,12,0,0,1-24,0V55.42l-6.63,3.31A12,12,0,0,1,145.27,53.37ZM164,136c-23.55,0-40,19.74-40,48s16.45,48,40,48,40-19.74,40-48S187.55,136,164,136Zm0,72c-15.55,0-16-21.54-16-24s.45-24,16-24,16,21.54,16,24S179.55,208,164,208Zm-60-60v72a12,12,0,0,1-24,0V167.42l-6.63,3.31a12,12,0,1,1-10.74-21.46l24-12A12,12,0,0,1,104,148Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
      "path",
      {
        d: "M224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ e.createElement("path", { d: "M94,24C71.63,24,56,43.74,56,72s15.63,48,38,48,38-19.74,38-48S116.37,24,94,24Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S111.37,104,94,104Zm72,32c-22.37,0-38,19.74-38,48s15.63,48,38,48,38-19.74,38-48S188.37,136,166,136Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S183.37,216,166,216ZM145,49.22a8,8,0,0,1,3.11-10.88l24-13.33A8,8,0,0,1,184,32v80a8,8,0,0,1-16,0V45.6l-12.12,6.73A8,8,0,0,1,145,49.22ZM104,144v80a8,8,0,0,1-16,0V157.6l-12.12,6.73a8,8,0,0,1-7.76-14l24-13.33A8,8,0,0,1,104,144Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M168,168c0,4.75-1.11,9.16-3.05,12.11A7.77,7.77,0,0,1,158,184c-9.72,0-10-14.36-10-16,0-4.74,1.11-9.16,3.05-12.11A7.77,7.77,0,0,1,158,152C167.72,152,168,166.36,168,168ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM140.84,75.58a8,8,0,0,0,10.74,3.58L156,76.94V112a8,8,0,0,0,16,0V64a8,8,0,0,0-11.58-7.16l-16,8A8,8,0,0,0,140.84,75.58ZM112,144a8,8,0,0,0-11.58-7.16l-16,8a8,8,0,0,0,7.16,14.32L96,156.94V192a8,8,0,0,0,16,0Zm16-56c0-18.84-10.69-32-26-32S76,69.16,76,88s10.69,32,26,32S128,106.84,128,88Zm56,80c0-18.84-10.69-32-26-32s-26,13.16-26,32,10.69,32,26,32S184,186.84,184,168ZM102,72a7.77,7.77,0,0,0-7,3.89c-1.94,3-3,7.37-3,12.11,0,1.64.28,16,10,16a7.77,7.77,0,0,0,7-3.89c1.94-3,3-7.36,3-12.11C112,86.36,111.72,72,102,72Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M121.75,41.21C115.22,31.26,105.62,26,94,26S72.78,31.26,66.25,41.21C60.93,49.31,58,60.25,58,72s2.93,22.69,8.25,30.79C72.78,112.74,82.38,118,94,118s21.22-5.26,27.75-15.21c5.32-8.1,8.25-19,8.25-30.79S127.07,49.31,121.75,41.21ZM94,106c-19,0-24-21.37-24-34s5-34,24-34,24,21.37,24,34S113,106,94,106Zm99.75,47.21C187.22,143.26,177.62,138,166,138s-21.22,5.26-27.75,15.21c-5.32,8.1-8.25,19-8.25,30.79s2.93,22.69,8.25,30.79c6.53,10,16.13,15.21,27.75,15.21s21.22-5.26,27.75-15.21c5.32-8.1,8.25-19,8.25-30.79S199.07,161.31,193.75,153.21ZM166,218c-18.95,0-24-21.37-24-34s5.05-34,24-34,24,21.37,24,34S185,218,166,218ZM146.75,48.25a6,6,0,0,1,2.34-8.16l24-13.34A6,6,0,0,1,182,32v80a6,6,0,0,1-12,0V42.2l-15.09,8.38A6,6,0,0,1,146.75,48.25ZM102,144v80a6,6,0,0,1-12,0V154.2l-15.09,8.38a6,6,0,1,1-5.82-10.49l24-13.34A6,6,0,0,1,102,144Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M94,24C71.63,24,56,43.74,56,72s15.63,48,38,48,38-19.74,38-48S116.37,24,94,24Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S111.37,104,94,104Zm72,32c-22.37,0-38,19.74-38,48s15.63,48,38,48,38-19.74,38-48S188.37,136,166,136Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S183.37,216,166,216ZM145,49.22a8,8,0,0,1,3.11-10.88l24-13.33A8,8,0,0,1,184,32v80a8,8,0,0,1-16,0V45.6l-12.12,6.73A8,8,0,0,1,145,49.22ZM104,144v80a8,8,0,0,1-16,0V157.6l-12.12,6.73a8,8,0,0,1-7.76-14l24-13.33A8,8,0,0,1,104,144Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M94,28c-10.92,0-19.93,5-26.08,14.31C62.81,50.09,60,60.64,60,72s2.81,21.91,7.92,29.7C74.07,111.05,83.08,116,94,116s19.93-5,26.08-14.3C125.19,93.91,128,83.37,128,72s-2.81-21.91-7.92-29.69C113.93,33,104.92,28,94,28Zm0,80c-18,0-26-18.08-26-36s8-36,26-36,26,18.08,26,36S112,108,94,108Zm98.08,46.31C185.93,145,176.92,140,166,140s-19.93,4.95-26.08,14.31C134.81,162.09,132,172.64,132,184s2.81,21.91,7.92,29.7c6.15,9.35,15.16,14.3,26.08,14.3s19.93-4.95,26.08-14.3c5.11-7.79,7.92-18.33,7.92-29.7S197.19,162.09,192.08,154.31ZM166,220c-18,0-26-18.08-26-36s8-36,26-36,26,18.08,26,36S184,220,166,220ZM148.5,47.28a4,4,0,0,1,1.56-5.44l24-13.34A4,4,0,0,1,180,32v80a4,4,0,0,1-8,0V38.8l-18.06,10A4,4,0,0,1,148.5,47.28ZM100,144v80a4,4,0,0,1-8,0V150.8l-18.06,10a4,4,0,1,1-3.88-7l24-13.34A4,4,0,0,1,100,144Z" }))
  ]
]);
export {
  l as default
};
