import a from "react";
const A = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M116,104a12,12,0,0,1-12,12h-4v36a12,12,0,0,1-24,0V116H72a12,12,0,0,1,0-24h32A12,12,0,0,1,116,104ZM240,92.74V152A44,44,0,0,1,198,196,68,68,0,0,1,76,200H36a20,20,0,0,1-20-20V76A20,20,0,0,1,36,56H92a44,44,0,0,1,80.51-24.53A36,36,0,0,1,221.94,76h1.32A16.76,16.76,0,0,1,240,92.74ZM180,55.09c0,.3,0,.6,0,.91a44.14,44.14,0,0,1-4.77,20H188a12,12,0,1,0-8-20.91ZM116,56h24a19.93,19.93,0,0,1,14.86,6.66A20.25,20.25,0,0,0,156,56a20,20,0,0,0-40,0ZM40,176h96V80H40Zm140-8V100H160v80a20,20,0,0,1-20,20H105.86A44,44,0,0,0,180,168Zm36-68H204v68c0,.79,0,1.57-.06,2.35A20,20,0,0,0,216,152Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M144,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8h96A8,8,0,0,1,144,80Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M80,152V112H72a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16H96v40a8,8,0,0,1-16,0ZM232,92.74V152a40,40,0,0,1-36.63,39.85,64,64,0,0,1-118.7.15H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64H96.81a40,40,0,0,1,73.31-28.85A32,32,0,0,1,211.69,80h7.57A12.76,12.76,0,0,1,232,92.74ZM175.6,50.4A39.89,39.89,0,0,1,168,80h16a16,16,0,1,0-8.4-29.6ZM113.38,64H136a16,16,0,0,1,15.07,10.68A24,24,0,1,0,113.38,64ZM40,176h96V80H40Zm144-8V96H152v80a16,16,0,0,1-16,16H94.44A48,48,0,0,0,184,168Zm32-72H200v72a62.76,62.76,0,0,1-.36,6.75A24,24,0,0,0,216,152Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M219.26,80h-7.57A31.71,31.71,0,0,0,216,64a32,32,0,0,0-45.88-28.85A40,40,0,0,0,96.81,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H76.67a64,64,0,0,0,118.7-.15A40,40,0,0,0,232,152V92.74A12.76,12.76,0,0,0,219.26,80ZM136,32a24,24,0,0,1,15.07,42.68A16,16,0,0,0,136,64H113.38A24,24,0,0,1,136,32ZM88,160a8,8,0,0,1-8-8V112H72a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16H96v40A8,8,0,0,1,88,160Zm96,8a48,48,0,0,1-89.56,24H136a16,16,0,0,0,16-16V96h32Zm0-88H168a39.89,39.89,0,0,0,7.6-29.6A16,16,0,1,1,184,80Zm32,72a24,24,0,0,1-16.36,22.75A62.76,62.76,0,0,0,200,168V96h16Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M82,110H72a6,6,0,0,1,0-12h32a6,6,0,0,1,0,12H94v42a6,6,0,0,1-12,0ZM230,92.74V152A38.05,38.05,0,0,1,194,190,62,62,0,0,1,78,190H40a14,14,0,0,1-14-14V80A14,14,0,0,1,40,66H99.34a38,38,0,0,1,70-28.19A30,30,0,0,1,208,82h11.28A10.75,10.75,0,0,1,230,92.74ZM173.42,49.45A37.28,37.28,0,0,1,174,56a38,38,0,0,1-10.28,26H184a18,18,0,1,0-10.58-32.55ZM112,66h24a14,14,0,0,1,13.84,12A26,26,0,1,0,112,66ZM40,178h96a2,2,0,0,0,2-2V80a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2v96A2,2,0,0,0,40,178Zm146-10V96a2,2,0,0,0-2-2H150v82a14,14,0,0,1-14,14H91.1A50,50,0,0,0,186,168Zm32-74H197.84a14.71,14.71,0,0,1,.16,2v72a62.69,62.69,0,0,1-.72,9.46A26,26,0,0,0,218,152Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M112,104a8,8,0,0,1-8,8H96v40a8,8,0,0,1-16,0V112H72a8,8,0,0,1,0-16h32A8,8,0,0,1,112,104ZM232,92.74V152a40,40,0,0,1-36.63,39.85,64,64,0,0,1-118.7.15H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64H96.81a40,40,0,0,1,73.31-28.85A32,32,0,0,1,211.69,80h7.57A12.76,12.76,0,0,1,232,92.74ZM112,56a23.82,23.82,0,0,0,1.38,8H136a16,16,0,0,1,15.07,10.68A24,24,0,1,0,112,56Zm24,120h0V80H40v96h96Zm48-80H152v80a16,16,0,0,1-16,16H94.44A48,48,0,0,0,184,168Zm16-32a16,16,0,0,0-24.4-13.6A39.89,39.89,0,0,1,168,80h16A16,16,0,0,0,200,64Zm16,32H200v72a62.76,62.76,0,0,1-.36,6.75A24,24,0,0,0,216,152Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M108,104a4,4,0,0,1-4,4H92v44a4,4,0,0,1-8,0V108H72a4,4,0,0,1,0-8h32A4,4,0,0,1,108,104ZM228,92.74V152a36,36,0,0,1-35.44,36,60,60,0,0,1-113.13,0H40a12,12,0,0,1-12-12V80A12,12,0,0,1,40,68h62.07a36,36,0,0,1,66.48-27.36,28,28,0,0,1,35,43.36h15.69A8.75,8.75,0,0,1,228,92.74ZM171.23,48.61A36,36,0,0,1,158.64,84H184a20,20,0,1,0-12.77-35.39ZM110.71,68H136a12,12,0,0,1,12,12v1.29A28,28,0,1,0,110.71,68ZM40,180h96a4,4,0,0,0,4-4V80a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4v96A4,4,0,0,0,40,180Zm148-12V96a4,4,0,0,0-4-4H148v84a12,12,0,0,1-12,12H88a52,52,0,0,0,100-20Zm32-75.26a.74.74,0,0,0-.74-.74h-24a11.8,11.8,0,0,1,.7,4v72a60.23,60.23,0,0,1-1.18,11.86A28,28,0,0,0,220,152Z" }))
  ]
]);
export {
  A as default
};
