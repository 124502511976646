import l from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,24a83.57,83.57,0,0,1,44,12.48v16L151.79,96l-28.47,3.85L104.62,87.6a20,20,0,0,0-28.07,5.28l-20.94,31.3a19.89,19.89,0,0,0-3.38,11l-.16,28.69A84,84,0,0,1,128,44ZM68.41,187.14A20,20,0,0,0,76,171.55l.2-35,18.07-27,16.62,10.88a19.92,19.92,0,0,0,14.14,3.42l31.47-4.26A20,20,0,0,0,169,112.79L191.16,87a20.15,20.15,0,0,0,4.39-8.9,83.78,83.78,0,0,1,14.12,69.48l-11.34-10.37a20.08,20.08,0,0,0-21.17-3.71l-30.45,12.66a20.1,20.1,0,0,0-12.11,15.55l-2.39,16.2a20,20,0,0,0,14.71,22.26l16,4.18a83.78,83.78,0,0,1-94.47-17.24Zm116.77,2.32-1.63-1.63a20.05,20.05,0,0,0-9.08-5.22l-18-4.72L158,167.48l26-10.81L200,171.26A84.84,84.84,0,0,1,185.18,189.46Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement(
      "path",
      {
        d: "M215,168.71a96.42,96.42,0,0,1-30.54,37l-9.36-9.37a8,8,0,0,0-3.63-2.09L150,188.59a8,8,0,0,1-5.88-8.9l2.38-16.2a8,8,0,0,1,4.84-6.22l30.46-12.66a8,8,0,0,1,8.47,1.49ZM159.89,105,182.06,79.2A8,8,0,0,0,184,74V50A96,96,0,0,0,50.49,184.65l9.92-6.52A8,8,0,0,0,64,171.49l.21-36.23a8.06,8.06,0,0,1,1.35-4.41l20.94-31.3a8,8,0,0,1,11.34-2l19.81,13a8.06,8.06,0,0,0,5.77,1.45l31.46-4.26A8,8,0,0,0,159.89,105Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ l.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,16a87.5,87.5,0,0,1,48,14.28V74L153.83,99.74,122.36,104l-.31-.22L102.38,90.92A16,16,0,0,0,79.87,95.1L58.93,126.4a16,16,0,0,0-2.7,8.81L56,171.44l-3.27,2.15A88,88,0,0,1,128,40ZM62.29,186.47l2.52-1.65A16,16,0,0,0,72,171.53l.21-36.23L93.17,104a3.62,3.62,0,0,0,.32.22l19.67,12.87a15.94,15.94,0,0,0,11.35,2.77L156,115.59a16,16,0,0,0,10-5.41l22.17-25.76A16,16,0,0,0,192,74V67.67A87.87,87.87,0,0,1,211.77,155l-16.14-14.76a16,16,0,0,0-16.93-3l-30.46,12.65a16.08,16.08,0,0,0-9.68,12.45l-2.39,16.19a16,16,0,0,0,11.77,17.81L169.4,202l2.36,2.37A87.88,87.88,0,0,1,62.29,186.47ZM185,195l-4.3-4.31a16,16,0,0,0-7.26-4.18L152,180.85l2.39-16.19L184.84,152,205,170.48A88.43,88.43,0,0,1,185,195Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM62.29,186.47l2.52-1.65A16,16,0,0,0,72,171.53l.21-36.23L93.17,104a3.62,3.62,0,0,0,.32.22l19.67,12.87a15.94,15.94,0,0,0,11.35,2.77L156,115.59a16,16,0,0,0,10-5.41l22.17-25.76A16,16,0,0,0,192,74V67.67A87.87,87.87,0,0,1,211.77,155l-16.14-14.76a16,16,0,0,0-16.93-3l-30.46,12.65a16.08,16.08,0,0,0-9.68,12.45l-2.39,16.19a16,16,0,0,0,11.77,17.81L169.4,202l2.36,2.37A87.88,87.88,0,0,1,62.29,186.47Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm0,12a89.53,89.53,0,0,1,50,15.2V74a2,2,0,0,1-.48,1.31L155.35,101a2,2,0,0,1-1.25.68L122.63,106a2,2,0,0,1-1.44-.36l-.24-.16-19.7-12.89a14,14,0,0,0-19.71,3.64l-21,31.3a13.94,13.94,0,0,0-2.36,7.71L58,171.45a2,2,0,0,1-.9,1.66l-5,3.27A90,90,0,0,1,128,38ZM59.28,186.05l4.43-2.9A14,14,0,0,0,70,171.52l.21-36.23a2.05,2.05,0,0,1,.33-1.1l21-31.3a2,2,0,0,1,1.31-.86,2,2,0,0,1,1.52.35l.24.16,19.7,12.89a13.93,13.93,0,0,0,10,2.44l31.47-4.26a14,14,0,0,0,8.73-4.74l22.17-25.76A14,14,0,0,0,190,74V62.82a89.91,89.91,0,0,1,22.68,95.67l-18.4-16.82a14,14,0,0,0-14.82-2.6L149,151.73a14.11,14.11,0,0,0-8.48,10.89l-2.38,16.19a14,14,0,0,0,10.3,15.58L169.9,200a2.09,2.09,0,0,1,.91.53l4.18,4.18A89.86,89.86,0,0,1,59.28,186.05ZM184.9,197.68l-5.59-5.61a14,14,0,0,0-6.36-3.65l-21.46-5.63a2,2,0,0,1-1.47-2.23l2.39-16.19a2,2,0,0,1,1.21-1.56l30.45-12.66a2,2,0,0,1,2.12.37l21.36,19.54A90.79,90.79,0,0,1,184.9,197.68Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,16a87.5,87.5,0,0,1,48,14.28V74L153.83,99.74,122.36,104l-.31-.22L102.38,90.92A16,16,0,0,0,79.87,95.1L58.93,126.4a16,16,0,0,0-2.7,8.81L56,171.44l-3.27,2.15A88,88,0,0,1,128,40ZM62.29,186.47l2.52-1.65A16,16,0,0,0,72,171.53l.21-36.23L93.17,104a3.62,3.62,0,0,0,.32.22l19.67,12.87a15.94,15.94,0,0,0,11.35,2.77L156,115.59a16,16,0,0,0,10-5.41l22.17-25.76A16,16,0,0,0,192,74V67.67A87.87,87.87,0,0,1,211.77,155l-16.14-14.76a16,16,0,0,0-16.93-3l-30.46,12.65a16.08,16.08,0,0,0-9.68,12.45l-2.39,16.19a16,16,0,0,0,11.77,17.81L169.4,202l2.36,2.37A87.88,87.88,0,0,1,62.29,186.47ZM185,195l-4.3-4.31a16,16,0,0,0-7.26-4.18L152,180.85l2.39-16.19L184.84,152,205,170.48A88.43,88.43,0,0,1,185,195Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ l.createElement(l.Fragment, null, /* @__PURE__ */ l.createElement("path", { d: "M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,8a91.48,91.48,0,0,1,52,16.15V74a4,4,0,0,1-1,2.61l-22.17,25.76a4,4,0,0,1-2.49,1.35L122.9,108a3.94,3.94,0,0,1-2.88-.72l-.16-.11L100.13,94.22A12,12,0,0,0,83.2,97.33l-21,31.3a11.93,11.93,0,0,0-2,6.6L60,171.46a4,4,0,0,1-1.8,3.33l-6.65,4.36A92,92,0,0,1,128,36ZM56.32,185.6l6.29-4.13a12,12,0,0,0,5.41-10l.21-36.23a4,4,0,0,1,.67-2.2l20.95-31.3a4,4,0,0,1,5.67-1l.15.11,19.74,12.91a12,12,0,0,0,8.56,2.11l31.47-4.26a12,12,0,0,0,7.49-4.06L185.1,81.81A12,12,0,0,0,188,74V58.31A91.91,91.91,0,0,1,213.5,162l-20.57-18.82a12,12,0,0,0-12.7-2.22l-30.45,12.66a12.06,12.06,0,0,0-7.27,9.33l-2.38,16.19A12,12,0,0,0,149,192.46l21.45,5.63a4,4,0,0,1,1.82,1l5.94,6A91.85,91.85,0,0,1,56.32,185.6Zm128.43,14.76-6.86-6.88a12,12,0,0,0-5.45-3.13L151,184.72a4,4,0,0,1-3-4.45l2.39-16.2a4,4,0,0,1,2.42-3.11l30.45-12.65a4,4,0,0,1,4.24.74L210,169.62A92.43,92.43,0,0,1,184.75,200.36Z" }))
  ]
]);
export {
  e as default
};
