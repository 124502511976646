import a from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M100,84a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H112A12,12,0,0,1,100,84ZM252,200a12,12,0,0,1-12,12H16a12,12,0,0,1,0-24h4V139.31A12,12,0,0,1,6.24,121l40-56a11.91,11.91,0,0,1,2.94-2.9l67.56-46.65.17-.11a19.94,19.94,0,0,1,22.18,0l.17.11,67.56,46.65a11.91,11.91,0,0,1,2.94,2.9l40,56A12,12,0,0,1,236,139.31V188h4A12,12,0,0,1,252,200ZM44,188H64V124a12,12,0,0,1,12-12H180a12,12,0,0,1,12,12v64h20V109.45L191.45,80.69,128,36.87,64.55,80.69,44,109.45Zm124-52H113.9L168,174.07ZM88,188h58.1L88,147.12Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M184,120v80H72V120Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M240,192h-8V130.57l1.49,2.08a8,8,0,1,0,13-9.3l-40-56a8,8,0,0,0-2-1.94L137,18.77l-.1-.07a16,16,0,0,0-17.76,0l-.1.07L51.45,65.42a8,8,0,0,0-2,1.94l-40,56a8,8,0,1,0,13,9.3L24,130.57V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM40,108.17,61.7,77.79,128,32l66.3,45.78L216,108.17V192H192V120a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v72H40Zm88,42L97,128H159Zm48-14.62v48.91L141.76,160ZM114.24,160,80,184.46V135.55ZM128,169.83,159,192H97ZM104,88a8,8,0,0,1,8-8h32a8,8,0,1,1,0,16H112A8,8,0,0,1,104,88Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,192h-8V130.57l1.49,2.08a8,8,0,1,0,13-9.3l-40-56a8,8,0,0,0-2-1.94L137,18.77l-.1-.07a16,16,0,0,0-17.76,0l-.1.07L51.45,65.42a8,8,0,0,0-2,1.94l-40,56a8,8,0,1,0,13,9.3L24,130.57V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM112,80h32a8,8,0,1,1,0,16H112a8,8,0,1,1,0-16Zm52.64,40L128,146.17,91.36,120ZM72,125.83,114.24,156,72,186.17ZM91.36,192,128,165.83,164.64,192ZM184,186.17,141.76,156,184,125.83Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,194H230V124.32l5.12,7.17a6,6,0,1,0,9.76-7l-40-56a6.14,6.14,0,0,0-1.47-1.45L135.77,20.35a14,14,0,0,0-15.62.06L52.59,67.06a6.14,6.14,0,0,0-1.47,1.45l-40,56a6,6,0,1,0,9.76,7L26,124.32V194H16a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12ZM38,107.52,60.27,76.34l66.62-46a2,2,0,0,1,2.14-.06l66.7,46.06L218,107.52V194H190V120a6,6,0,0,0-6-6H72a6,6,0,0,0-6,6v74H38Zm90,45.11L90.72,126h74.56Zm50-21v56.68L138.32,160ZM117.68,160,78,188.34V131.66ZM128,167.37,165.28,194H90.72ZM106,88a6,6,0,0,1,6-6h32a6,6,0,0,1,0,12H112A6,6,0,0,1,106,88Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,192h-8V130.57l1.49,2.08a8,8,0,1,0,13-9.3l-40-56a8,8,0,0,0-2-1.94L137,18.77l-.1-.07a16,16,0,0,0-17.76,0l-.1.07L51.45,65.42a8,8,0,0,0-2,1.94l-40,56a8,8,0,1,0,13,9.3L24,130.57V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM40,108.17,61.7,77.79,128,32l66.3,45.78L216,108.17V192H192V120a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v72H40Zm88,42L97,128H159Zm48-14.62v48.91L141.76,160ZM114.24,160,80,184.46V135.55ZM128,169.83,159,192H97ZM104,88a8,8,0,0,1,8-8h32a8,8,0,1,1,0,16H112A8,8,0,0,1,104,88Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,196H228V118.08l8.74,12.25A4,4,0,0,0,240,132a4,4,0,0,0,3.25-6.32l-40-56a3.77,3.77,0,0,0-1-1L134.66,22a12,12,0,0,0-13.37,0L53.73,68.71a3.77,3.77,0,0,0-1,1l-40,56a4,4,0,1,0,6.51,4.65L28,118.08V196H16a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8ZM36,106.88l22.85-32,66.93-46.23a4,4,0,0,1,4.38,0l67,46.26,22.85,32V196H188V120a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4v76H36Zm92,48.2L84.48,124h87Zm52-27.31v64.46L134.88,160ZM121.12,160,76,192.23V127.77Zm6.88,4.92L171.52,196h-87ZM108,88a4,4,0,0,1,4-4h32a4,4,0,0,1,0,8H112A4,4,0,0,1,108,88Z" }))
  ]
]);
export {
  l as default
};
