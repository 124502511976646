import a from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,92A36,36,0,1,0,92,56,36,36,0,0,0,128,92Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,128,44ZM44,96a20,20,0,1,1,20,20A20,20,0,0,1,44,96Zm184,17.6A12,12,0,0,1,208.49,123s-21-15.48-53.34.28c-.34,4.75-1,10.94-2.13,18l30.66,25.55a12,12,0,0,1,3.7,13l-16,48a12,12,0,1,1-22.76-7.59L162,180l-15.44-12.86a136.39,136.39,0,0,1-5.72,14.23c-14.64,31-39.34,51.93-73.42,62.15A11.82,11.82,0,0,1,64,244a12,12,0,0,1-3.45-23.5c37.84-11.35,60.77-38.71,68.41-81.44-24,13.54-43.11,17-56.46,17A65.87,65.87,0,0,1,51,152.31a12,12,0,0,1,9.72-21.93c.43.17,25.42,9.53,68.5-19.76,54.71-37.18,94.26-6.38,94.26-6.38A12,12,0,0,1,228,113.6Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M152,56a24,24,0,1,1-24-24A24,24,0,0,1,152,56Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M128,88A32,32,0,1,0,96,56,32,32,0,0,0,128,88Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,128,40ZM48,96a16,16,0,1,1,16,16A16,16,0,0,1,48,96Zm174.24,14.61A8,8,0,0,1,211,111.87c-1.15-.89-23.71-17.7-59.32.61a214.93,214.93,0,0,1-3,30.35l32.43,27a8,8,0,0,1,2.47,8.68l-16,48a8,8,0,0,1-15.18-5.06l14.27-42.82-22.08-18.4a141.86,141.86,0,0,1-5.1,14.33c-13.75,32.74-38.38,54.63-73.2,65.08a8,8,0,0,1-4.6-15.32c60.68-18.21,71.14-72.22,73.42-101.65C108,139.88,86.57,144,72.36,144a59.59,59.59,0,0,1-19.67-3.27A8,8,0,0,1,56,125.4a7.82,7.82,0,0,1,3.31.73s26.76,10.68,72.19-20.2c52.29-35.54,88-7.77,89.51-6.57A8,8,0,0,1,222.24,110.61Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,56a32,32,0,1,1,32,32A32,32,0,0,1,96,56ZM221,99.36c-1.5-1.2-37.22-29-89.51,6.57C86,136.84,59.57,126.23,59.32,126.12a8,8,0,1,0-6.63,14.56c.61.28,7.49,3.27,19.67,3.27,14.21,0,35.64-4.11,62.77-21.29-2.28,29.41-12.73,83.47-73.43,101.68a8,8,0,1,0,4.6,15.32c34.83-10.45,59.45-32.34,73.2-65.08a141.86,141.86,0,0,0,5.1-14.33l22.08,18.4-14.27,42.82a8,8,0,0,0,15.18,5.06l16-48a8,8,0,0,0-2.47-8.68l-32.42-27a215.91,215.91,0,0,0,3-30.34c36.18-18.57,59-.85,59.28-.65a8,8,0,1,0,10-12.48ZM64,112A16,16,0,1,0,48,96,16,16,0,0,0,64,112Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,86A30,30,0,1,0,98,56,30,30,0,0,0,128,86Zm0-48a18,18,0,1,1-18,18A18,18,0,0,1,128,38ZM50,96a14,14,0,1,1,14,14A14,14,0,0,1,50,96Zm170.68,13.35a6,6,0,0,1-8.41,1c-1.17-.92-25.06-18.91-62.52.94a214.64,214.64,0,0,1-3.23,32.38l33.32,27.77a6,6,0,0,1,1.85,6.51l-16,48a6,6,0,0,1-11.38-3.8L169,178l-25.49-21.25a141,141,0,0,1-5.86,17.07C124.15,206,100,227.48,65.73,237.75A6.14,6.14,0,0,1,64,238a6,6,0,0,1-1.72-11.75c64.82-19.45,73.42-78.76,75.11-107.41C109,137.65,86.8,142,72.39,142c-11.71,0-18.29-2.86-18.88-3.13a6,6,0,1,1,5-10.92c.21.09,27.6,11.28,74.14-20.35,26.44-18,48.5-19,62.36-16.67,15.37,2.57,24.39,9.7,24.76,10A6,6,0,0,1,220.68,109.35Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,88A32,32,0,1,0,96,56,32,32,0,0,0,128,88Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,128,40ZM48,96a16,16,0,1,1,16,16A16,16,0,0,1,48,96Zm174.24,14.61A8,8,0,0,1,211,111.87c-1.15-.89-23.71-17.7-59.32.61a214.93,214.93,0,0,1-3,30.35l32.43,27a8,8,0,0,1,2.47,8.68l-16,48a8,8,0,0,1-15.18-5.06l14.27-42.82-22.08-18.4a141.86,141.86,0,0,1-5.1,14.33c-13.75,32.74-38.38,54.63-73.2,65.08a8,8,0,0,1-4.6-15.32c60.68-18.21,71.14-72.22,73.42-101.65C108,139.88,86.57,144,72.36,144a59.59,59.59,0,0,1-19.67-3.27A8,8,0,0,1,56,125.4a7.82,7.82,0,0,1,3.31.73s26.76,10.68,72.19-20.2c52.29-35.54,88-7.77,89.51-6.57A8,8,0,0,1,222.24,110.61Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,84a28,28,0,1,0-28-28A28,28,0,0,0,128,84Zm0-48a20,20,0,1,1-20,20A20,20,0,0,1,128,36ZM52,96a12,12,0,1,1,12,12A12,12,0,0,1,52,96Zm167.12,12.1a4,4,0,0,1-5.62.62c-1.2-.94-26.41-20.11-65.7,1.3,0,.28,0,.54,0,.84a210.61,210.61,0,0,1-3.43,33.54l34.23,28.53a4,4,0,0,1,1.23,4.33l-16,48A4,4,0,0,1,160,228a3.92,3.92,0,0,1-1.26-.21,4,4,0,0,1-2.53-5.06l15.13-45.41-29-24.13A138.23,138.23,0,0,1,135.81,173c-13.26,31.58-37,52.71-70.66,62.8A3.82,3.82,0,0,1,64,236a4,4,0,0,1-1.15-7.83c69.32-20.8,75.54-86.09,76.74-113.21l-1.34.9C109.24,135.58,86.78,140,72.42,140c-11.24,0-17.51-2.73-18.08-3a4,4,0,1,1,3.32-7.28c.25.11,28.44,11.87,76.09-20.52,25.89-17.6,47.41-18.6,60.91-16.34,14.86,2.48,23.48,9.29,23.84,9.58A4,4,0,0,1,219.12,108.1Z" }))
  ]
]);
export {
  l as default
};
