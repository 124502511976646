import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M120,128a16,16,0,1,1-16-16A16,16,0,0,1,120,128Zm32-16a16,16,0,1,0,16,16A16,16,0,0,0,152,112Zm95.48,12.66A20,20,0,0,1,232,132H204v35.05A38,38,0,0,1,182,236H74a38,38,0,0,1-22-68.95V132H24A20,20,0,0,1,4.39,108.17a60.15,60.15,0,0,1,52-47.76A59.7,59.7,0,0,1,44,24a12,12,0,0,1,24,0,36,36,0,0,0,36,36h48a36,36,0,0,0,36-36,12,12,0,0,1,24,0,59.7,59.7,0,0,1-12.35,36.41,60.15,60.15,0,0,1,52,47.76A19.78,19.78,0,0,1,247.48,124.66ZM76,160H180V104a20,20,0,0,0-20-20H96a20,20,0,0,0-20,20ZM56.5,84.64A36.15,36.15,0,0,0,29.26,108H52v-4A43.71,43.71,0,0,1,56.5,84.64ZM196,198a14,14,0,0,0-14-14H74a14,14,0,0,0,0,28H182A14,14,0,0,0,196,198Zm30.74-90A36.15,36.15,0,0,0,199.5,84.64,43.71,43.71,0,0,1,204,104v4Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M208,192h0a32,32,0,0,1-32,32H80a32,32,0,0,1-32-32h0a32,32,0,0,1,32-32h96A32,32,0,0,1,208,192ZM64,104A32,32,0,0,1,96,72H63.22a48,48,0,0,0-47.07,38.53A8,8,0,0,0,24,120H64Zm175.85,6.53A48,48,0,0,0,192.78,72H160a32,32,0,0,1,32,32v16h40A8,8,0,0,0,239.85,110.53Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M104,192a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16H96A8,8,0,0,1,104,192Zm72-8H160a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Zm-76-72a12,12,0,1,0,12,12A12,12,0,0,0,100,112Zm56,0a12,12,0,1,0,12,12A12,12,0,0,0,156,112Zm88.39,10.12A16,16,0,0,1,232,128H200v32a40,40,0,0,1-24,72H80a40,40,0,0,1-24-72V128H24A16,16,0,0,1,8.31,109,56.13,56.13,0,0,1,63.22,64h1.64A55.83,55.83,0,0,1,48,24a8,8,0,0,1,16,0,40,40,0,0,0,40,40h48a40,40,0,0,0,40-40,8,8,0,0,1,16,0,55.83,55.83,0,0,1-16.86,40h1.64a56.13,56.13,0,0,1,54.91,45A15.82,15.82,0,0,1,244.39,122.12ZM56,112v-8a39.81,39.81,0,0,1,8-24h-.8A40.09,40.09,0,0,0,24,112Zm144,80a24,24,0,0,0-24-24H80a24,24,0,0,0,0,48h96A24,24,0,0,0,200,192Zm-16-39.2V104a24,24,0,0,0-24-24H96a24,24,0,0,0-24,24v48.8a40.57,40.57,0,0,1,8-.8h96A40.57,40.57,0,0,1,184,152.8ZM232,112a40.08,40.08,0,0,0-39.2-32H192a39.81,39.81,0,0,1,8,24v8Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M104,192a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16H96A8,8,0,0,1,104,192Zm72-8H160a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Zm68.39-61.88A16,16,0,0,1,232,128H200v32a40,40,0,0,1-24,72H80a40,40,0,0,1-24-72V128H24A16,16,0,0,1,8.31,109,56.13,56.13,0,0,1,63.22,64h1.64A55.83,55.83,0,0,1,48,24a8,8,0,0,1,16,0,40,40,0,0,0,40,40h48a40,40,0,0,0,40-40,8,8,0,0,1,16,0,55.83,55.83,0,0,1-16.86,40h1.64a56.13,56.13,0,0,1,54.91,45A15.82,15.82,0,0,1,244.39,122.12ZM144,124a12,12,0,1,0,12-12A12,12,0,0,0,144,124Zm-56,0a12,12,0,1,0,12-12A12,12,0,0,0,88,124ZM56,112v-8a39.81,39.81,0,0,1,8-24h-.8A40.09,40.09,0,0,0,24,112Zm144,80a24,24,0,0,0-24-24H80a24,24,0,0,0,0,48h96A24,24,0,0,0,200,192Zm32-80a40.08,40.08,0,0,0-39.2-32H192a39.81,39.81,0,0,1,8,24v8Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M102,192a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12H96A6,6,0,0,1,102,192Zm74-6H160a6,6,0,0,0,0,12h16a6,6,0,0,0,0-12Zm-76-72a10,10,0,1,0,10,10A10,10,0,0,0,100,114Zm56,0a10,10,0,1,0,10,10A10,10,0,0,0,156,114Zm86.85,6.85A14,14,0,0,1,232,126H198v35.05A38,38,0,0,1,176,230H80a38,38,0,0,1-22-68.95V126H24a14,14,0,0,1-10.87-5.15,13.82,13.82,0,0,1-2.88-11.5A54.12,54.12,0,0,1,63.22,66h6.89A53.93,53.93,0,0,1,50,24a6,6,0,0,1,12,0,42,42,0,0,0,42,42h48a42,42,0,0,0,42-42,6,6,0,0,1,12,0,53.93,53.93,0,0,1-20.11,42h6.89a54.12,54.12,0,0,1,52.95,43.35A13.82,13.82,0,0,1,242.85,120.85ZM58,114V104A37.87,37.87,0,0,1,68.32,78h-5.1A42.08,42.08,0,0,0,22,111.7a1.83,1.83,0,0,0,.4,1.55A2,2,0,0,0,24,114Zm144,78a26,26,0,0,0-26-26H80a26,26,0,0,0,0,52h96A26,26,0,0,0,202,192Zm-16-36.66V104a26,26,0,0,0-26-26H96a26,26,0,0,0-26,26v51.34A37.94,37.94,0,0,1,80,154h96A37.94,37.94,0,0,1,186,155.34Zm48-43.64A42.08,42.08,0,0,0,192.78,78h-5.1A37.87,37.87,0,0,1,198,104v10h34a2,2,0,0,0,1.58-.75A1.83,1.83,0,0,0,234,111.7Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M104,192a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16H96A8,8,0,0,1,104,192Zm72-8H160a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Zm-76-48a12,12,0,1,0-12-12A12,12,0,0,0,100,136Zm56,0a12,12,0,1,0-12-12A12,12,0,0,0,156,136Zm88.39-13.88A16,16,0,0,1,232,128H200v32a40,40,0,0,1-24,72H80a40,40,0,0,1-24-72V128H24A16,16,0,0,1,8.31,109,56.13,56.13,0,0,1,63.22,64h1.64A55.83,55.83,0,0,1,48,24a8,8,0,0,1,16,0,40,40,0,0,0,40,40h48a40,40,0,0,0,40-40,8,8,0,0,1,16,0,55.83,55.83,0,0,1-16.86,40h1.64a56.13,56.13,0,0,1,54.91,45A15.82,15.82,0,0,1,244.39,122.12ZM72,152.8a40.57,40.57,0,0,1,8-.8h96a40.57,40.57,0,0,1,8,.8V104a24,24,0,0,0-24-24H96a24,24,0,0,0-24,24ZM56,112v-8a39.81,39.81,0,0,1,8-24h-.8A40.09,40.09,0,0,0,24,112Zm144,80a24,24,0,0,0-24-24H80a24,24,0,0,0,0,48h96A24,24,0,0,0,200,192Zm32-80a40.08,40.08,0,0,0-39.2-32H192a39.81,39.81,0,0,1,8,24v8Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M100,192a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8H96A4,4,0,0,1,100,192Zm76-4H160a4,4,0,0,0,0,8h16a4,4,0,0,0,0-8Zm-76-72a8,8,0,1,0,8,8A8,8,0,0,0,100,116Zm56,0a8,8,0,1,0,8,8A8,8,0,0,0,156,116Zm85.3,3.59A12,12,0,0,1,232,124H196v38.08A36,36,0,0,1,176,228H80a36,36,0,0,1-20-65.92V124H24a12,12,0,0,1-9.32-4.41,11.82,11.82,0,0,1-2.47-9.85A52.11,52.11,0,0,1,63.22,68H76.34A52,52,0,0,1,52,24a4,4,0,0,1,8,0,44.05,44.05,0,0,0,44,44h48a44.05,44.05,0,0,0,44-44,4,4,0,0,1,8,0,52,52,0,0,1-24.34,44h13.12a52.11,52.11,0,0,1,51,41.74A11.82,11.82,0,0,1,241.3,119.59ZM60,116V104A35.94,35.94,0,0,1,73.41,76H63.22a44.09,44.09,0,0,0-43.14,35.31,3.82,3.82,0,0,0,.81,3.21A4,4,0,0,0,24,116Zm116,48H80a28,28,0,0,0,0,56h96a28,28,0,0,0,0-56Zm12-5.94V104a28,28,0,0,0-28-28H96a28,28,0,0,0-28,28v54.06A35.88,35.88,0,0,1,80,156h96A35.88,35.88,0,0,1,188,158.06Zm47.92-46.75A44.09,44.09,0,0,0,192.78,76H182.59A35.94,35.94,0,0,1,196,104v12h36a4,4,0,0,0,3.13-1.48A3.82,3.82,0,0,0,235.92,111.31Z" }))
  ]
]);
export {
  e as default
};
