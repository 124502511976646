import a from "react";
const A = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M236,108a24,24,0,1,1-24-24A24,24,0,0,1,236,108ZM68,108a24,24,0,1,0-24,24A24,24,0,0,0,68,108ZM92,84A24,24,0,1,0,68,60,24,24,0,0,0,92,84Zm72,0a24,24,0,1,0-24-24A24,24,0,0,0,164,84Zm48,100a44,44,0,0,1-61.1,40.55,60.15,60.15,0,0,0-45.8,0A44,44,0,0,1,67,145.34,31.33,31.33,0,0,0,81.91,126.6a48,48,0,0,1,92.18,0A31.34,31.34,0,0,0,189,145.34,44,44,0,0,1,212,184Zm-24,0a20,20,0,0,0-10.49-17.6l-.1-.06a55.22,55.22,0,0,1-26.37-33,24,24,0,0,0-46.08,0,55.21,55.21,0,0,1-26.37,33.05l-.1.06A20,20,0,0,0,88,204a19.77,19.77,0,0,0,7.8-1.58l.13,0a84,84,0,0,1,64.14,0l.13,0A19.77,19.77,0,0,0,168,204,20,20,0,0,0,188,184Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M232,108a20,20,0,1,1-20-20A20,20,0,0,1,232,108ZM64,108a20,20,0,1,0-20,20A20,20,0,0,0,64,108ZM92,80A20,20,0,1,0,72,60,20,20,0,0,0,92,80Zm72,0a20,20,0,1,0-20-20A20,20,0,0,0,164,80Zm19.24,75.85A43.46,43.46,0,0,1,162.57,130a36,36,0,0,0-69.14,0,43.49,43.49,0,0,1-20.67,25.9,32,32,0,0,0,27.73,57.62,72.49,72.49,0,0,1,55,0,32,32,0,0,0,27.73-57.62Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M212,80a28,28,0,1,0,28,28A28,28,0,0,0,212,80Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,120ZM72,108a28,28,0,1,0-28,28A28,28,0,0,0,72,108ZM44,120a12,12,0,1,1,12-12A12,12,0,0,1,44,120ZM92,88A28,28,0,1,0,64,60,28,28,0,0,0,92,88Zm0-40A12,12,0,1,1,80,60,12,12,0,0,1,92,48Zm72,40a28,28,0,1,0-28-28A28,28,0,0,0,164,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,48Zm23.12,100.86a35.3,35.3,0,0,1-16.87-21.14,44,44,0,0,0-84.5,0A35.25,35.25,0,0,1,69,148.82,40,40,0,0,0,88,224a39.48,39.48,0,0,0,15.52-3.13,64.09,64.09,0,0,1,48.87,0,40,40,0,0,0,34.73-72ZM168,208a24,24,0,0,1-9.45-1.93,80.14,80.14,0,0,0-61.19,0,24,24,0,0,1-20.71-43.26,51.22,51.22,0,0,0,24.46-30.67,28,28,0,0,1,53.78,0,51.27,51.27,0,0,0,24.53,30.71A24,24,0,0,1,168,208Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M240,108a28,28,0,1,1-28-28A28,28,0,0,1,240,108ZM72,108a28,28,0,1,0-28,28A28,28,0,0,0,72,108ZM92,88A28,28,0,1,0,64,60,28,28,0,0,0,92,88Zm72,0a28,28,0,1,0-28-28A28,28,0,0,0,164,88Zm23.12,60.86a35.3,35.3,0,0,1-16.87-21.14,44,44,0,0,0-84.5,0A35.25,35.25,0,0,1,69,148.82,40,40,0,0,0,88,224a39.48,39.48,0,0,0,15.52-3.13,64.09,64.09,0,0,1,48.87,0,40,40,0,0,0,34.73-72Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,82a26,26,0,1,0,26,26A26,26,0,0,0,212,82Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,212,122ZM70,108a26,26,0,1,0-26,26A26,26,0,0,0,70,108ZM44,122a14,14,0,1,1,14-14A14,14,0,0,1,44,122ZM92,86A26,26,0,1,0,66,60,26,26,0,0,0,92,86Zm0-40A14,14,0,1,1,78,60,14,14,0,0,1,92,46Zm72,40a26,26,0,1,0-26-26A26,26,0,0,0,164,86Zm0-40a14,14,0,1,1-14,14A14,14,0,0,1,164,46Zm22.15,104.61a37.32,37.32,0,0,1-17.82-22.33,42,42,0,0,0-80.66,0A37.26,37.26,0,0,1,69.9,150.58,38,38,0,0,0,88,222a37.58,37.58,0,0,0,14.76-3,66.14,66.14,0,0,1,50.41,0A37.66,37.66,0,0,0,168,222a38,38,0,0,0,18.15-71.39ZM168,210a25.93,25.93,0,0,1-10.21-2.08,78.15,78.15,0,0,0-59.65,0A26,26,0,0,1,75.68,161.1a49.28,49.28,0,0,0,23.51-29.48,30,30,0,0,1,57.62,0,49.27,49.27,0,0,0,23.57,29.5A26,26,0,0,1,168,210Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,80a28,28,0,1,0,28,28A28,28,0,0,0,212,80Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,120ZM72,108a28,28,0,1,0-28,28A28,28,0,0,0,72,108ZM44,120a12,12,0,1,1,12-12A12,12,0,0,1,44,120ZM92,88A28,28,0,1,0,64,60,28,28,0,0,0,92,88Zm0-40A12,12,0,1,1,80,60,12,12,0,0,1,92,48Zm72,40a28,28,0,1,0-28-28A28,28,0,0,0,164,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,48Zm23.12,100.86a35.3,35.3,0,0,1-16.87-21.14,44,44,0,0,0-84.5,0A35.25,35.25,0,0,1,69,148.82,40,40,0,0,0,88,224a39.48,39.48,0,0,0,15.52-3.13,64.09,64.09,0,0,1,48.87,0,40,40,0,0,0,34.73-72ZM168,208a24,24,0,0,1-9.45-1.93,80.14,80.14,0,0,0-61.19,0,24,24,0,0,1-20.71-43.26,51.22,51.22,0,0,0,24.46-30.67,28,28,0,0,1,53.78,0,51.27,51.27,0,0,0,24.53,30.71A24,24,0,0,1,168,208Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M212,84a24,24,0,1,0,24,24A24,24,0,0,0,212,84Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,124ZM68,108a24,24,0,1,0-24,24A24,24,0,0,0,68,108ZM44,124a16,16,0,1,1,16-16A16,16,0,0,1,44,124ZM92,84A24,24,0,1,0,68,60,24,24,0,0,0,92,84Zm0-40A16,16,0,1,1,76,60,16,16,0,0,1,92,44Zm72,40a24,24,0,1,0-24-24A24,24,0,0,0,164,84Zm0-40a16,16,0,1,1-16,16A16,16,0,0,1,164,44Zm21.18,108.36a39.32,39.32,0,0,1-18.77-23.52,40,40,0,0,0-76.82,0,39.3,39.3,0,0,1-18.73,23.51A36,36,0,0,0,102,217.17a68.14,68.14,0,0,1,51.95,0,36,36,0,0,0,31.23-64.79ZM168,212a27.8,27.8,0,0,1-11-2.23,76.16,76.16,0,0,0-58.11,0A27.72,27.72,0,0,1,88,212a28,28,0,0,1-13.29-52.65,47.23,47.23,0,0,0,22.56-28.29,32,32,0,0,1,61.46,0,47.26,47.26,0,0,0,22.6,28.3A28,28,0,0,1,168,212Z" }))
  ]
]);
export {
  A as default
};
