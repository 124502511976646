import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M192,148a16,16,0,1,1-16-16A16,16,0,0,1,192,148Zm-4-40a11.84,11.84,0,0,1-5.07-1.13L118.25,76.69a68,68,0,0,0-57.83,59.89l65.93-35.17A12,12,0,0,1,144,112v98.11a67.71,67.71,0,0,0,44.75-35.52,12,12,0,1,1,21.42,10.82,91.54,91.54,0,0,1-76,50.38A12.13,12.13,0,0,1,132,236c-.35,0-.68,0-1-.05-1,0-2,.05-3,.05A92,92,0,0,1,46.36,101.61,40,40,0,0,1,68,28a11.84,11.84,0,0,1,5.07,1.13l117.24,54.7A16,16,0,0,0,188,52a12,12,0,0,1,0-24,40,40,0,0,1,0,80ZM80,192.12V153.33l-17.39,9.28A68.13,68.13,0,0,0,80,192.12ZM120,132l-16,8.53v67.08a67.1,67.1,0,0,0,16,3.9ZM86.5,61.88,65.69,52.17a16,16,0,0,0-5.58,29.74A92,92,0,0,1,86.5,61.88Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,112V224a79.58,79.58,0,0,1-32-6.66V129.07Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M172,136a12,12,0,1,1-12,12A12,12,0,0,1,172,136Zm12.62-32.75L118.91,72.59A71.95,71.95,0,0,0,56.06,141.3l68.18-36.36A8,8,0,0,1,136,112V215.55a71.64,71.64,0,0,0,60.71-50A8,8,0,0,1,212,170.4,88,88,0,1,1,51.74,100.1,36,36,0,0,1,68,32a8.05,8.05,0,0,1,3.38.75L189.63,87.93A20,20,0,0,0,188,48a8,8,0,0,1,0-16,36,36,0,0,1,0,72A8.05,8.05,0,0,1,184.62,103.25ZM88,203.83V142.4L57.51,158.66A72.15,72.15,0,0,0,88,203.83Zm32-78.5-16,8.54v78a71,71,0,0,0,16,3.67ZM96.13,62,66.37,48.07a20,20,0,0,0-5.2,38.71c.6-.71,1.2-1.42,1.84-2.11A88,88,0,0,1,96.13,62Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,136a12,12,0,1,1-12,12A12,12,0,0,1,172,136Zm12.62-32.75L118.91,72.59A71.95,71.95,0,0,0,56.06,141.3l68.18-36.36A8,8,0,0,1,136,112V215.55a71.64,71.64,0,0,0,60.71-50A8,8,0,0,1,212,170.4,88,88,0,1,1,51.74,100.1,36,36,0,0,1,68,32a8.05,8.05,0,0,1,3.38.75L189.63,87.93A20,20,0,0,0,188,48a8,8,0,0,1,0-16,36,36,0,0,1,0,72A8.05,8.05,0,0,1,184.62,103.25ZM96,208.47V138.13L57.51,158.66A72.23,72.23,0,0,0,96,208.47ZM96.13,62,66.37,48.07a20,20,0,0,0-5.2,38.71c.6-.71,1.2-1.42,1.84-2.11A88,88,0,0,1,96.13,62Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,138a10,10,0,1,1-10,10A10,10,0,0,1,172,138Zm13.46-36.56L119.24,70.53A74,74,0,0,0,54,144c0,.22,0,.44,0,.66l71.16-37.95A6,6,0,0,1,134,112V217.76a73.63,73.63,0,0,0,64.61-51.56,6,6,0,0,1,11.45,3.6A86,86,0,1,1,54.57,99.23,34,34,0,0,1,68,34a6,6,0,0,1,2.54.56L189.26,90A22,22,0,0,0,188,46a6,6,0,0,1,0-12,34,34,0,0,1,0,68A6,6,0,0,1,185.46,101.44ZM102,213.27a73.47,73.47,0,0,0,20,4.47V122l-20,10.67ZM55.27,157.59A74.22,74.22,0,0,0,90,207.47v-68.4Zm46.14-95.38L66.74,46a22,22,0,0,0-4.93,43.05c.86-1,1.75-2.06,2.67-3.07A86,86,0,0,1,101.41,62.21Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,136a12,12,0,1,1-12,12A12,12,0,0,1,172,136Zm12.62-32.75L118.91,72.59A71.95,71.95,0,0,0,56.06,141.3l68.18-36.36A8,8,0,0,1,136,112V215.55a71.64,71.64,0,0,0,60.71-50A8,8,0,0,1,212,170.4,88,88,0,1,1,51.74,100.1,36,36,0,0,1,68,32a8.05,8.05,0,0,1,3.38.75L189.63,87.93A20,20,0,0,0,188,48a8,8,0,0,1,0-16,36,36,0,0,1,0,72A8.05,8.05,0,0,1,184.62,103.25ZM88,203.83V142.4L57.51,158.66A72.15,72.15,0,0,0,88,203.83Zm32-78.5-16,8.54v78a71,71,0,0,0,16,3.67ZM96.13,62,66.37,48.07a20,20,0,0,0-5.2,38.71c.6-.71,1.2-1.42,1.84-2.11A88,88,0,0,1,96.13,62Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M172,140a8,8,0,1,1-8,8A8,8,0,0,1,172,140Zm14.31-40.38L119.57,68.48A76,76,0,0,0,52,144c0,1.32,0,2.64.1,3.95l74-39.48A4,4,0,0,1,132,112V219.88a75.64,75.64,0,0,0,68.52-53.08,4,4,0,1,1,7.63,2.4A84,84,0,1,1,57.57,98.25,32,32,0,0,1,68,36a4,4,0,0,1,1.69.38L188.85,92A24,24,0,0,0,188,44a4,4,0,0,1,0-8,32,32,0,0,1,0,64A4,4,0,0,1,186.31,99.62Zm-62.31,19-24,12.8v83.16a75.45,75.45,0,0,0,24,5.26ZM53.05,156.51a76.15,76.15,0,0,0,39,54.4V135.73Zm54-93.86L67.15,44a24,24,0,0,0-4.6,47.36c1.1-1.37,2.22-2.71,3.41-4A83.89,83.89,0,0,1,107.08,62.65Z" }))
  ]
]);
export {
  t as default
};
