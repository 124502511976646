import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M42.06,109.94a48,48,0,1,1,67.88-67.88A12,12,0,0,1,93,59,24,24,0,1,0,59,93a12,12,0,1,1-17,17ZM163,59A24,24,0,1,1,197,93a12,12,0,1,0,17,17,48,48,0,1,0-67.88-67.88,12,12,0,0,0,17,17Zm33.94,87a12,12,0,0,0,0,17A24,24,0,1,1,163,197a12,12,0,0,0-17,17,48,48,0,1,0,67.88-67.88A12,12,0,0,0,197,146.06ZM93,197A24,24,0,1,1,59,163a12,12,0,0,0-17-17,48,48,0,1,0,67.88,67.88,12,12,0,1,0-17-17Zm71-88V147l24.49,24.48a12,12,0,0,1-17,17L147,164H109L84.49,188.49a12,12,0,0,1-17-17L92,147V109L67.51,84.49a12,12,0,0,1,17-17L109,92H147l24.48-24.49a12,12,0,0,1,17,17Zm-48,31h24V116H116Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M112,76A36,36,0,1,1,76,40,36,36,0,0,1,112,76Zm68,36a36,36,0,1,0-36-36A36,36,0,0,0,180,112ZM76,144a36,36,0,1,0,36,36A36,36,0,0,0,76,144Zm104,0a36,36,0,1,0,36,36A36,36,0,0,0,180,144Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M189.66,66.34a8,8,0,0,0-11.32,0L148.69,96H107.31L77.66,66.34A8,8,0,0,0,66.34,77.66L96,107.31v41.38L66.34,178.34a8,8,0,0,0,11.32,11.32L107.31,160h41.38l29.65,29.66a8,8,0,0,0,11.32-11.32L160,148.69V107.31l29.66-29.65A8,8,0,0,0,189.66,66.34ZM112,112h32v32H112Zm26.51-50.67a44,44,0,1,1,56.16,56.16A7.86,7.86,0,0,1,192,118a8,8,0,0,1-2.67-15.54,28,28,0,1,0-35.74-35.74,8,8,0,1,1-15.08-5.34ZM224,180a44,44,0,0,1-85.49,14.67,8,8,0,0,1,15.08-5.34,28,28,0,1,0,35.74-35.74,8,8,0,0,1,5.34-15.08A44.07,44.07,0,0,1,224,180ZM117.49,194.67a44,44,0,1,1-56.16-56.16,8,8,0,0,1,5.34,15.08,28,28,0,1,0,35.74,35.74,8,8,0,0,1,15.08,5.34ZM32,76a44,44,0,0,1,85.49-14.67,8,8,0,1,1-15.08,5.34,28,28,0,1,0-35.74,35.74A8,8,0,0,1,64,118a7.86,7.86,0,0,1-2.67-.46A44.07,44.07,0,0,1,32,76Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M189.66,77.66,160,107.31v41.38l29.66,29.65a8,8,0,0,1-11.32,11.32L148.69,160H107.31L77.66,189.66a8,8,0,0,1-11.32-11.32L96,148.69V107.31L66.34,77.66A8,8,0,0,1,77.66,66.34L107.31,96h41.38l29.65-29.66a8,8,0,0,1,11.32,11.32Zm-46.28-6.12a8,8,0,0,0,10.21-4.87,28,28,0,1,1,35.74,35.74A8,8,0,0,0,192,118a7.86,7.86,0,0,0,2.67-.46,44,44,0,1,0-56.16-56.16A8,8,0,0,0,143.38,71.54Zm51.29,67a8,8,0,0,0-5.34,15.08,28,28,0,1,1-35.74,35.74,8,8,0,0,0-15.08,5.34,44,44,0,1,0,56.16-56.16Zm-82,46a8,8,0,0,0-10.21,4.87,28,28,0,1,1-35.74-35.74,8,8,0,0,0-5.34-15.08,44,44,0,1,0,56.16,56.16A8,8,0,0,0,112.62,184.46Zm-51.29-67A7.86,7.86,0,0,0,64,118a8,8,0,0,0,2.67-15.54,28,28,0,1,1,35.74-35.74,8,8,0,1,0,15.08-5.34,44,44,0,1,0-56.16,56.16Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M188.24,67.76a6,6,0,0,0-8.48,0L149.52,98h-43L76.24,67.76a6,6,0,0,0-8.48,8.48L98,106.48v43L67.76,179.76a6,6,0,1,0,8.48,8.48L106.48,158h43l30.24,30.24a6,6,0,0,0,8.48-8.48L158,149.52v-43l30.24-30.24A6,6,0,0,0,188.24,67.76ZM110,110h36v36H110Zm30.39-48A42,42,0,1,1,194,115.61a6.09,6.09,0,0,1-2,.34,6,6,0,0,1-2-11.66A30,30,0,1,0,151.71,66a6,6,0,1,1-11.32-4ZM222,180a42,42,0,0,1-81.61,14,6,6,0,0,1,11.32-4A30,30,0,1,0,190,151.71a6,6,0,0,1,4-11.32A42.07,42.07,0,0,1,222,180ZM115.61,194A42,42,0,1,1,62,140.39a6,6,0,1,1,4,11.32A30,30,0,1,0,104.29,190a6,6,0,0,1,11.32,4ZM34,76a42,42,0,0,1,81.61-14,6,6,0,1,1-11.32,4A30,30,0,1,0,66,104.29,6,6,0,0,1,64,116a6.09,6.09,0,0,1-2-.34A42.07,42.07,0,0,1,34,76Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M189.66,66.34a8,8,0,0,0-11.32,0L148.69,96H107.31L77.66,66.34A8,8,0,0,0,66.34,77.66L96,107.31v41.38L66.34,178.34a8,8,0,0,0,11.32,11.32L107.31,160h41.38l29.65,29.66a8,8,0,0,0,11.32-11.32L160,148.69V107.31l29.66-29.65A8,8,0,0,0,189.66,66.34ZM112,112h32v32H112Zm26.51-50.67a44,44,0,1,1,56.16,56.16A7.86,7.86,0,0,1,192,118a8,8,0,0,1-2.67-15.54,28,28,0,1,0-35.74-35.74,8,8,0,1,1-15.08-5.34ZM224,180a44,44,0,0,1-85.49,14.67,8,8,0,0,1,15.08-5.34,28,28,0,1,0,35.74-35.74,8,8,0,0,1,5.34-15.08A44.07,44.07,0,0,1,224,180ZM117.49,194.67a44,44,0,1,1-56.16-56.16,8,8,0,0,1,5.34,15.08,28,28,0,1,0,35.74,35.74,8,8,0,0,1,15.08,5.34ZM32,76a44,44,0,0,1,85.49-14.67,8,8,0,1,1-15.08,5.34,28,28,0,1,0-35.74,35.74A8,8,0,0,1,64,118a7.86,7.86,0,0,1-2.67-.46A44.07,44.07,0,0,1,32,76Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M186.83,74.83a4,4,0,1,0-5.66-5.66L150.34,100H105.66L74.83,69.17a4,4,0,0,0-5.66,5.66L100,105.66v44.68L69.17,181.17a4,4,0,0,0,5.66,5.66L105.66,156h44.68l30.83,30.83a4,4,0,0,0,5.66-5.66L156,150.34V105.66ZM108,108h40v40H108Zm34.28-45.33a40,40,0,1,1,51.05,51.05A3.88,3.88,0,0,1,192,114a4,4,0,0,1-1.33-7.77,32,32,0,1,0-40.85-40.85,4,4,0,1,1-7.54-2.66ZM220,180a40,40,0,0,1-77.72,13.33,4,4,0,1,1,7.54-2.66,32,32,0,1,0,40.85-40.85,4,4,0,0,1,2.66-7.54A40.06,40.06,0,0,1,220,180ZM113.72,193.33a40,40,0,1,1-51-51.05,4,4,0,0,1,2.66,7.54,32,32,0,1,0,40.85,40.85,4,4,0,0,1,7.54,2.66ZM36,76a40,40,0,0,1,77.72-13.33,4,4,0,0,1-7.54,2.66,32,32,0,1,0-40.85,40.85A4,4,0,0,1,64,114a3.88,3.88,0,0,1-1.33-.23A40.06,40.06,0,0,1,36,76Z" }))
  ]
]);
export {
  t as default
};
