import a from "react";
const l = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M216,156H168.79l-4.73-16H216a12,12,0,0,0,12-12v-4A104,104,0,0,0,122.52,20C66,20.8,20,67.43,20,124a104,104,0,0,0,45.37,85.94A12,12,0,0,0,72.14,212H116a20,20,0,0,0,19.52-24.34q-.09-.41-.21-.81l-2-6.85h17.58l10,33.67A20.11,20.11,0,0,0,180,228h36a20,20,0,0,0,20-20V176A20,20,0,0,0,216,156ZM100.4,123.48a19.94,19.94,0,0,0-3.92,16.86q.09.41.21.81L110.61,188H76a80,80,0,0,1-32-64c0-43.48,35.39-79.36,78.86-80H124a80,80,0,0,1,79.61,72H116A19.94,19.94,0,0,0,100.4,123.48ZM126.14,156l-4.75-16H139l4.73,16ZM212,204H183l-7.09-24H212Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M112.19,137.74l15.62,52.52A8,8,0,0,1,120,200H72.14A91.91,91.91,0,0,1,32,124c0-50,40.65-91.26,90.69-92A92,92,0,0,1,216,124v4H120A8,8,0,0,0,112.19,137.74Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M96,164a12,12,0,1,1-12-12A12,12,0,0,1,96,164Zm136,12v32a16,16,0,0,1-16,16H180a16.1,16.1,0,0,1-15.35-11.47L153.84,176H131.91l3.56,12a5.44,5.44,0,0,1,.15.54A16,16,0,0,1,120,208H72.14a8,8,0,0,1-4.52-1.4A100,100,0,0,1,24,124c0-54.36,44.24-99.2,98.58-100A100,100,0,0,1,224,124v4a8,8,0,0,1-8,8H158.71l7.09,24H216A16,16,0,0,1,232,176ZM120,120h87.91A84,84,0,0,0,122.8,40C77.16,40.64,40,78.31,40,124a84,84,0,0,0,34.67,68H120l-15.45-52a4.77,4.77,0,0,1-.15-.54A16,16,0,0,1,120,120Zm29.11,40L142,136H120l7.14,24ZM216,176H170.52L180,208h36Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M216,160H165.8l-7.09-24H216a8,8,0,0,0,8-8v-4A100,100,0,0,0,122.58,24C68.24,24.77,24,69.61,24,124A100,100,0,0,0,67.62,206.6a8,8,0,0,0,4.52,1.4H120a16,16,0,0,0,15.62-19.47,5.44,5.44,0,0,0-.15-.54l-3.56-12h21.93l10.79,36.53A16.1,16.1,0,0,0,180,224h36a16,16,0,0,0,16-16V176A16,16,0,0,0,216,160ZM84,176a12,12,0,1,1,12-12A12,12,0,0,1,84,176Zm43.16-16L120,136h22l7.09,24ZM216,208H180l-9.46-32H216Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M94,164a10,10,0,1,1-10-10A10,10,0,0,1,94,164Zm136,12v32a14,14,0,0,1-14,14H180a14.1,14.1,0,0,1-13.43-10l-11.22-38h-26.1l4.33,14.56c0,.13.07.27.1.4A14,14,0,0,1,120,206H72.14A6,6,0,0,1,68.75,205,98,98,0,0,1,26,124c0-53.27,43.35-97.22,96.61-98A98,98,0,0,1,222,124v4a6,6,0,0,1-6,6H156l8.27,28H216A14,14,0,0,1,230,176ZM122,191.71l-15.54-52.26c0-.14-.07-.28-.1-.41A14,14,0,0,1,120,122h90a86,86,0,0,0-86-84h-1.24C76,38.66,38,77.22,38,124a86,86,0,0,0,36,70h46a2,2,0,0,0,2-2.29ZM151.79,162l-8.27-28H120a2,2,0,0,0-2,2.29L125.66,162ZM218,176a2,2,0,0,0-2-2H167.85l10.21,34.57A2,2,0,0,0,180,210h36a2,2,0,0,0,2-2Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96,164a12,12,0,1,1-12-12A12,12,0,0,1,96,164Zm136,12v32a16,16,0,0,1-16,16H180a16.1,16.1,0,0,1-15.35-11.47L153.84,176H131.91l3.56,12a5.44,5.44,0,0,1,.15.54A16,16,0,0,1,120,208H72.14a8,8,0,0,1-4.52-1.4A100,100,0,0,1,24,124c0-54.36,44.24-99.2,98.58-100A100,100,0,0,1,224,124v4a8,8,0,0,1-8,8H158.71l7.09,24H216A16,16,0,0,1,232,176ZM120,192l-15.45-52a4.77,4.77,0,0,1-.15-.54A16,16,0,0,1,120,120h87.91A84,84,0,0,0,122.8,40C77.16,40.64,40,78.31,40,124a84,84,0,0,0,34.67,68Zm29.13-32L142,136H120l7.14,24ZM216,176H170.52L180,208h36Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M92,164a8,8,0,1,1-8-8A8,8,0,0,1,92,164Zm136,12v32a12,12,0,0,1-12,12H180a12.08,12.08,0,0,1-11.51-8.6L156.83,172H126.55l5.09,17.13a2.13,2.13,0,0,1,.07.27A12,12,0,0,1,120,204H72.14a4,4,0,0,1-2.26-.7A96,96,0,0,1,28,124c0-52.19,42.47-95.23,94.63-96A96,96,0,0,1,220,124v4a4,4,0,0,1-4,4H153.35l9.46,32H216A12,12,0,0,1,228,176ZM123.93,191.25l-15.57-52.37a2.86,2.86,0,0,1-.07-.28A12,12,0,0,1,120,124h92a88,88,0,0,0-89.25-88C74.93,36.67,36,76.13,36,124a88,88,0,0,0,37.39,72H120a4,4,0,0,0,3.93-4.75ZM154.46,164,145,132H120a4,4,0,0,0-3.93,4.75l8.1,27.25ZM220,176a4,4,0,0,0-4-4H165.17l11,37.13A4,4,0,0,0,180,212h36a4,4,0,0,0,4-4Z" }))
  ]
]);
export {
  l as default
};
