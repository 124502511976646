import a from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M92.38,38.05A12,12,0,0,1,101,23.42a108,108,0,0,1,54,0,12,12,0,1,1-6,23.23,84,84,0,0,0-42,0A12,12,0,0,1,92.38,38.05ZM32.29,113.87a12.2,12.2,0,0,0,3.2.43,12,12,0,0,0,11.56-8.8,84,84,0,0,1,21-36.35A12,12,0,1,0,50.94,52.34a108.1,108.1,0,0,0-27,46.76A12,12,0,0,0,32.29,113.87ZM149,209.35a84,84,0,0,1-42,0,12,12,0,1,0-6,23.23,108,108,0,0,0,54,0,12,12,0,1,0-6-23.23Zm59.95-103.83a12,12,0,0,0,23.12-6.42,108,108,0,0,0-27-46.78A12,12,0,1,0,188,69.15,84,84,0,0,1,208.94,105.52Zm23.15,51.39a107.86,107.86,0,0,1-31.86,51.38,12,12,0,0,1-18.72-3.48,60,60,0,0,0-107,0,12,12,0,0,1-8.5,6.36,12.48,12.48,0,0,1-2.19.2,12,12,0,0,1-8-3.08A107.85,107.85,0,0,1,23.91,156.9,12,12,0,0,1,47,150.49,83.58,83.58,0,0,0,62,180a83.46,83.46,0,0,1,29-23.47,52,52,0,1,1,74,0A83.59,83.59,0,0,1,194,180a83.73,83.73,0,0,0,15-29.49,12,12,0,0,1,23.13,6.42ZM128,148a28,28,0,1,0-28-28A28,28,0,0,0,128,148Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M224,128a95.76,95.76,0,0,1-31.8,71.37A72,72,0,0,0,128,160a40,40,0,1,0-40-40,40,40,0,0,0,40,40,72,72,0,0,0-64.2,39.37h0A96,96,0,1,1,224,128Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M96.26,37A8,8,0,0,1,102,27.29a104.11,104.11,0,0,1,52,0,8,8,0,0,1-2,15.75,8.15,8.15,0,0,1-2-.26,88,88,0,0,0-44,0A8,8,0,0,1,96.26,37ZM33.35,110a8,8,0,0,0,9.85-5.57,87.88,87.88,0,0,1,22-38.09A8,8,0,0,0,53.79,55.14a104,104,0,0,0-26,45A8,8,0,0,0,33.35,110ZM150,213.22a88,88,0,0,1-44,0,8,8,0,0,0-4,15.49,104.11,104.11,0,0,0,52,0,8,8,0,0,0-4-15.49Zm62.8-108.77a8,8,0,0,0,15.42-4.28,104,104,0,0,0-26-45,8,8,0,1,0-11.41,11.21A88.14,88.14,0,0,1,212.79,104.45Zm15.44,51.39a103.68,103.68,0,0,1-30.68,49.47A8,8,0,0,1,185.07,203a64,64,0,0,0-114.14,0,8,8,0,0,1-5.67,4.23,8.11,8.11,0,0,1-1.46.14,8,8,0,0,1-5.35-2.05,103.74,103.74,0,0,1-30.68-49.49,8,8,0,0,1,15.42-4.27,87.58,87.58,0,0,0,19,34.88,79.57,79.57,0,0,1,36.1-28.77,48,48,0,1,1,59.38,0,79.57,79.57,0,0,1,36.1,28.77,87.58,87.58,0,0,0,19-34.88,8,8,0,1,1,15.42,4.28ZM128,152a32,32,0,1,0-32-32A32,32,0,0,0,128,152Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96.26,37A8,8,0,0,1,102,27.29a104.11,104.11,0,0,1,52,0,8,8,0,0,1-2,15.75,8.15,8.15,0,0,1-2-.26,88,88,0,0,0-44,0A8,8,0,0,1,96.26,37ZM33.35,110a8,8,0,0,0,9.85-5.57,88,88,0,0,1,22-38.09A8,8,0,0,0,53.79,55.14a104.05,104.05,0,0,0-26,45A8,8,0,0,0,33.35,110Zm179.44-5.56a8,8,0,0,0,15.42-4.28,104,104,0,0,0-26-45,8,8,0,1,0-11.41,11.22A88,88,0,0,1,212.79,104.45ZM222.66,146a8,8,0,0,0-9.85,5.58,87.61,87.61,0,0,1-19,34.83A79.75,79.75,0,0,0,172,165.11a4,4,0,0,0-4.83.31,59.81,59.81,0,0,1-78.27,0,4,4,0,0,0-4.84-.31,79.52,79.52,0,0,0-22,21.12,87.7,87.7,0,0,1-18.83-34.67,8,8,0,0,0-15.42,4.28,104.07,104.07,0,0,0,200.46,0A8,8,0,0,0,222.66,146ZM128,164a44,44,0,1,0-44-44A44.05,44.05,0,0,0,128,164Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M98.19,36.54a6,6,0,0,1,4.31-7.31,102,102,0,0,1,51,0,6,6,0,1,1-3,11.61,90,90,0,0,0-45,0A6,6,0,0,1,98.19,36.54Zm-64.3,71.54a5.88,5.88,0,0,0,1.6.22,6,6,0,0,0,5.78-4.4A90,90,0,0,1,63.78,65a6,6,0,1,0-8.56-8.41A102.12,102.12,0,0,0,29.7,100.7,6,6,0,0,0,33.89,108.08ZM150.5,215.15a90,90,0,0,1-45,0,6,6,0,1,0-3,11.61,102,102,0,0,0,51,0,6,6,0,0,0-3-11.62Zm64.22-111.24a6,6,0,0,0,5.78,4.4,5.88,5.88,0,0,0,1.6-.22,6,6,0,0,0,4.18-7.38,102,102,0,0,0-25.49-44.18,6,6,0,0,0-8.55,8.41A90.11,90.11,0,0,1,214.72,103.91ZM226.3,155.3a101.75,101.75,0,0,1-30.08,48.53,6,6,0,0,1-9.37-1.74,66,66,0,0,0-117.7,0,6,6,0,0,1-4.26,3.18,5.74,5.74,0,0,1-1.09.1,6,6,0,0,1-4-1.54A101.74,101.74,0,0,1,29.7,155.3a6,6,0,1,1,11.56-3.21,89.79,89.79,0,0,0,21.2,37.59,77.56,77.56,0,0,1,40-31.43,46,46,0,1,1,51,0,77.56,77.56,0,0,1,40,31.43,89.7,89.7,0,0,0,21.2-37.58,6,6,0,1,1,11.56,3.2ZM128,154a34,34,0,1,0-34-34A34,34,0,0,0,128,154Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M96.26,37A8,8,0,0,1,102,27.29a104.11,104.11,0,0,1,52,0,8,8,0,0,1-2,15.75,8.15,8.15,0,0,1-2-.26,88,88,0,0,0-44,0A8,8,0,0,1,96.26,37ZM33.35,110a8,8,0,0,0,9.85-5.57,87.88,87.88,0,0,1,22-38.09A8,8,0,0,0,53.8,55.14a103.92,103.92,0,0,0-26,45A8,8,0,0,0,33.35,110ZM150,213.22a88,88,0,0,1-44,0,8,8,0,0,0-4,15.49,104.11,104.11,0,0,0,52,0,8,8,0,0,0-4-15.49Zm62.8-108.77a8,8,0,0,0,15.42-4.28,104,104,0,0,0-26-45,8,8,0,1,0-11.41,11.21A88.14,88.14,0,0,1,212.79,104.45Zm15.44,51.39a103.68,103.68,0,0,1-30.68,49.47A8,8,0,0,1,185.07,203a64,64,0,0,0-114.14,0,8,8,0,0,1-12.48,2.32,103.74,103.74,0,0,1-30.68-49.49,8,8,0,0,1,15.42-4.27,87.58,87.58,0,0,0,19,34.88,79.57,79.57,0,0,1,36.1-28.77,48,48,0,1,1,59.38,0,79.57,79.57,0,0,1,36.1,28.77,87.58,87.58,0,0,0,19-34.88,8,8,0,1,1,15.42,4.28ZM128,152a32,32,0,1,0-32-32A32,32,0,0,0,128,152Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M100.13,36A4,4,0,0,1,103,31.17a100.09,100.09,0,0,1,50,0,4,4,0,0,1-1,7.88,4.36,4.36,0,0,1-1-.13,92,92,0,0,0-46,0A4,4,0,0,1,100.13,36ZM34.42,106.16a4,4,0,0,0,4.92-2.79,91.92,91.92,0,0,1,23-39.82,4,4,0,0,0-5.71-5.61,100.15,100.15,0,0,0-25,43.3A4,4,0,0,0,34.42,106.16ZM151,217.09a92,92,0,0,1-46,0,4,4,0,0,0-2,7.75,100,100,0,0,0,50,0,4,4,0,1,0-2-7.74Zm65.65-113.71a4,4,0,1,0,7.7-2.14,100.08,100.08,0,0,0-25-43.31,4,4,0,1,0-5.71,5.61A92,92,0,0,1,216.65,103.38Zm7.73,51.39a99.77,99.77,0,0,1-29.5,47.57,4,4,0,0,1-6.24-1.16,68,68,0,0,0-121.28,0,4,4,0,0,1-2.83,2.12,3.61,3.61,0,0,1-.73.07,4,4,0,0,1-2.68-1,99.8,99.8,0,0,1-29.5-47.58,4,4,0,1,1,7.71-2.13A91.89,91.89,0,0,0,62.8,192.91a75.66,75.66,0,0,1,44.54-34.08,44,44,0,1,1,41.32,0,75.66,75.66,0,0,1,44.54,34.08,91.83,91.83,0,0,0,23.47-40.28,4,4,0,0,1,7.71,2.14ZM128,156a36,36,0,1,0-36-36A36,36,0,0,0,128,156Z" }))
  ]
]);
export {
  t as default
};
