import e from "react";
const c = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M188.88,28.29c-19.47-8-42.59-10.29-65.11-6.54-24.28,4-45.33,14.53-60.88,30.34C25.47,90.15,33.05,167.56,50,207.88,56.87,224.36,69,244,87.51,244c25.38,0,36.72-22.14,46.73-41.68,6.73-13.14,13.69-26.74,24.23-33.89h0c5.49-3.72,13.22-6.7,21.41-9.86,23.69-9.13,56.12-21.64,56.12-65.4C236,64.19,218.82,40.54,188.88,28.29ZM171.24,136.18c-9.08,3.5-18.48,7.13-26.24,12.39-15.63,10.6-24.39,27.71-32.13,42.81C103.79,209.13,97.7,220,87.51,220c-1.28,0-8-3.81-15.41-21.4C57.48,163.75,51.89,97.51,80,68.91c11.79-12,28.73-20.34,47.69-23.49a104.37,104.37,0,0,1,17-1.39,94,94,0,0,1,35.08,6.47c12,4.92,32.21,17,32.21,42.67C212,119.14,196.51,126.43,171.24,136.18Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
      "path",
      {
        d: "M224,93.16c0,49-48.17,49-72.25,65.34C121.88,178.76,119.64,232,87.52,232s-61.74-125-16-171.51C119.64,11.49,224,27.82,224,93.16Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ e.createElement("path", { d: "M187.37,32c-18.79-7.68-41.14-9.92-62.93-6.3-23.44,3.9-43.73,14-58.68,29.2-11.12,11.32-24.62,33.35-25.7,74-.7,26.49,4.39,55.44,13.61,77.45S74.61,240,87.52,240c22.94,0,33.23-20.08,43.17-39.5,7-13.6,14.16-27.66,25.55-35.38,5.86-4,13.8-7,22.21-10.28,23.86-9.2,53.55-20.66,53.55-61.67C232,65.9,215.73,43.6,187.37,32ZM172.69,139.91c-9.28,3.58-18.05,7-25.43,12-14.78,10-23.3,26.66-30.81,41.33C106.67,212.3,100.05,224,87.52,224c-4.52,0-12.18-7.37-19.09-23.85-8.39-20-13-46.49-12.37-70.83.73-27.66,8.23-50.11,21.11-63.21C95.23,47.74,120.79,40,144.57,40a98.48,98.48,0,0,1,36.74,6.76c13,5.3,34.69,18.38,34.69,46.37C216,123.21,195.93,131,172.69,139.91Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M232,93.17c0,41-29.69,52.47-53.55,61.67-8.41,3.24-16.35,6.3-22.21,10.28-11.39,7.72-18.59,21.78-25.55,35.38-9.94,19.42-20.23,39.5-43.17,39.5-12.91,0-24.61-11.64-33.85-33.66s-14.31-51-13.61-77.45c1.08-40.65,14.58-62.68,25.7-74,14.95-15.2,35.24-25.3,58.68-29.2,21.79-3.62,44.14-1.38,62.93,6.3C215.73,43.6,232,65.9,232,93.17Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M186.61,33.84c-18.45-7.54-40.41-9.74-61.84-6.17-23,3.82-42.93,13.72-57.58,28.62C56.31,67.36,43.13,88.94,42.06,128.94c-.69,26.23,4.34,54.87,13.46,76.62,8.77,20.92,20.13,32.44,32,32.44,21.72,0,31.72-19.53,41.39-38.41,7.08-13.82,14.4-28.11,26.21-36.12h0c6-4.11,14.09-7.21,22.61-10.5C201,144,230,132.81,230,93.17,230,66.75,214.19,45.13,186.61,33.84Zm-13.2,107.94c-9.17,3.54-17.84,6.88-25,11.76-14.36,9.73-22.75,26.12-30.15,40.58C109.47,211.23,101.9,226,87.52,226c-5,0-13.18-6.59-20.94-25.08-8.49-20.26-13.17-47-12.52-71.66.75-28.16,8.45-51.09,21.69-64.55,18.45-18.78,44.57-26.65,68.86-26.65A100.32,100.32,0,0,1,182.07,45C213.33,57.74,218,80.65,218,93.17,218,124.58,196.34,132.93,173.41,141.78Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M187.37,32c-18.79-7.68-41.14-9.92-62.93-6.3-23.44,3.9-43.73,14-58.68,29.2-11.12,11.32-24.62,33.35-25.7,74-.7,26.49,4.39,55.44,13.61,77.45S74.61,240,87.52,240c22.94,0,33.23-20.08,43.17-39.5,7-13.6,14.16-27.66,25.55-35.38h0c5.86-4,13.8-7,22.21-10.28,23.86-9.2,53.55-20.66,53.55-61.67C232,65.9,215.73,43.6,187.37,32ZM172.69,139.91c-9.28,3.58-18.05,7-25.43,12-14.78,10-23.3,26.66-30.81,41.33C106.67,212.3,100.05,224,87.52,224c-4.52,0-12.18-7.37-19.09-23.85-8.39-20-13-46.49-12.37-70.83.73-27.66,8.23-50.11,21.11-63.21C95.23,47.74,120.79,40,144.57,40a98.48,98.48,0,0,1,36.74,6.76c13,5.3,34.69,18.38,34.69,46.37C216,123.21,195.93,131,172.69,139.91Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M185.85,35.7c-18.11-7.41-39.68-9.56-60.76-6.06C102.5,33.4,83,43.1,68.62,57.7,58,68.5,45.11,89.65,44.06,129c-.69,25.95,4.29,54.29,13.3,75.79C65.8,224.92,76.51,236,87.52,236c20.5,0,29.78-18.13,39.61-37.32,7.19-14.05,14.63-28.57,26.86-36.87,6.25-4.23,14.39-7.37,23-10.7,22.72-8.76,51-19.67,51-57.94C228,67.6,212.64,46.66,185.85,35.7ZM174.13,143.64c-9.06,3.5-17.63,6.8-24.63,11.55-13.93,9.45-22.2,25.6-29.49,39.84-9.06,17.69-16.88,33-32.49,33-7.36,0-15.88-9.83-22.78-26.3-8.6-20.51-13.34-47.61-12.68-72.49,1-36.75,12.64-56.12,22.26-65.9,18.86-19.19,45.53-27.23,70.33-27.23,13.88,0,27.18,2.52,38.17,7C215.17,56.34,220,80.15,220,93.17,220,126,196.68,135,174.13,143.64Z" }))
  ]
]);
export {
  c as default
};
