import a from "react";
const e = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M234.39,87.29c-57.67-57.72-155.11-57.72-212.78,0-21.45,21.47-23.52,53.13-5,77a20,20,0,0,0,22.92,6.37L88.4,153.29l.45-.16A20,20,0,0,0,101,138.47l5.44-27.24a72.48,72.48,0,0,1,42.76-.09L155,138.62a20,20,0,0,0,12.14,14.49l.45.17,48.94,17.37a20,20,0,0,0,22.91-6.37C257.91,140.42,255.84,108.76,234.39,87.29Zm-12.23,59.9L178,131.5,172.24,104a20,20,0,0,0-13-14.8,96.49,96.49,0,0,0-62.94.14A20,20,0,0,0,83.4,104.24L78,131.53,33.84,147.19c-9.16-13.85-7.41-30.77,4.73-42.92C62.81,80,95.41,67.88,128,67.88S193.19,80,217.43,104.27C229.57,116.42,231.32,133.35,222.16,147.19ZM228,200a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,200Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M230,157a8,8,0,0,1-9.26,2.47L171.58,142a8,8,0,0,1-4.86-5.8l-6.21-29.74a7.94,7.94,0,0,0-5.14-5.9,84.39,84.39,0,0,0-55.1.13,7.93,7.93,0,0,0-5.12,6l-5.9,29.51A8,8,0,0,1,84.38,142L35.29,159.42A8,8,0,0,1,26,157c-14.6-18.83-13.26-43.83,4-61.12,53.11-53.11,142.77-53.11,195.88,0C243.23,113.12,244.57,138.12,230,157Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M231.59,90.13h0C175.44,34,80.56,34,24.41,90.13c-20,20-21.92,49.49-4.69,71.71A16,16,0,0,0,32.35,168a15.8,15.8,0,0,0,5.75-1.08l49-17.37.29-.11a16,16,0,0,0,9.75-11.73l5.9-29.52a76.52,76.52,0,0,1,49.68-.11h0l6.21,29.75a16,16,0,0,0,9.72,11.59l.29.11,49,17.39a16,16,0,0,0,18.38-5.06C253.51,139.62,251.58,110.13,231.59,90.13ZM223.67,152l-.3-.12-48.82-17.33-6.21-29.74A16,16,0,0,0,158,93a92.56,92.56,0,0,0-60.34.13,16,16,0,0,0-10.32,12l-5.9,29.51L32.63,151.86c-.1,0-.17.13-.27.17-12.33-15.91-11-36.23,3.36-50.58,25-25,58.65-37.53,92.28-37.53s67.27,12.51,92.28,37.53C234.61,115.8,236,136.12,223.67,152Zm.32,48a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,200Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M236.28,161.84a16,16,0,0,1-18.38,5.06l-49-17.39-.29-.11a16,16,0,0,1-9.72-11.59l-6.21-29.75h0a76.52,76.52,0,0,0-49.68.11l-5.9,29.52a16,16,0,0,1-9.75,11.73l-.29.11-49,17.37A15.8,15.8,0,0,1,32.35,168a16,16,0,0,1-12.63-6.14c-17.23-22.22-15.3-51.71,4.69-71.71,56.15-56.17,151-56.17,207.18,0h0C251.58,110.13,253.51,139.62,236.28,161.84ZM216,192H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M230.19,91.55h0c-55.39-55.4-149-55.4-204.38,0C6.56,110.82,4.7,139.22,21.29,160.61A14,14,0,0,0,37.39,165l49-17.38.22-.09a13.93,13.93,0,0,0,8.53-10.25l5.9-29.52a1.93,1.93,0,0,1,1.21-1.47,78.53,78.53,0,0,1,51.18-.11,1.93,1.93,0,0,1,1.22,1.45l6.21,29.75a14,14,0,0,0,8.5,10.14l.23.08,49,17.4a14,14,0,0,0,16.1-4.42C251.3,139.22,249.44,110.82,230.19,91.55Zm-5,61.71a2,2,0,0,1-2.29.58l-.22-.09-49-17.38A2,2,0,0,1,172.6,135l-6.22-29.74a14,14,0,0,0-9.06-10.35,90.53,90.53,0,0,0-59,.13,14,14,0,0,0-9,10.45L83.37,135a2,2,0,0,1-1.15,1.44L33.28,153.75l-.22.09a2,2,0,0,1-2.29-.58c-13-16.74-11.56-38.12,3.53-53.22,25.39-25.4,59.55-38.1,93.7-38.1s68.31,12.7,93.7,38.1C236.79,115.14,238.21,136.52,225.23,153.26ZM222,200a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,200Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M231.59,90.13h0C175.44,34,80.56,34,24.41,90.13c-20,20-21.92,49.49-4.69,71.71A16,16,0,0,0,32.35,168a15.8,15.8,0,0,0,5.75-1.08l49-17.37.29-.11a16,16,0,0,0,9.75-11.73l5.9-29.52a76.52,76.52,0,0,1,49.68-.11h0l6.21,29.75a16,16,0,0,0,9.72,11.59l.29.11,49,17.39a16,16,0,0,0,18.38-5.06C253.51,139.62,251.58,110.13,231.59,90.13ZM223.67,152l-.3-.12-48.82-17.33-6.21-29.74A16,16,0,0,0,158,93a92.56,92.56,0,0,0-60.34.13,16,16,0,0,0-10.32,12l-5.9,29.51L32.63,151.86c-.1,0-.17.13-.27.17-12.33-15.91-11-36.23,3.36-50.58,25-25,58.65-37.53,92.28-37.53s67.27,12.51,92.28,37.53C234.61,115.8,236,136.12,223.67,152Zm.32,48a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,200Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M228.79,93C174.15,38.34,81.85,38.34,27.21,93,8.69,111.5,6.9,138.81,22.85,159.39a12,12,0,0,0,13.83,3.77l49-17.39.15-.06a12,12,0,0,0,7.31-8.79l5.9-29.51a3.93,3.93,0,0,1,2.51-3,80.59,80.59,0,0,1,52.5-.12,3.94,3.94,0,0,1,2.52,2.94L162.81,137a12,12,0,0,0,7.29,8.69l.15.06,49.07,17.41a12,12,0,0,0,13.83-3.77C249.1,138.81,247.31,111.5,228.79,93Zm-2,61.51a4,4,0,0,1-4.61,1.21l-.15-.06L173,138.24a4,4,0,0,1-2.37-2.87l-6.21-29.75a12,12,0,0,0-7.75-8.87,88.63,88.63,0,0,0-57.74.14,12,12,0,0,0-7.72,9l-5.9,29.51A4,4,0,0,1,83,138.26l-49,17.38-.15.06a4,4,0,0,1-4.61-1.21c-13.42-17.3-11.9-40.26,3.69-55.85C58.65,72.85,93.33,60,128,60s69.35,12.89,95.13,38.68C238.71,114.23,240.24,137.19,226.82,154.49ZM220,200a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,200Z" }))
  ]
]);
export {
  e as default
};
