import a from "react";
const H = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M80,120a12,12,0,0,1,12-12h8v-8a12,12,0,0,1,24,0v8h8a12,12,0,0,1,0,24h-8v8a12,12,0,0,1-24,0v-8H92A12,12,0,0,1,80,120Zm176,0v64a20,20,0,0,1-20,20H225.94a36,36,0,0,1-67.88,0H113.94a36,36,0,0,1-67.88,0H32a20,20,0,0,1-20-20V72A20,20,0,0,1,32,52H184a12,12,0,0,1,12,12v4h26.58a19.9,19.9,0,0,1,18.57,12.57l14,35A12,12,0,0,1,256,120ZM196,92v16h30.28l-6.4-16ZM36,180H46.06a36,36,0,0,1,67.88,0h44.12A36.32,36.32,0,0,1,172,162.08V76H36Zm56,12a12,12,0,1,0-12,12A12,12,0,0,0,92,192Zm112,0a12,12,0,1,0-12,12A12,12,0,0,0,204,192Zm28-12V132H196v24.23A36.09,36.09,0,0,1,225.94,180Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M248,120v64a8,8,0,0,1-8,8H216a24,24,0,0,0-32-22.63h0A24,24,0,0,0,168,192H104a24,24,0,0,0-48,0H32a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8H184v56Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M80,120a8,8,0,0,1,8-8h16V96a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16H120v16a8,8,0,0,1-16,0V128H88A8,8,0,0,1,80,120Zm176,0v64a16,16,0,0,1-16,16H223a32,32,0,0,1-62,0H111a32,32,0,0,1-62,0H32a16,16,0,0,1-16-16V72A16,16,0,0,1,32,56H184a8,8,0,0,1,8,8v8h34.58a15.93,15.93,0,0,1,14.86,10.06l14,35A7.92,7.92,0,0,1,256,120ZM192,88v24h44.18l-9.6-24ZM32,184H49a32,32,0,0,1,62,0h50a32.11,32.11,0,0,1,15-19.69V72H32Zm64,8a16,16,0,1,0-16,16A16,16,0,0,0,96,192Zm112,0a16,16,0,1,0-16,16A16,16,0,0,0,208,192Zm32-8V128H192v32a32.06,32.06,0,0,1,31,24Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M255.43,117l-14-35A15.93,15.93,0,0,0,226.58,72H192V64a8,8,0,0,0-8-8H32A16,16,0,0,0,16,72V184a16,16,0,0,0,16,16H49a32,32,0,0,0,62,0h50a32,32,0,0,0,62,0h17a16,16,0,0,0,16-16V120A7.92,7.92,0,0,0,255.43,117ZM80,208a16,16,0,1,1,16-16A16,16,0,0,1,80,208Zm56-80H120v16a8,8,0,0,1-16,0V128H88a8,8,0,0,1,0-16h16V96a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Zm56,80a16,16,0,1,1,16-16A16,16,0,0,1,192,208Zm0-96V88h34.58l9.6,24Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M82,120a6,6,0,0,1,6-6h18V96a6,6,0,0,1,12,0v18h18a6,6,0,0,1,0,12H118v18a6,6,0,0,1-12,0V126H88A6,6,0,0,1,82,120Zm172,0v64a14,14,0,0,1-14,14H221.4a30,30,0,0,1-58.8,0H109.4a30,30,0,0,1-58.8,0H32a14,14,0,0,1-14-14V72A14,14,0,0,1,32,58H184a6,6,0,0,1,6,6V74h36.58a13.93,13.93,0,0,1,13,8.8l14,35A6.1,6.1,0,0,1,254,120ZM190,86v28h49.14l-10.7-26.74A2,2,0,0,0,226.58,86ZM32,186H50.6a30,30,0,0,1,58.8,0h53.2A30.1,30.1,0,0,1,178,165.48V70H32a2,2,0,0,0-2,2V184A2,2,0,0,0,32,186Zm66,6a18,18,0,1,0-18,18A18,18,0,0,0,98,192Zm112,0a18,18,0,1,0-18,18A18,18,0,0,0,210,192Zm32-8V126H190v36.07c.66,0,1.33-.07,2-.07a30.05,30.05,0,0,1,29.4,24H240A2,2,0,0,0,242,184Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M80,120a8,8,0,0,1,8-8h16V96a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16H120v16a8,8,0,0,1-16,0V128H88A8,8,0,0,1,80,120Zm176,0v64a16,16,0,0,1-16,16H223a32,32,0,0,1-62,0H111a32,32,0,0,1-62,0H32a16,16,0,0,1-16-16V72A16,16,0,0,1,32,56H184a8,8,0,0,1,8,8v8h34.58a15.93,15.93,0,0,1,14.86,10.06l14,35A7.92,7.92,0,0,1,256,120ZM192,88v24h44.18l-9.6-24ZM32,184H49a32,32,0,0,1,62,0h50a32.11,32.11,0,0,1,15-19.69V72H32Zm64,8a16,16,0,1,0-16,16A16,16,0,0,0,96,192Zm112,0a16,16,0,1,0-16,16A16,16,0,0,0,208,192Zm32-8V128H192v32a32.06,32.06,0,0,1,31,24Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M84,120a4,4,0,0,1,4-4h20V96a4,4,0,0,1,8,0v20h20a4,4,0,0,1,0,8H116v20a4,4,0,0,1-8,0V124H88A4,4,0,0,1,84,120Zm168,0v64a12,12,0,0,1-12,12H219.71a28,28,0,0,1-55.42,0H107.71a28,28,0,0,1-55.42,0H32a12,12,0,0,1-12-12V72A12,12,0,0,1,32,60H184a4,4,0,0,1,4,4V76h38.58a12,12,0,0,1,11.15,7.54l14,35A4,4,0,0,1,252,120ZM188,84v32h54.09L230.3,86.51A4,4,0,0,0,226.58,84ZM32,188H52.29a28,28,0,0,1,55.42,0h56.58A28.05,28.05,0,0,1,180,166.71V68H32a4,4,0,0,0-4,4V184A4,4,0,0,0,32,188Zm68,4a20,20,0,1,0-20,20A20,20,0,0,0,100,192Zm112,0a20,20,0,1,0-20,20A20,20,0,0,0,212,192Zm32-8V124H188v40.29A28,28,0,0,1,219.71,188H240A4,4,0,0,0,244,184Z" }))
  ]
]);
export {
  H as default
};
