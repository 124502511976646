import e from "react";
const t = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M220.48,60C209.21,39.37,193.42,28,176,28s-33.21,11.37-44.48,32c-1.24,2.28-2.42,4.65-3.52,7.11-1.1-2.46-2.28-4.83-3.52-7.11C113.21,39.37,97.42,28,80,28S46.79,39.37,35.52,60C25.51,78.38,20,102.52,20,128s5.51,49.62,15.52,68C46.79,216.62,62.58,228,80,228s33.21-11.38,44.48-32c1.24-2.28,2.42-4.65,3.52-7.11,1.1,2.46,2.28,4.83,3.52,7.11,11.27,20.65,27.06,32,44.48,32s33.21-11.38,44.48-32c10-18.35,15.52-42.49,15.52-68S230.49,78.38,220.48,60ZM152,116a12,12,0,1,1-12,12A12,12,0,0,1,152,116Zm-96,0a12,12,0,1,1-12,12A12,12,0,0,1,56,116Zm24,88c-12.08,0-25.13-15.72-31.7-40.84A35.55,35.55,0,0,0,56,164a36,36,0,0,0,0-72,35.55,35.55,0,0,0-7.7.84C54.87,67.72,67.92,52,80,52c17,0,36,31.21,36,76S97,204,80,204Zm96,0c-12.08,0-25.13-15.72-31.7-40.84a35.55,35.55,0,0,0,7.7.84,36,36,0,0,0,0-72,35.55,35.55,0,0,0-7.7.84C150.87,67.72,163.92,52,176,52c17,0,36,31.21,36,76S193,204,176,204Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
      "path",
      {
        d: "M80,128a24,24,0,1,1-24-24A24,24,0,0,1,80,128Zm72-24a24,24,0,1,0,24,24A24,24,0,0,0,152,104Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ e.createElement("path", { d: "M176,32c-20.61,0-38.28,18.16-48,45.85C118.28,50.16,100.61,32,80,32c-31.4,0-56,42.17-56,96s24.6,96,56,96c20.61,0,38.28-18.16,48-45.85,9.72,27.69,27.39,45.85,48,45.85,31.4,0,56-42.17,56-96S207.4,32,176,32ZM106.92,186.39C99.43,200.12,89.62,208,80,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32A104.81,104.81,0,0,1,53.08,69.61C60.57,55.88,70.38,48,80,48s19.43,7.88,26.92,21.61C115.35,85.07,120,105.81,120,128S115.35,170.93,106.92,186.39ZM40,128a16,16,0,1,1,16,16A16,16,0,0,1,40,128Zm162.92,58.39C195.43,200.12,185.62,208,176,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32,104.81,104.81,0,0,1,10.24-29.23C156.57,55.88,166.38,48,176,48s19.43,7.88,26.92,21.61C211.35,85.07,216,105.81,216,128S211.35,170.93,202.92,186.39ZM136,128a16,16,0,1,1,16,16A16,16,0,0,1,136,128Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M176,32c-20.61,0-38.28,18.16-48,45.85C118.28,50.16,100.61,32,80,32c-31.4,0-56,42.17-56,96s24.6,96,56,96c20.61,0,38.28-18.16,48-45.85,9.72,27.69,27.39,45.85,48,45.85,31.4,0,56-42.17,56-96S207.4,32,176,32ZM106.92,186.39C99.43,200.12,89.62,208,80,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32A104.81,104.81,0,0,1,53.08,69.61C60.57,55.88,70.38,48,80,48s19.43,7.88,26.92,21.61C115.35,85.07,120,105.81,120,128S115.35,170.93,106.92,186.39Zm96,0C195.43,200.12,185.62,208,176,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32,104.81,104.81,0,0,1,10.24-29.23C156.57,55.88,166.38,48,176,48s19.43,7.88,26.92,21.61C211.35,85.07,216,105.81,216,128S211.35,170.93,202.92,186.39Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M176,34c-21.15,0-39.1,20.15-48,50.26C119.1,54.15,101.15,34,80,34c-30.28,0-54,41.29-54,94s23.72,94,54,94c21.15,0,39.1-20.15,48-50.26,8.9,30.11,26.85,50.26,48,50.26,30.28,0,54-41.29,54-94S206.28,34,176,34ZM80,210c-18.24,0-34.19-24.1-39.83-56.53a30,30,0,1,0,0-50.94C45.81,70.1,61.76,46,80,46c22.77,0,42,37.55,42,82S102.77,210,80,210ZM38,128a18,18,0,1,1,18,18A18,18,0,0,1,38,128Zm138,82c-18.24,0-34.19-24.1-39.83-56.53a30,30,0,1,0,0-50.94C141.81,70.1,157.76,46,176,46c22.77,0,42,37.55,42,82S198.77,210,176,210Zm-42-82a18,18,0,1,1,18,18A18,18,0,0,1,134,128Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M176,32c-20.61,0-38.28,18.16-48,45.85C118.28,50.16,100.61,32,80,32c-31.4,0-56,42.17-56,96s24.6,96,56,96c20.61,0,38.28-18.16,48-45.85,9.72,27.69,27.39,45.85,48,45.85,31.4,0,56-42.17,56-96S207.4,32,176,32ZM106.92,186.39C99.43,200.12,89.62,208,80,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32A104.81,104.81,0,0,1,53.08,69.61C60.57,55.88,70.38,48,80,48s19.43,7.88,26.92,21.61C115.35,85.07,120,105.81,120,128S115.35,170.93,106.92,186.39ZM40,128a16,16,0,1,1,16,16A16,16,0,0,1,40,128Zm162.92,58.39C195.43,200.12,185.62,208,176,208s-19.43-7.88-26.92-21.61a104.81,104.81,0,0,1-10.24-29.23,32,32,0,1,0,0-58.32,104.81,104.81,0,0,1,10.24-29.23C156.57,55.88,166.38,48,176,48s19.43,7.88,26.92,21.61C211.35,85.07,216,105.81,216,128S211.35,170.93,202.92,186.39ZM136,128a16,16,0,1,1,16,16A16,16,0,0,1,136,128Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("path", { d: "M213.45,63.86C203.65,45.89,190.35,36,176,36s-27.65,9.89-37.45,27.86A113.91,113.91,0,0,0,128,92.31a113.91,113.91,0,0,0-10.55-28.45C107.65,45.89,94.35,36,80,36S52.35,45.89,42.55,63.86C33.17,81.06,28,103.84,28,128s5.17,46.94,14.55,64.14C52.35,210.11,65.65,220,80,220s27.65-9.89,37.45-27.86A113.91,113.91,0,0,0,128,163.69a113.91,113.91,0,0,0,10.55,28.45c9.8,18,23.1,27.86,37.45,27.86s27.65-9.89,37.45-27.86c9.38-17.2,14.55-40,14.55-64.14S222.83,81.06,213.45,63.86Zm-103,124.45C102.1,203.59,91.29,212,80,212s-22.1-8.41-30.43-23.69c-6-11-10.17-24.59-12.18-39.42a28,28,0,1,0,0-41.78c2-14.83,6.17-28.39,12.18-39.42C57.9,52.41,68.71,44,80,44s22.1,8.41,30.43,23.69C119.18,83.73,124,105.15,124,128S119.18,172.27,110.43,188.31ZM36,128a20,20,0,1,1,20,20A20,20,0,0,1,36,128Zm170.43,60.31C198.1,203.59,187.29,212,176,212s-22.1-8.41-30.43-23.69c-6-11-10.17-24.59-12.18-39.42a28,28,0,1,0,0-41.78c2-14.83,6.17-28.39,12.18-39.42C153.9,52.41,164.71,44,176,44s22.1,8.41,30.43,23.69C215.18,83.73,220,105.15,220,128S215.18,172.27,206.43,188.31ZM132,128a20,20,0,1,1,20,20A20,20,0,0,1,132,128Z" }))
  ]
]);
export {
  t as default
};
