import a from "react";
const A = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20ZM77,194.66A108.9,108.9,0,0,0,85.54,182a12,12,0,1,0-20.77-12A83.28,83.28,0,0,1,60,177.27a83.73,83.73,0,0,1,0-98.54A83.28,83.28,0,0,1,64.77,86,12,12,0,1,0,85.54,74,108.9,108.9,0,0,0,77,61.34a83.76,83.76,0,0,1,102.06,0A108.9,108.9,0,0,0,170.46,74a12,12,0,1,0,20.77,12A83.28,83.28,0,0,1,196,78.73a83.73,83.73,0,0,1,0,98.54,83.28,83.28,0,0,1-4.74-7.28,12,12,0,1,0-20.77,12A108.9,108.9,0,0,0,179,194.66a83.76,83.76,0,0,1-102.06,0Zm22.2-80.14A110,110,0,0,1,100,128a110,110,0,0,1-.83,13.48A12,12,0,0,1,87.27,152a12.83,12.83,0,0,1-1.5-.09,12,12,0,0,1-10.42-13.39A85.75,85.75,0,0,0,76,128a85.75,85.75,0,0,0-.65-10.52,12,12,0,1,1,23.82-3Zm71.06,37.39a12.83,12.83,0,0,1-1.5.09,12,12,0,0,1-11.9-10.52,109.88,109.88,0,0,1,0-27,12,12,0,1,1,23.82,3,85.46,85.46,0,0,0,0,21A12,12,0,0,1,170.23,151.91Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M60.66,59.58A95.74,95.74,0,0,1,89.33,128a95.74,95.74,0,0,1-28.67,68.42,96,96,0,0,1,0-136.84Zm134.68,0a96,96,0,0,0,0,136.84,96,96,0,0,0,0-136.84Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM72.09,195.91c.82-1,1.64-1.93,2.42-2.91A8,8,0,1,0,62,183l-1.34,1.62a87.82,87.82,0,0,1,0-113.24L62,73A8,8,0,1,0,74.51,63c-.78-1-1.6-2-2.42-2.91a87.84,87.84,0,0,1,111.82,0c-.82,1-1.64,1.92-2.42,2.91A8,8,0,1,0,194,73l1.34-1.62a87.82,87.82,0,0,1,0,113.24L194,183a8,8,0,1,0-12.48,10c.78,1,1.6,1.95,2.42,2.91a87.84,87.84,0,0,1-111.82,0Zm23.8-50.59a104.5,104.5,0,0,1-4.48,17.35,8,8,0,0,1-15.09-5.34,87.1,87.1,0,0,0,3.79-14.65,8,8,0,1,1,15.78,2.64Zm0-34.64a8,8,0,0,1-6.57,9.21A8.52,8.52,0,0,1,88,120a8,8,0,0,1-7.88-6.68,87.1,87.1,0,0,0-3.79-14.65,8,8,0,0,1,15.09-5.34A104.5,104.5,0,0,1,95.89,110.68Zm78.91,56.86a8,8,0,0,1-10.21-4.87,104.5,104.5,0,0,1-4.48-17.35,8,8,0,1,1,15.78-2.64,87.1,87.1,0,0,0,3.79,14.65A8,8,0,0,1,174.8,167.54Zm-14.69-56.86a104.5,104.5,0,0,1,4.48-17.35,8,8,0,0,1,15.09,5.34,87.1,87.1,0,0,0-3.79,14.65A8,8,0,0,1,168,120a8.52,8.52,0,0,1-1.33-.11A8,8,0,0,1,160.11,110.68Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M209.35,63.28a4,4,0,0,0-5.8-.47,88.94,88.94,0,0,0-9.4,10,8.2,8.2,0,0,1-11,1.81A8,8,0,0,1,181.49,63a104.17,104.17,0,0,1,10.33-11.14,4,4,0,0,0-.22-6.09,103.79,103.79,0,0,0-127.2,0,4,4,0,0,0-.22,6.09A104.64,104.64,0,0,1,74.35,62.79,8.18,8.18,0,0,1,74,73.58,8,8,0,0,1,62,73a88.41,88.41,0,0,0-9.59-10.2,4,4,0,0,0-5.79.48,103.79,103.79,0,0,0,0,129.44,4,4,0,0,0,5.8.47,88.94,88.94,0,0,0,9.4-10,8.2,8.2,0,0,1,11-1.81A8,8,0,0,1,74.51,193a104.17,104.17,0,0,1-10.33,11.14,4,4,0,0,0,.22,6.09,103.79,103.79,0,0,0,127.2,0,4,4,0,0,0,.22-6.09A104.17,104.17,0,0,1,181.49,193a8,8,0,0,1,1.7-11.59,8.2,8.2,0,0,1,11,1.81,88.94,88.94,0,0,0,9.4,10,4,4,0,0,0,5.8-.47,103.79,103.79,0,0,0,0-129.44ZM81.71,88.3a8.18,8.18,0,0,1,9.79,5.29,105.1,105.1,0,0,1,4.34,16.77A8,8,0,0,1,88,120a8,8,0,0,1-8-7.35c-.38-4.44-1.11-8.91-3.2-12.84A8,8,0,0,1,81.71,88.3Zm14.13,57.29a103.54,103.54,0,0,1-4.43,17.08,8,8,0,0,1-11.17,4.46,8.19,8.19,0,0,1-3.82-10.08,87.84,87.84,0,0,0,3.69-14.37,8,8,0,0,1,10.18-6.35A8.17,8.17,0,0,1,95.84,145.59Zm79.92,21.54a8,8,0,0,1-11.17-4.46,103.54,103.54,0,0,1-4.43-17.08,8.18,8.18,0,0,1,5.55-9.26,8,8,0,0,1,10.18,6.35,87.84,87.84,0,0,0,3.69,14.37A8.19,8.19,0,0,1,175.76,167.13Zm3.41-67.32c-2.09,3.93-2.82,8.4-3.2,12.84a8,8,0,0,1-10.17,7,8.16,8.16,0,0,1-5.65-9.25A103.59,103.59,0,0,1,164.5,93.6a8.17,8.17,0,0,1,9.79-5.3A8,8,0,0,1,179.17,99.81Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm67.31,161.67c-1-1.12-2-2.26-2.9-3.42a6,6,0,0,0-9.36,7.5c1.19,1.49,2.43,2.94,3.71,4.37a89.84,89.84,0,0,1-117.52,0c1.28-1.43,2.52-2.88,3.71-4.37a6,6,0,0,0-9.36-7.5c-.93,1.16-1.91,2.3-2.9,3.42a89.8,89.8,0,0,1,0-119.34c1,1.12,2,2.26,2.9,3.42A6,6,0,0,0,68.28,74,6,6,0,0,0,73,64.25c-1.19-1.49-2.43-2.94-3.71-4.37a89.84,89.84,0,0,1,117.52,0c-1.28,1.43-2.52,2.88-3.71,4.37a6,6,0,1,0,9.36,7.5c.93-1.16,1.91-2.3,2.9-3.42a89.8,89.8,0,0,1,0,119.34ZM93.92,145a101.43,101.43,0,0,1-4.4,17,6,6,0,1,1-11.31-4,89.91,89.91,0,0,0,3.87-15,6,6,0,1,1,11.84,2Zm0-34A6,6,0,0,1,89,117.92a6.74,6.74,0,0,1-1,.08,6,6,0,0,1-5.91-5,89.91,89.91,0,0,0-3.87-15,6,6,0,1,1,11.31-4A101.43,101.43,0,0,1,93.92,111Zm83.87,47a6,6,0,1,1-11.31,4,101.43,101.43,0,0,1-4.4-17,6,6,0,1,1,11.84-2A89.91,89.91,0,0,0,177.79,158Zm0-60a89.91,89.91,0,0,0-3.87,15,6,6,0,0,1-5.91,5,6.74,6.74,0,0,1-1-.08,6,6,0,0,1-4.93-6.91,101.43,101.43,0,0,1,4.4-17,6,6,0,1,1,11.31,4Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM72.09,195.91c.82-1,1.64-1.93,2.42-2.91A8,8,0,1,0,62,183l-1.34,1.62a87.82,87.82,0,0,1,0-113.24L62,73A8,8,0,1,0,74.51,63c-.78-1-1.6-2-2.42-2.91a87.84,87.84,0,0,1,111.82,0c-.82,1-1.64,1.92-2.42,2.91A8,8,0,1,0,194,73l1.34-1.62a87.82,87.82,0,0,1,0,113.24L194,183a8,8,0,1,0-12.48,10c.78,1,1.6,1.95,2.42,2.91a87.84,87.84,0,0,1-111.82,0Zm23.8-50.59a104.5,104.5,0,0,1-4.48,17.35,8,8,0,0,1-15.09-5.34,87.1,87.1,0,0,0,3.79-14.65,8,8,0,1,1,15.78,2.64Zm0-34.64a8,8,0,0,1-6.57,9.21A8.52,8.52,0,0,1,88,120a8,8,0,0,1-7.88-6.68,87.1,87.1,0,0,0-3.79-14.65,8,8,0,0,1,15.09-5.34A104.5,104.5,0,0,1,95.89,110.68Zm78.91,56.86a8,8,0,0,1-10.21-4.87,104.5,104.5,0,0,1-4.48-17.35,8,8,0,1,1,15.78-2.64,87.1,87.1,0,0,0,3.79,14.65A8,8,0,0,1,174.8,167.54Zm-14.69-56.86a104.5,104.5,0,0,1,4.48-17.35,8,8,0,0,1,15.09,5.34,87.1,87.1,0,0,0-3.79,14.65A8,8,0,0,1,168,120a8.52,8.52,0,0,1-1.33-.11A8,8,0,0,1,160.11,110.68Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm67.31,162.64c-1.55-1.66-3.05-3.38-4.46-5.14a4,4,0,0,0-6.24,5c1.58,2,3.26,3.9,5,5.76a91.82,91.82,0,0,1-123.22,0c1.74-1.86,3.42-3.78,5-5.76a4,4,0,0,0-6.24-5c-1.41,1.76-2.91,3.48-4.46,5.14a91.81,91.81,0,0,1,0-125.28c1.55,1.66,3.05,3.38,4.46,5.14a4,4,0,0,0,6.24-5c-1.58-2-3.26-3.9-5-5.76a91.82,91.82,0,0,1,123.22,0c-1.74,1.86-3.42,3.78-5,5.76a4,4,0,1,0,6.24,5c1.41-1.76,2.91-3.48,4.46-5.14a91.81,91.81,0,0,1,0,125.28Zm-103.37-46a98.57,98.57,0,0,1-4.3,16.67A4,4,0,0,1,83.86,164a3.88,3.88,0,0,1-1.33-.23,4,4,0,0,1-2.44-5.1,92.07,92.07,0,0,0,4-15.33,4,4,0,0,1,7.88,1.32Zm0-33.32a4,4,0,0,1-3.28,4.6A3.77,3.77,0,0,1,88,116a4,4,0,0,1-3.94-3.34,92.07,92.07,0,0,0-4-15.33,4,4,0,0,1,7.55-2.66A98.57,98.57,0,0,1,91.94,111.34Zm84,47.33a4,4,0,0,1-2.44,5.1,3.88,3.88,0,0,1-1.33.23,4,4,0,0,1-3.78-2.67,98.57,98.57,0,0,1-4.3-16.67,4,4,0,0,1,7.88-1.32A92.07,92.07,0,0,0,175.91,158.67Zm0-61.34a92.07,92.07,0,0,0-4,15.33A4,4,0,0,1,168,116a3.68,3.68,0,0,1-.66-.06,4,4,0,0,1-3.28-4.6,98.57,98.57,0,0,1,4.3-16.67,4,4,0,0,1,7.55,2.66Z" }))
  ]
]);
export {
  A as default
};
